export const DPRater = {
  baseRate: 'SBR',
  raterRequest: 'raterRequest',
  raterResult: 'raterResult',
  totalPremium: 'TotalPremium',
  mgaf: 'MGAF',
  empat : 'EMPATFSur',
  fprePlusFee: 'FPrePlusFee',
  customFees: 'CustomFees',
  totalPremiumByPeril: 'TotPerilPrem',
  totPremByPeril: 'TotPremByPeril',
  totPerilPrem: 'TotalPerilPremiums',
  figaAssessment : 'FIGA',
  figa2ndAssessment: 'Figa2ndAssessment',
  totalByPerilPremium: 'TotByPerilPrem',
  citizenAssessment: 'FairPlanAssess'
};
