export const BindAndIssueLabelConstants = {
  bindAndIssueTitle: 'Bind and Issue',
  legalInsuredName: 'Legal Insured Name',
  requestedEffectiveDate: 'Requested Effective Date',
  acknowledgementStatement: 'By checking this box, I confirm that requested effective date entered is correct. I understand  and agree that once this policy is issued, it cannot be voided. If a policy is to be cancelled, regardless of reason, policy fees and inspection fees are fully earned and will not be returned.',
  areYouSureYouWantToIssue: 'Are you sure you want to Issue?',
  areYouSureYouWantToReset: 'By confirming, all the changes will be discarded. Are you sure you want to reset?',
  issuedSuccessfully: 'Issued Successfully'
};

export const BillingLabelConstants = {
  billingTitle: 'Billing',
  paymentPlan: 'Pay Plan Option',
  payor: 'Payor',
  downpayment: 'Downpayment Amount',
  paymentMethod: 'Payment Method',
  cardHolderDetails: 'Card Holder Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  address: 'Address',
  city: 'City',
  state: 'State',
  zipCode: 'Zip Code',
  emailAddress: 'Email Address',
  creditCardDetails: 'Credit Card Details',
  cardType: 'Credit Card Type',
  creditCardNumber: 'Credit Card Number',
  creditCardExpirationMonth: 'Expiration Month',
  creditCardExpirationYear: 'Expiration Year',
  cvv: 'CVV',
  cid: 'CID',
  totalChargeAmount: 'Total Charge Amount',
  comments: 'Comments',
  bankAccountInformation: 'Bank Account Information',
  accountType: 'Account Type',
  routingNumber: 'Routing Number',
  accountNumber: 'Account Number',
  nameOnAccount: 'Name on Account',
  nextTermPaymentPlan: 'Next Term Payment Plan',
  nextTermPayor: 'Next Term Payor',
  enrollAutoPayTermsAndCondition: `I understand that this authorization will remain in effect throughout all policy renewal terms, until I cancel it in writing or by phone and /or I terminate my policy.
                          I agree to notify Centauri, via my agent, in writing or by phone at least 10 days prior to the next billing date of any changes in my account information or of any request to terminate this authorization.
                          In the case of a payment being rejected by my financial institution, I understand that Centauri may assess a Non-sufficient Funds (NSF) charge to my policy.
                          By clicking the button below, I certify that I am an authorized user of this credit card/bank account and will not dispute these scheduled transactions with my financial institution, so long as the transactions correspond to the terms indicated in this authorization form.
                          A copy of this authorization will be emailed to the insured outlining the dates their financial institution will have the funds drawn based on the pay plan selected. This authorization includes payments for either Centauri Specialty Insurance Company or Centauri National Insurance Company.`,

  oneTimePaymentLanguage: `By clicking the button below, I certify that I am an authorized user of this credit card/bank account and will not dispute this one-time payment with my financial institution.
                            This authorization includes payments for either Centauri Specialty Insurance Company or Centauri National Insurance Company.
                            In the case of a payment being rejected by my financial institution, I understand that Centauri may assess a Non-sufficient Funds (NSF) charge to my policy.`,

  insuredAgreementLanguage: 'I read the above statement to the insured and the insured agrees with the statement.',

  enrollAutoPayAgree: 'I agree',
  oneTimePaymentAgree: 'I agree',
  agreeInsured: 'I agree',
  allowRecurringPayment: 'Allow Recurring Payment',
  updateRecurringPayment: 'Update Recurring Payment Account?',
  checkNumber: 'Check Number',
  checkImage: 'Check Image',
  uploadCheckImage: 'Upload Check Image',
  viewCheckImage: 'View Check Image',
  creditCardFeeLanguage: `**IMPORTANT INFORMATION REGARDING CREDIT CARD PAYMENTS** If I am paying by credit card, then I authorize and agree that my total payment amount includes a 
                          non-refundable fee to my credit card in the amount of 3.0% of the authorized payment amount to process my payment to Centauri Specialty Insurance Company or 
                          Centauri National Insurance Company. The amount of this fee is included in the Total Estimate Premium above. 
                          This fee is used to offset the cost of the credit card processing, is not retained by the biller, and does not exceed the transaction cost.`,
  creditCardFeeAgree: 'I agree',
  totalPaymentForCCReminder: 'Total Payment includes non-refundable 3% credit card fee.',
  totalChargeAmountForCCReminder: 'Total Charge Amount includes non-refundable 3% credit card fee.',
  debitCardsNotAcceptedAtThisTime: 'Debit cards are not accepted at this time.'
};

export const PaperlessDocumentsLabelConstants = {
  paperlessDocumentsTitle: 'Paperless Documents',
  enrollInPaperless: 'Enroll in Paperless?',
  emailAddress: 'Email Address',
  confirmEmailAddress: 'Confirm Email Address',
  acknowledgement: 'By checking this box, I confirm that requested effective date entered is correct. I understand  and agree that once this policy is issued, it cannot be voided. If a policy is to be cancelled, regardless of reason, policy fees and inspection fees are fully earned and will not be returned.',
  agreement: 'I agree to Paperless documents delivery'
};

export const SignatureLabelConstants = {
  signatureTitle: 'Signature',
  statement: 'Statement',
  signature: 'Signature',
  signatureMessage: 'Thank you for writing your policy with Centauri. Please confirm all information is correct before clicking the "Issue" button.  All policy and inspection fees will become fully earned at time of binding. The Insurance Application and Declaration Page will be available (Adobe Acrobat PDF Reader will be required).  As always, we advise that the producer review all documents with the insured.'
};

export const DocumentsLabelConstants = {
  documentsTitle: 'Documents',
  upload: 'Upload',
  download: 'Download',
  emailDocuments: 'Email Documents',
  dateAdded: 'Date Added',
  category: 'Category',
  description: 'Description',
  file: 'File',
  addTitle: 'Upload Document',
  editTitle: 'Edit Document',
  suppressed: 'Suppressed',
  suppressDocumentTitle: 'Suppress Printing',
  suppressDocumentTableNoResult: 'No Documents Returned',
  refresh: 'Refresh',
  viewDocument: 'View Document'
};

export const NotesLabelConstants = {
  notesTitle: 'Notes',
  addNote: 'Add Note',
  printNote: 'Print Notes',
  dateAdded: 'Date Added',
  category: 'Category',
  description: 'Description',
  details: 'Details',
  issue: 'Issue',
  addedBy: 'Added By'
};

export const PrintLabelConstants = {
  title: 'Printing',
  formsDocument: 'Forms Document',
  singlePrint: 'Single Print',
  multiplePrint: 'Multiple Print',
  formName: 'Form Name',
};

export const PaymentPlanListConstants = {
  fullPayCode: 'PPO0',
  twoPayCode: 'PPO1',
  fourPayCode: 'PPO2',
  eightPayCode: 'PPO3',
  mortgageePayCode: 'PPO4',
  sixtyPercent: 60,
  fourtyPercent: 40,
  thirtyPercent: 30,
  twentyFivePercent: 25,
  fullPayDesc: 'Insured Billed – Full Pay',
  twoPayDesc: 'Insured Billed – 2 Pay',
  fourPayDesc: 'Insured Billed – 4 Pay',
  eightPayDesc: 'Insured Billed – 8 Pay',
  mortgageePayDesc: 'Mortgagee Bill – Full Pay'
};

export const PremiumConstants = {
  NHR: 'NHR',
  HUR: 'HUR',
  TotalPremiumStepAlias: 'TotalPremium',
  TotalPremiumGroupName: 'TotalPre',
  PremiumAdj: 'PremiumAdj',
  ConsentToRate: 'ConsentToRate',
  FinalFeesStepAlias: 'FPrePlusFee',
  MGAFeesStepAlias: 'MGAF',
  EMPATFSurStepAlias: 'EMPATFSur',
  Additive: 'ADDITIVE',
  TotalPremiumWithAdjGroupName: 'TotalPreWithAdj',
  TotalPremiumWithAdjStepAlias: 'TotalPremiumWithAdj',
  FigaAssessmentStepAlias: 'FIGA',
  InspectionFeeStepAlias: 'InspectionFee',
  CitizenFairPlanAssessmentStepAlias: 'FairPlanAssess',
  MinPremAdjStepAlias: 'MinPremAdj'
};

export const CreditCardTypeConstants = {
  AMEX: 'CCT0'
};

