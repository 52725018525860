/* eslint-disable no-bitwise */
/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/prefer-for-of */
import { HttpClient } from '@angular/common/http';
import { ElementRef, Inject, Injectable, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserInfoModel } from '../../shared/models/user-info.model';
import { BehaviorSubject, Observable, of, Subject, Subscription, throwError as observableThrowError, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TokenResponse } from '../../shared/models/tokenResponse';
import { UserAccessRight } from '../../shared/models/userAccessRight';
import { UserType } from '../../shared/constants/generic.constants';
import { AgentService } from './management/agent-service';
import { AgenciesResponseDTO } from '../../shared/models/data/dto/agent/agencies-response.dto';
import Utils from '../../shared/utilities/utils';
import { SubAgenciesResponseDTO } from '../../shared/models/data/dto/agent/subagencies-response.dto';
import { AgentUserResponseDTO } from '../../shared/models/data/dto/agent/agentuser-response.dto';
import NotifUtils from '../../shared/utilities/notif-utils';
import { AgenciesSubAgenciesResponseDTO } from '../../shared/models/data/dto/agent/agencies-subagencies.response.dto';
import { BaseClass } from '../../shared/base-class';
import { AllAgenciesSubAgenciesResponseDTO } from '../../shared/models/data/dto/agent/all-agencies-subagencies.response.dto';
import * as moment from 'moment';
import { ProgramStateAgentService } from './management/programstate-agent-service';
import { ProgramStateResponseDTO, StateCodeValuePair } from '../../shared/models/management/program-state-response.dto';
import { ProgramStateDTO } from '../../shared/models/management/program-state.dto';
import { FormControl, FormGroup } from '@angular/forms';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { PathConstants } from '../../shared/constants/path.constants';
import { AuditLogService } from './management/auditLog.service';
import { LoginLabelsConstants, PasswordFormConstants } from '../../shared/constants/login.labels.constants';
import { RoleType } from 'app/shared/enum/role-type.enum';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { ClientDetailService } from './client-details/client-detail.service';
import { AllAgenciesSubAgenciesByAgencyIdResponseDTO } from 'app/shared/models/data/dto/agent/all-agencies-subagencies-agency.response.dto';
import { ApproverDetailsDTO } from '../../shared/models/data/dto/billing/approver-details.dto';
import { ReinstatementApprovalViewDTO } from '../../shared/models/data/dto/billing/reinstatement-approval-view.dto';
import { CommonService } from './common.service';
import { AutoReinstatementConstants } from '../../shared/constants/auto-reinstatement.constants';
import { updateAppIsLoadingFromAuthService } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';
import { LockoutDTO } from '../../shared/models/management/lockout-dto';
import { updateProgramStateAgentDetailIsLoadingFromAuthService, updateProgramStateAgentDetailStateAssignmentFromAuthService } from 'app/store/dashboard/dashboard.actions';
import { AccountLockoutLabelConstants } from '../../shared/constants/account-lockout.constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SessionTimeoutComponent } from 'app/modules/login/session-timeout/session-timeout.component';
import { SessionTimeoutLabelsConstants } from 'app/shared/constants/session-timeout.constant';
import { ErrorMessageConstant } from 'app/shared/constants/error-message.constants';

import { Idle, DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TimeoutModalComponent } from 'app/modules/login/timeout-modal/timeout-modal.component';
import { AgencyService } from './management/agency-service';
import { IGetAgencyPolicyCommissionRateRequestDTO, IGetAgencyPolicyCommissionRateResponseDTO } from 'app/shared/models/management/agency-management/agency-details.response.dto';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseClass {
  PasswordFormConstants = PasswordFormConstants;
  isLogout: boolean;
  userType = new BehaviorSubject<UserType>(null);
  public currentUserRole: string;
  public serializeUserInfo: UserInfoModel;
  public agenciesInfo: AgenciesResponseDTO;
  public subAgenciesInfo: SubAgenciesResponseDTO;
  public agentUserInfo: AgentUserResponseDTO;
  public agenciesSubAgencies: AgenciesSubAgenciesResponseDTO;
  public getAllAgenciesSubAgencies: AllAgenciesSubAgenciesResponseDTO[];
  public getAllAgenciesSubAgenciesByAgencyId: AllAgenciesSubAgenciesByAgencyIdResponseDTO[];
  public allowOrderCredit$: Subject<boolean> = new Subject<boolean>();

  private idleOnTimeout$: Subscription;
  private idleOnStart$: Subscription;
  private idleOnEnd$: Subscription;

  isAgencyCompleted = new Subject<boolean>();
  isAgencyCompleted$ = this.isAgencyCompleted.asObservable();

  isAgencySubAgencyCompleted = new Subject<boolean>();
  isAgencySubAgencyCompleted$ = this.isAgencySubAgencyCompleted.asObservable();

  isGetAgenciesSubAgenciesCompleted = new Subject<boolean>();
  GetAgenciesSubAgenciesCompleted$ = this.isGetAgenciesSubAgenciesCompleted.asObservable();

  isAgentUserInfoCompleted = new BehaviorSubject<boolean>(false);
  isAgentUserInfoCompleted$ = this.isAgentUserInfoCompleted.asObservable();
  isUserAllowedToBind: boolean = true;

  isAgentIdLoad = new Subject<boolean>();
  isAgentIdLoad$ = this.isAgentIdLoad.asObservable();
  isAgentSecCalling: boolean = false;

  isAgentDashboardListDone: boolean = false;
  brul358EffectiveDate: Date = null;
  brul247EffectiveDate: Date = null;
  uwr121UpdateEffectiveDate: Date = null;
  uwr116UpdateEffectiveDate: Date = null;
  uwr87UpdateEffectiveDate: Date = null;
  privateFloodCoverageRemovalEffectiveDate: Date = null;
  turnOffUWR152EffectiveDate: Date = new Date(2024,4,30);
  turnOffUWR5EffectiveDate: Date = new Date(2024,4,30);
  stateWideNewMinimumDeductibleEffectiveDate: Date = new Date(2024,4,30);
  uwr6UpdateEffectiveDate: Date = null;
  uwr20UpdateEffectiveDate: Date = null;
  uwr97UpdateEffectiveDate: Date = null;

  agentProgramStateDetails: ProgramStateDTO[] = [];
  programStates: StateCodeValuePair[] = [];
  programTypes: ProgramStateDTO[] = [];
  LoginDateFormGroup = new FormGroup({
    loginDate: new FormControl(undefined),
    UTCServerDate: new FormControl(undefined),
    ESTServerDate: new FormControl(undefined),
    IPXDate: new FormControl(undefined),
    New110: new FormControl(undefined),
    UWR26Update: new FormControl(undefined),
    NewRaterVersionDate: new FormControl(undefined),
    NewLimitedTheftCoverageCharging: new FormControl(undefined),
    RSPSDate_NB: new FormControl(undefined),
    RSPSDate_RB: new FormControl(undefined),
    TriggerInactivityTimer: new FormControl(undefined),
    ActivateInactivityTimer: new FormControl(undefined),
    FRREDate_NB: new FormControl(undefined),
    FRREDate_RB: new FormControl(undefined)
  });

  serverDates: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(undefined);
  loginDateOption: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'mm/dd/yyyy',
    disableUntil: {
      year: 2019,
      month: 12,
      day: 31
    },
    disableSince: {
      year: 2056,
      month: 1,
      day: 1
    }
  };
  agencyId: string = '';
  subagencyId: string = '';
  isPasswordChanged: boolean = false;
  isChangePasswordAtLogin: boolean = false;
  isLocked: boolean = false;
  isAdminUser: boolean = true;
  auditLogSubscription: Subscription;
  commissionRateResponse: IGetAgencyPolicyCommissionRateResponseDTO = null;
  isDoneCommissionRateChecking$: Subject<null> = new Subject<null>();

  isCommissionRateError: boolean = false;

  public genericConstants = GenericConstants;

  get getUserViewCommissionAuthority(): boolean {
    return this.agentUserInfo?.entity?.canViewCommission;
  }

  authResponse: TokenResponse = null;
  authToken: string = '';

  public SessionTimeoutLabelsConstants  = SessionTimeoutLabelsConstants;
  isTimer: boolean = false;
  localStorageCount: number;

  private userLoggedIn = new Subject<boolean>();

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  isUserLoggedIn: boolean = false;
  sessionModal;
  timeoutModal;
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private agentService: AgentService,
    private programStateAgentService: ProgramStateAgentService,
    private auditLogService: AuditLogService,
    private clientDetailService: ClientDetailService,
    private commonService: CommonService,
    private store: Store,
    private modalService: BsModalService,
    public idle: Idle,
    protected keepalive: Keepalive,
    public agencyService: AgencyService
    ) {
      super();
      this.isLogout = true;
      this.userLoggedIn.next(false);
      this.idle.setInterrupts(this.createCustomInterruptSources(null));
    }

    initAppSettings(){
      sessionStorage.setItem('isLoggedIn', 'true');

      this.getServerDate().subscribe(result => {
        const defaultActiveTime = +result?.inactivityTimeout.activateInactivityTimer;
        const trigerTime = +result?.inactivityTimeout.triggerInactivityTimer;
        const activeTime = defaultActiveTime - trigerTime;
        const newActiveTime = activeTime * 60;
        const newTrigerTimer = trigerTime * 60;
        localStorage.setItem('TriggerInactivityTimerSecs', ''+newTrigerTimer);
        localStorage.setItem('ActivateInactivityTimerSecs', ''+newActiveTime);
        this.brul358EffectiveDate = result.brulDate.bruL358;
        this.brul247EffectiveDate = result.brulDate.bruL247;
        this.uwr121UpdateEffectiveDate = result.brulDate.uwR121Update;
        this.uwr116UpdateEffectiveDate = result.brulDate.uwR116Update;
        this.uwr87UpdateEffectiveDate = result.brulDate.uwR87Update;
        this.privateFloodCoverageRemovalEffectiveDate = result.brulDate.privateFloodCoverageRemoval;
        this.turnOffUWR152EffectiveDate = new Date(result.brulDate.turnOffUWR152EffectiveDate);
        this.turnOffUWR5EffectiveDate = new Date(result.brulDate.turnOffUWR5EffectiveDate);
        this.stateWideNewMinimumDeductibleEffectiveDate = new Date(result.brulDate.stateWideNewMinimumDeductibleEffectiveDate);
        this.uwr6UpdateEffectiveDate = result.brulDate.uwR6Update;
        this.uwr20UpdateEffectiveDate = result.brulDate.uwR20Update;
        this.uwr97UpdateEffectiveDate = result.brulDate.uwR97Update;
        this.initTimerSecs();
        this.setUserLoggedIn(true);
      });
    }

    initTimerSecs(){
      const ActivateInactivityTimer = localStorage.getItem('ActivateInactivityTimerSecs') ?? '3420';
      const TriggerInactivityTimer = localStorage.getItem('TriggerInactivityTimerSecs') ?? '180';

      this.idle.setIdle(+ActivateInactivityTimer);//57mins convert to 3420 seconds //
      this.idle.setTimeout((+TriggerInactivityTimer) +1);//3mins convert to 180seconds
    }


    idelTime(){
      this.initTimerSecs();

      this.idle.onIdleEnd.subscribe((d) => {
        this.idleState = 'No longer idle.';
        this.idle.stop();
      });

      this.idle.onTimeout.subscribe(() => {
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.idle.stop();
      });

      this.idle.onIdleStart.subscribe(() => {
          const timer = +localStorage.getItem('TriggerInactivityTimerSecs');
          const endTime = timer * 1000 + Date.now();
          localStorage.setItem('endTime', ''+endTime);

          const url = this.router.url;
          const authData = localStorage.getItem('auth');
          const decodedJWT = JSON.parse(window.atob(authData.split('.')[1]));
          const isError404Open = localStorage.getItem('isError404Open');

          if(!this.isLoggedIn()){
            this.setUserLoggedIn(false);
          }else{
            if(
              !url.includes('/makeapayment') &&
              url !== '/404' &&
              url !==  '/account/forgot-password' &&
              url !== '/account/change-password-new-user' &&
              !url.includes('/compRater') &&
              !url.includes('/account/reset-password') &&
              !decodedJWT.is_insured &&
              isError404Open  !== 'true'
            ){
              this.callSessionTimeout();
            }
          }
      });

      this.idle.onTimeoutWarning.subscribe((countdown) => {});

      // sets the ping interval to 15 seconds
      this.keepalive.interval(15);
      this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
      this.getUserLoggedIn().subscribe(userLoggedIn => {
        if (userLoggedIn) {
          this.idle.watch();
          this.timedOut = false;
        } else {
          this.idle.stop();
        }
      });
    }

    ngOnDestroy() {
      this.idle.onTimeout.unsubscribe();
      this.idle.onIdleStart.unsubscribe();
      this.idle.onIdleEnd.unsubscribe();
    }

    createCustomInterruptSources(options) {
      return [
          new DocumentInterruptSource('focus click mousedown keypress keydown keyup DOMMouseScroll mousewheel touchmove MSPointer resize scroll ', options),
          new StorageInterruptSource(options)
      ];
    }

    reset() {
      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
    }

    setUserLoggedIn(userLoggedIn: boolean) {
      localStorage.setItem('isUserLoggedIn','true');

      if (!userLoggedIn) {
        localStorage.removeItem('isUserLoggedIn');
      }

      this.userLoggedIn.next(userLoggedIn);
    }

    getUserLoggedIn(): Observable<boolean> {
      return this.userLoggedIn.asObservable();
    }

  /**
   *
   * @getCustomDate()
   * - used to set Date conditionally, If Login Date Picker = undefined | blank; get the current date; else get the LoginDate Value as our Current Date
   */
  public getCustomDate(isForUTCConversion: boolean = false): Date {
    const localStorageDate = localStorage.getItem('loginDate');
    const UTCDate = localStorage.getItem('UTCServerDate');
    const ESTDate = localStorage.getItem('ESTServerDate');
    if (localStorageDate !== 'undefined' && localStorageDate !== null && localStorageDate !== 'null') {
      this.LoginDateFormGroup.get('loginDate').setValue(this.reformatToDateTime(JSON.parse(localStorageDate), true));
      return this.LoginDateFormGroup.get('loginDate').value;
    } else {
      const date = new Date();
      const utcDate = new Date(date.toUTCString());
      utcDate.setMilliseconds(date.getMilliseconds());
      return !isForUTCConversion ? new Date() : utcDate;
      // return !isForUTCConversion ? new Date(ESTDate) : new Date(UTCDate);
    }
  }
  /**
   *
   * @param dateValue -> Can be Date Object from our Date Picker or Just a normal date that you want to reformat with current time
   * @param isFromCustomDatePicker -> To determine if From Reusable Date Picker or not
   * @returns correct date and time
   */
  public reformatToDateTime(dateValue?: any, isFromCustomDatePicker?: boolean): any {
    const currentDate = new Date();
    switch (isFromCustomDatePicker) {
      case true:
        const date = dateValue?.singleDate?.date;
        return new Date(`${date.month}/${date.day}/${date.year} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}`);
      case false:
        return new Date(`${dateValue.month}/${dateValue.day}/${dateValue.year} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}.${currentDate.getMilliseconds()}`);
    }
  }

  login(username: string, password: string): Observable<boolean> {
    const url = `/auth`;
    const clientDetails = this.clientDetailService.getClientDetails();

    const data = {
      username: username,
      password: password,
      clientDetails: {
        ipAddress: null,
        os: `${clientDetails.os} ${clientDetails.osVersion}`,
        deviceType: clientDetails.mobile ? 'Mobile' : 'PC',
        browser: clientDetails.browser,
        browserVersion: clientDetails.browserVersion
      },
      appCode: environment.ApplicationId
    };

    return this.getAuthFromServer(url, data);
  }

  resetTimeOut() {
    this.idle.stop();
    this.idle.onIdleStart.unsubscribe();
    this.idle.onTimeoutWarning.unsubscribe();
    this.idle.onIdleEnd.unsubscribe();
  }


  logout(): boolean {
    this.setAuth(null);
    const tabArrDetails = localStorage.getItem('tabList');
    localStorage.clear();
    localStorage.setItem('tabList',tabArrDetails);
    this.LoginDateFormGroup.get('loginDate').setValue(undefined);
    return true;
  }

  setAuthData() {
    this.setAuth(this.authResponse);
  }

  setAuth(auth: TokenResponse | null): boolean {
    if (auth) {
      Utils.isExpiredToken = false;
      localStorage.setItem(environment.AuthKey, JSON.stringify(auth));

      const userInfo: string = atob(JSON.stringify(auth).split('.')[1]);
      this.serializeUserInfo = JSON.parse(userInfo);
      this.userType.next(this.serializeUserInfo.internal ? 'internal' : 'external');

      this.isAdminUser = this.serializeUserInfo.role_id === AccountLockoutLabelConstants.userRoles.rivtechInternal ||
        this.serializeUserInfo.role_id === AccountLockoutLabelConstants.userRoles.rivtechAdmin ||
        this.serializeUserInfo.role_id === AccountLockoutLabelConstants.userRoles.centauriAdmin ||
        this.serializeUserInfo.role_id === AccountLockoutLabelConstants.userRoles.underwriter;

      localStorage.setItem('userType', this.serializeUserInfo.internal ? 'internal' : 'external');
      localStorage.setItem(environment.UsernameKey, this.serializeUserInfo.unique_name);
      localStorage.setItem(environment.UserIdentifierKey, this.serializeUserInfo.sub);
      localStorage.setItem(environment.UserTypeRoleDescription, this.serializeUserInfo.user_type_role_description);
      localStorage.setItem(environment.viewCommission, `${this.serializeUserInfo.view_commissions}`);
    } else {
      localStorage.removeItem(environment.AuthKey);
    }

    return true;
  }

  getAuth(): TokenResponse | null {
    const item = localStorage.getItem(environment.AuthKey);

    if (item) {
      return JSON.parse(item);
    }

    return null;
  }

  getTokenResponse(): TokenResponse | null {
    const item = this.authToken;

    if (item) {
      return JSON.parse(item);
    }

    return null;
  }

  setUserName(): void {
    const userInfo: string = atob(JSON.stringify(this.authResponse).split('.')[1]);
    this.serializeUserInfo = JSON.parse(userInfo);
    localStorage.setItem(environment.UsernameKey, this.serializeUserInfo.unique_name);
  }

  getUserName(): string {
    const userName = localStorage.getItem(environment.UsernameKey);
    return userName ? userName : null;
  }

  getUserId(): number {
    const id = Number(localStorage.getItem(environment.UserIdentifierKey));
    return id ? id : 0;
  }

  isLoggedIn(): boolean {
    const authKey = localStorage.getItem(environment.AuthKey);

    return authKey != null && authKey !== '';
  }

  hasToken(): boolean {
    return this.authToken !== '';
  }

  callSessionTimeout(){
    this.modalService.show(SessionTimeoutComponent, {
      initialState: {
        modalTitle: 'Session Timeout',
        authService: this,
      },

      backdrop: 'static',
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'my-modal',
    });
  }

  getAuthFromServer(url: string, data: any): Observable<boolean> {
    return this.http.post<any>(`${environment.IdentityServiceUrl}${url}`, data).pipe(
      map((res) => {
        const token = res && res.token;
        if (token) {
          this.authResponse = res;
          this.authToken = JSON.stringify(res);
          const decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
          this.isPasswordChanged = decodedJWT.password_changed;
          this.isChangePasswordAtLogin = decodedJWT.change_password_at_login;

          if (this.isChangePasswordAtLogin === true) {
            this.router.navigate([`${PathConstants.Account.Index}/${PathConstants.Account.ChangePasswordAtLogin}`]);
            Utils.unblockUI();
          } else if (this.isPasswordChanged === true) {
            this.saveUserInformation(token);
            localStorage.setItem('role_id', decodedJWT.role_id);
            this.agencyId = decodedJWT.agency_id;
            this.subagencyId = decodedJWT.subagency_id;
            return true;
          } else {
            this.setUserName();
            this.router.navigate([`${PathConstants.Account.Index}/${PathConstants.Account.NewUserChangePassword}`]);
            Utils.unblockUI();
          }
        } else {
          return false;
        }
      }),
      catchError(error => {
        const exist = error.error.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.exist);
        const incorrect = error.error.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.incorrect);
        const inactive = error.error.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.userInactive);
        const locked = error.error.error.toLowerCase().includes(LoginLabelsConstants.errorMessage.locked);
        if (exist || incorrect || inactive) {
          const msg = exist ? LoginLabelsConstants.errorMessage.exist :
            incorrect ? LoginLabelsConstants.errorMessage.incorrect : LoginLabelsConstants.errorMessage.userInactive;
          this.loginAuditLog(msg, data.username);
        }

        if (locked) {
          this.isLocked = true;

          const separator = '|';
          const errorMessage = error.error.error;
          const lockedMessage: string[] = errorMessage.includes(separator) ? errorMessage.split(separator) : null;

          if (lockedMessage && lockedMessage[1] === LoginLabelsConstants.errorMessage.locked) {
            error.error.error = lockedMessage[0];
            this.loginAuditLog(LoginLabelsConstants.errorMessage.aleardyLockedlocked, data.username);
          } else {
            this.loginAuditLog(LoginLabelsConstants.errorMessage.locked, data.username);
          }
        }

        return observableThrowError(error);
      }));
  }

  getUserAccessRights(): Observable<UserAccessRight[]> {

    return this.http.get<any>(`${environment.IdentityServiceUrl}/Auth/access-rights`).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  saveUserInfo(body: any | undefined): Observable<any> {
    const url_ = `${environment.ApiUrl}/User`;
    return this.http.post(url_, body).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  getAgenciesById(parsedUserInfo): void {
    const agencyId = parsedUserInfo.agency_id;
    if (!!agencyId) {
      this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: true }));
      this.agentService.getAgenciesById(agencyId).subscribe((response: AgenciesResponseDTO) => {
        this.agenciesInfo = response;
        localStorage.setItem('agencies', JSON.stringify(this.agenciesInfo));
        this.isAgencyCompleted.next(true);
      }, err => {
        this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: false }));
        NotifUtils.showError(JSON.stringify(err.error));
      });
    }
  }

  getSubAgenciesById(parsedUserInfo): void {
    if (!!parsedUserInfo.subagency_id) {
      this.agentService.getSubAgenciesById(parsedUserInfo.subagency_id).subscribe((response: SubAgenciesResponseDTO) => {
        this.subAgenciesInfo = response;
        localStorage.setItem('subagencies', JSON.stringify(this.subAgenciesInfo));
        this.allowOrderCredit$.next();
      }, err => {
        this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: false }));
        NotifUtils.showError(JSON.stringify(err.error));
      });
    }
  }

  saveUserInformation(token?): void {
    const decodedJWT = JSON.parse(window.atob(token.split('.')[1]));
    this.isAgentUserInfoCompleted.next(false);
    this.agentService.getAgentUserInformation(decodedJWT.unique_name).subscribe((response: AgentUserResponseDTO) => {
      this.agentUserInfo = response;
      this.isAgentUserInfoCompleted.next(false);
      const payload = {
        userInfo: {
          userId: decodedJWT.sub,
          fullName: `${decodedJWT.given_name} ${decodedJWT.family_name}`,
          role_id: decodedJWT.role_id,
          agentId: response?.id,
          agencyId: decodedJWT.agency_id,
          subAgencyId: decodedJWT.subagency_id,
          isLoggedIn: true
        }
      };
      this.saveUserInfo(payload).pipe(take(1)).subscribe();
    }, err => {
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getAgentUserInformation(parsedUserInfo, effectiveDate = null): void {
    this.isAgentUserInfoCompleted.next(false);
    this.agentService.getAgentUserInformation(parsedUserInfo.unique_name).subscribe((response: AgentUserResponseDTO) => {
      this.agentUserInfo = response;
      localStorage.setItem('agentuser', JSON.stringify(this.agentUserInfo));
      this.isAgentIdLoad.next(true);
      this.checkUserBindingAuthority(this.userType.value === 'external', effectiveDate);
      this.getProgramStateByAgentId(this.agentUserInfo?.id);
    }, err => {
      this.isAgentUserInfoCompleted.next(true);
      this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: false }));
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getAgencySubAgencies(agencyId, subAgencyId, riskDetailId, effectiveDate = null): void {
    this.isAgencySubAgencyCompleted.next(false);
    if (agencyId && subAgencyId && agencyId !== '00000000-0000-0000-0000-000000000000' && subAgencyId !== '00000000-0000-0000-0000-000000000000' &&
      (agencyId !== 0 && subAgencyId !== 0) && (agencyId !== null || subAgencyId !== null)) { // temporary to avoid error one existing data
      this.agentService.getAgencySubAgencies(agencyId, subAgencyId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((response: AgenciesSubAgenciesResponseDTO) => {
        this.agenciesSubAgencies = response;
        localStorage.setItem(`agencies-subagencies_${riskDetailId}`, JSON.stringify(this.agenciesSubAgencies));
        this.isAgencySubAgencyCompleted.next(true);
        this.checkUserBindingAuthority(this.userType.value === 'external', effectiveDate);
        Utils.unblockUI();
      }, err => {
        this.isAgencySubAgencyCompleted.next(true);
        Utils.unblockUI();
        NotifUtils.showError(JSON.stringify(err.error));
      });
    }
  }

  getAllAgenciesSubagencies(agentId: string[]): void {
    this.isGetAgenciesSubAgenciesCompleted.next(false);
    this.agentService.getAllAgenciesSubagencies(agentId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((response: any) => {
      this.getAllAgenciesSubAgencies = response;
      this.isGetAgenciesSubAgenciesCompleted.next(true);
    }, err => {
      this.isGetAgenciesSubAgenciesCompleted.next(true);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getAllAgenciesSubagenciesByAgencyId(agencyId: string[]): void {
    this.isGetAgenciesSubAgenciesCompleted.next(false);
    this.agentService.getAllAgenciesSubagenciesByAgencyId(agencyId).pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe((response: any) => {
      this.getAllAgenciesSubAgenciesByAgencyId = response;
      this.isGetAgenciesSubAgenciesCompleted.next(true);
    }, err => {
      this.isGetAgenciesSubAgenciesCompleted.next(true);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  getAgentInformation(agentId: string): void {
    this.isAgentUserInfoCompleted.next(false);
    this.agentService.getAgentInformation(agentId).subscribe((response: AgentUserResponseDTO) => {
      this.agentUserInfo = response;
      this.isAgentUserInfoCompleted.next(true);
      Utils.unblockUI();
    }, err => {
      this.isAgentUserInfoCompleted.next(true);
      Utils.unblockUI();
      NotifUtils.showError(JSON.stringify(err.error));
    });
  }

  logUserLogin(username: string): any {
    return this.http.post(`${environment.ApiUrl}/User/login`, { username }, { responseType: 'text' }).catch(err => err);
  }

  logUserLogout(username: string): any {
    return this.http.post(`${environment.ApiUrl}/User/logout`, { username }, { responseType: 'text' }).catch(err => err);
  }

  checkIpAddress(): any {
    return this.http.get(`${environment.ApiUrl}/User/check-ip-address`).catch(err => err);
  }

  getServerDate(): any {
    return this.http.get(`${environment.ApiUrl}/User/serverdate`).catch(err => err);
  }

  getPublicToken(): any {
    return this.http.get(`${environment.ApiUrl}/User/get-public-token`).catch(err => err);
  }

  getPublicInsuredToken(): any {
    return this.http.get(`${environment.ApiUrl}/User/get-pulic-insured-token`).catch(err => err);
  }

  checkUserBindingAuthority(isAgent: boolean, effectiveDate): void {
    if (this.agenciesInfo && this.agentUserInfo && !!effectiveDate && !isNaN(effectiveDate)) {
      const isInBetweenSubAGencyDates: boolean = moment(effectiveDate).isBetween(this.subAgenciesInfo?.licenseEffectiveDate, this.subAgenciesInfo?.licenseExpirationDate, 'days', '[]') ?? false;
      const isSubAgencyLicenseExpired: boolean = moment(new Date()).isAfter(this.subAgenciesInfo?.licenseExpirationDate);

      const isAgentAllowedToBind: boolean = this.agenciesInfo?.hasBindingAuthority && this.subAgenciesInfo?.hasBindingAuthority &&
        isInBetweenSubAGencyDates && !isSubAgencyLicenseExpired &&
        this.subAgenciesInfo?.isActive && this.agenciesInfo?.isActive;

      this.isUserAllowedToBind = isAgent ? isAgentAllowedToBind : true;
    }
  }

  getProgramStateByAgentId(agentId: string): void {
    if (!!agentId) {
      this.agentProgramStateDetails = [];
      this.programStates = [];
      this.store.dispatch(updateProgramStateAgentDetailIsLoadingFromAuthService({ isLoading: true }));
      this.store.dispatch(updateProgramStateAgentDetailStateAssignmentFromAuthService({ stateList: null }));
      this.programStateAgentService.getProgramStateAgentDetail(agentId).subscribe((res: ProgramStateResponseDTO) => {
        if (res) {
          res.programStates.forEach(a => {
            this.agentProgramStateDetails.push(a);
          });
          res.stateCodes.forEach(b => {
            this.programStates.push(b);
          });
          this.store.dispatch(updateProgramStateAgentDetailIsLoadingFromAuthService({ isLoading: false }));
          this.store.dispatch(updateProgramStateAgentDetailStateAssignmentFromAuthService({ stateList: res.stateCodes.sort((a,b) => a.stateName.localeCompare(b.stateName)) }));

        }
        this.isAgentUserInfoCompleted.next(true);
        this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: false }));
      }, err => {
        this.isAgentUserInfoCompleted.next(true);
        this.store.dispatch(updateAppIsLoadingFromAuthService({ isLoading: false }));
        this.store.dispatch(updateProgramStateAgentDetailIsLoadingFromAuthService({ isLoading: false }));
        this.store.dispatch(updateProgramStateAgentDetailStateAssignmentFromAuthService({ stateList: null }));
        NotifUtils.showError(JSON.stringify(err.error));
      });
    } else {
      this.isAgentUserInfoCompleted.next(true);
    }
  }

  loginAuditLog(error: string, user: string, isTwoFactor: boolean = false): void {
    const clientDetails = this.clientDetailService.getClientDetails();
    const payload = {
      userId: 0,
      keyId: user,
      auditType: LoginLabelsConstants.loginAuditType,
      description: '',
      method: '',
      os: `${clientDetails.os} ${clientDetails.osVersion}`,
      deviceType: clientDetails.mobile ? 'Mobile' : 'PC',
      browser: clientDetails.browser,
      browserVersion: clientDetails.browserVersion
    };

    switch (error) {
      case LoginLabelsConstants.errorMessage.exist:
        payload.description = LoginLabelsConstants.errorMessage.existDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.getAuth;
        break;
      case LoginLabelsConstants.errorMessage.incorrect:
        payload.description = LoginLabelsConstants.errorMessage.incorrectDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.getAuth;
        break;
      case LoginLabelsConstants.errorMessage.userInactive:
        payload.description = LoginLabelsConstants.errorMessage.userInactiveDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.getAuth;
        break;
      case LoginLabelsConstants.errorMessage.agencyInactive:
        payload.description = LoginLabelsConstants.errorMessage.agencyInactiveDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.onLogin;
        break;
      case LoginLabelsConstants.errorMessage.locked:
        const errDescription = isTwoFactor ? LoginLabelsConstants.errorMessage.lockedTwoFactorDescription : LoginLabelsConstants.errorMessage.lockedLoginDescription;
        payload.description = errDescription.replace('{0}', user);
        payload.method = isTwoFactor ? LoginLabelsConstants.method.onLogin : LoginLabelsConstants.method.getAuth;
        break;
      case LoginLabelsConstants.errorMessage.aleardyLockedlocked:
        payload.description = LoginLabelsConstants.errorMessage.accountLockedDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.getAuth;
        break;
      case LoginLabelsConstants.errorMessage.twoFactor:
        payload.description = LoginLabelsConstants.errorMessage.incorrectTwoFactorDescription.replace('{0}', user);
        payload.method = LoginLabelsConstants.method.onLogin;
        break;
    }

    this.auditLogService.failedLoginAuditLog(payload).subscribe();
  }

  savePasswordAuditLog(action: string, username: string): void {
    let isAuthorizedAuditLog: boolean = true;

    const payload = {
      userId: this.getUserId(),
      keyId: username,
      auditType: '',
      description: '',
      method: ''
    };

    switch (action) {
      case PasswordFormConstants.auditLog.action.requestForgot:
        payload.auditType = PasswordFormConstants.auditLog.forgotPwRequestLog.auditType;
        payload.description = PasswordFormConstants.auditLog.forgotPwRequestLog.description.replace('{0}', username);
        payload.method = PasswordFormConstants.auditLog.methodName.requestForgot;
        isAuthorizedAuditLog = false;
        break;
      case PasswordFormConstants.auditLog.action.successForgot:
        payload.auditType = PasswordFormConstants.auditLog.forgotPwSuccessLog.auditType;
        payload.description = PasswordFormConstants.auditLog.forgotPwSuccessLog.description.replace('{0}', username);
        payload.method = PasswordFormConstants.auditLog.methodName.successForgot;
        isAuthorizedAuditLog = false;
        break;
      case PasswordFormConstants.auditLog.action.successChange:
        payload.auditType = PasswordFormConstants.auditLog.changePwSuccessLog.auditType;
        payload.description = PasswordFormConstants.auditLog.changePwSuccessLog.description.replace('{0}', username);
        payload.method = PasswordFormConstants.auditLog.methodName.change;
        break;
      case PasswordFormConstants.auditLog.action.successReset:
        payload.auditType = PasswordFormConstants.auditLog.resetPwSuccessLog.auditType;
        payload.description = PasswordFormConstants.auditLog.resetPwSuccessLog.description.replace('{0}', username);
        payload.method = PasswordFormConstants.auditLog.methodName.reset;
        break;
    }

    if (isAuthorizedAuditLog) {
      this.auditLogService.insertToAuditLog(payload).subscribe();
    } else {
      this.auditLogService.failedLoginAuditLog(payload).subscribe();
    }
  }


  public IsAccountantUser(): boolean {
    return this.CheckRoleType(RoleType.Accountant);
  }

  public IsUnderWriterUser(): boolean {
    return this.CheckRoleType(RoleType.UnderWriter);
  }

  public CheckRoleType(prefferedRole: RoleType): boolean {
    const currentUser = localStorage.getItem(environment.UserTypeRoleDescription);

    return currentUser?.toLocaleLowerCase() === prefferedRole.toLowerCase();
  }

  get isInternalUser(): boolean {
    return this.userType.value === this.genericConstants.userType.internal;
  }

  get isExternalUser(): boolean {
    return this.userType.value === this.genericConstants.userType.external;
  }

  getTempIPAddress(): Observable<any> {
    return this.http.get(`${environment.ApiUrl}/v1/third-party/ipaddress`)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  getApproverDetails(approverDetails: any): Observable<ApproverDetailsDTO> {
    const details: ReinstatementApprovalViewDTO = <ReinstatementApprovalViewDTO>approverDetails;
    const approvalDetails = details?.approvalDetails;

    if (details.approvalDetails === null || (details.approvalDetails.approvedById === null && details.approvalDetails.rescindedById === null) ||
      approvalDetails?.reinstatementApprovalStatusId === AutoReinstatementConstants.approvalStatus.Expired) {
      return of(null);
    }
    return this.http.get(`${environment.ApiUrl}/User/${approvalDetails.isApproved ? approvalDetails.approvedById : approvalDetails.rescindedById}`).pipe(
      map(result => {
        return result;
      })).catch(this.commonService.handleObservableHttpError);
  }

  unlockUser(username: string): Observable<number> {
    const data = {
      username: username,
      appCode: environment.ApplicationId
    };
    return this.http.post<any>(`${environment.IdentityServiceUrl}/Auth/unlock-account`, data).pipe(
      map((res) => {
        return res;
      }),
      catchError(error => {
        return observableThrowError(error);
      }));
  }

  insertUnlockAuditLog(username: string, entityId: string, isUnlocked: boolean = false): void {
    const currentUser = localStorage.getItem(environment.UsernameKey);
    const description = isUnlocked ? AccountLockoutLabelConstants.userUnlockSuccessMessage : AccountLockoutLabelConstants.userUnlockFailedMessage;

    const payload = {
      userId: this.getUserId(),
      keyId: entityId,
      auditType: AccountLockoutLabelConstants.auditlog.action.unlock,
      description: description.replace('{username}', username).replace('{user}', currentUser),
      method: AccountLockoutLabelConstants.auditlog.methodName.unlock
    };

    if(this.auditLogSubscription) {
      this.auditLogSubscription.unsubscribe();
    }

    this.auditLogSubscription = this.auditLogService.insertToAuditLog(payload).subscribe();
  }

  showUnauthorizedError(): void {
    const defaults: any = {
        title: 'Oops...',
        icon: ErrorMessageConstant.unAuthorizedError.error,
        allowOutsideClick: false
    };
    NotifUtils.showNotice(ErrorMessageConstant.unAuthorizedError.message, defaults, () => {
      this.closeAllOpenModals();
      this.logout();
      this.router.navigate([`/${PathConstants.Login.Index}`]);
    });
  }

  closeAllOpenModals(){
    if(document.querySelector<HTMLElement>('.modal')){
      const modalClass = document.querySelector<HTMLElement>('.modal');
      modalClass.style.display = 'none';
      modalClass.style.position = 'none';
      const modalBackDropShowClass = document.querySelector<HTMLElement>('.modal-backdrop.show');
      modalBackDropShowClass.style.display = 'none';
      const modalBackDropClass = document.querySelector<HTMLElement>('.modal');
      modalBackDropClass.style.display = 'none';

      const list = document.querySelectorAll('.modal');
      for (let i = 0; i < list.length; ++i) {
        list[i].classList.remove('show');
      }
    }
  }

  //#region - BRUL-398: Leap Year Handling (Feb 29)
  //# Do not allow for 2/29 to be selected as a New Business Policy effective date OR Rewrite effective date
  //# If login date = 2/29 and is a leap year, default the effective date to 3/1 (Applicable for New Business only)

  //# Checks if the Login date is 2/29 and is a leap year
  isBRUL398Triggered(inputDate: Date): boolean {
    const isLeapYear = moment(inputDate).isLeapYear();
    const isEndOfFebruary = inputDate.getMonth() + 1 === 2 && inputDate.getDate() === 29;
    return isLeapYear && isEndOfFebruary;
  }

  //# Checks if the Login date is a Leap Year and returns the 2/29 date of the year or the next leap year
  getLeapYearDateToDisable(currentDate: Date): any[] {
    const currentYear = currentDate.getFullYear();
    const isLeapYear = moment([currentYear]).isLeapYear();

    // if current year is a leap year, return 2/29/(currentYear)
    if (isLeapYear) {
      return [
        {
          year: currentYear,
          month: 2,
          day: 29
        }
      ];
    } else {
      // if current year is NOT a leap year, find the next leap year and return 2/29(nextLeapYear)
      let nextYear = currentYear + 1;

      while (!moment([nextYear]).isLeapYear()) {
        nextYear++;
      }

      return [{
        year: nextYear,
        month: 2,
        day: 29
      }];
    }
  }

  // Checks if the Login date is 2/29 and is a leap year, defaults the effective date to 3/1 (New Business only)
  getCorrectEffectiveDate(currentDate: Date): Date {
    if (this.isBRUL398Triggered(currentDate)) {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return currentDate;
  }

  //#endregion

  checkAgencyCommissionRate(request: IGetAgencyPolicyCommissionRateRequestDTO = null, hasSubscription: boolean = false): void {
    if (!request) {
      Utils.isCheckingCommissionRate = true;
      NotifUtils.showError(ErrorMessageConstant.Commission.noCommissionRate, () => {
        this.commissionRateResponse = null;
        Utils.isCheckingCommissionRate = false;
      });

      if (hasSubscription) {
        this.isDoneCommissionRateChecking$.next();
      }

      return;
    }

    this.agencyService.getAgencyPolicyCommissionRate(request).takeUntil(this.stop$).subscribe(result => {
      Utils.isCheckingCommissionRate = true;
      this.isCommissionRateError = false;
      this.commissionRateResponse = result;

      if (!result?.commissionRate || !result?.hasAgencyCommissionSetup) {
        this.isCommissionRateError = true;
        NotifUtils.showError(ErrorMessageConstant.Commission.noCommissionRate, () => {
          Utils.isCheckingCommissionRate = false;
        });
      } else {
        Utils.isCheckingCommissionRate = false;
        this.isCommissionRateError = false;
      }

      if (hasSubscription) {
        this.isDoneCommissionRateChecking$.next();
      }
    }, err => {
      this.isCommissionRateError = true;
      NotifUtils.showError(ErrorMessageConstant.Commission.genericError, () => {
        Utils.isCheckingCommissionRate = false;

        if (hasSubscription) {
          this.isDoneCommissionRateChecking$.next();
        }
      });
    });
  }

  getProgramTypeCodeViaProgramType(programType: string): number {
    let programTypeCode = 0;
    switch(programType.toLocaleLowerCase()) {
      case 'preferred':
        programTypeCode = 1;
        break;
      case 'elite':
        programTypeCode = 2;
        break;
    }
    return programTypeCode;
  }

  getProgramTypeCodeViaStateAndFormType(stateCode: string, formType: string): number {
    if (stateCode === 'FL' || (stateCode === 'LA' && formType === 'DP3')) {
      return 1; //PREFERRED
    } else {
      return 2; //ELITE
    }
  }

  getMaintenanceModeInformation(ipAddress: string): Observable<any> {
    return this.http.get(`${environment.ApiUrl}/User/maintenance?ipAddress=${ipAddress}`).pipe(
      catchError(this.commonService.handleObservableHttpError)
    );
  }

  getIPAddress(): Observable<any> {
    return this.http.get('https://api.ipify.org/?format=json').pipe(
      catchError(err => err)
    );
  }
}
