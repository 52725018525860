import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { QuickQuoteData } from '../../../../../modules/submission-management/data/quick-quote.data';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { QuickQuoteFormConstant, QuickQuotesLabelConstans } from '../../../../../shared/constants/quick-quotes.labels.constants';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import { PropertyData } from '../../../../../modules/submission-management/data/property.data';
import { BaseClass } from '../../../../../shared/base-class';
import { IAngularMyDpOptions, IMyDate } from 'angular-mydatepicker';
import { environment } from '../../../../../../environments/environment';
import { QuoteService } from '../../../../../core/services/quote.service';
import { ApplicantBusinessRules } from '../../../../../modules/submission-management/business-rules/applicant.business-rules';
import { CoveragesData } from '../../../../../modules/submission-management/data/coverages.data';
import { filter, pairwise, take, takeUntil } from 'rxjs/operators';
import { ThirdPartyDataService } from '../../../../../core/services/submission/third-party/third-party-data.service';
import Utils from '../../../../../shared/utilities/utils';
import { ApplicantData } from '../../../../../modules/submission-management/data/applicant.data';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { Validators } from '@angular/forms';
import { ThirdPartyData } from '../../../../../modules/submission-management/data/third-party.data';
import { PropertyValidators } from 'app/modules/submission-management/validators/property.validators';
import { AllResponseDTO } from '../../../../../shared/models/data/dto/third-party/all-response.dto';
import { InfoMessageConstant } from '../../../../../shared/constants/info-message.constants';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import vkbeautify from 'vkbeautify';
import { SummaryData } from '../../../data/summary.data';
import { LocationLabelConstants } from '../../../../../shared/constants/property.labels.constants';
import { ZipcodesService } from '../../../../../core/services/submission/zipcodes/zipcodes.service';
import { ZipCodeData } from '../../../../../modules/submission-management/data/zipcode.data';
import { LvZipCodeDTO } from '../../../../../shared/models/data/dto/quick-quote/lv-zipcode.dto';
import { PageSections } from '../../../../../shared/enum/page-sections.enum';
import { MajorBodyOfWater } from '../../../../../shared/constants/major-body-of-water.list';
import { RaterPremiumService } from '../../../../../core/services/submission/rater-premium/rater-premium.service';
import { SubmissionNavValidationService } from '../../../../../core/services/navigation/submission-nav-validation.service';
import { PathConstants } from '../../../../../shared/constants/path.constants';
import { Pages } from '../../../../../shared/constants/pages-and-sections.constants';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { ApplicantLabelsConstants } from 'app/shared/constants/applicant.labels.constants';
import { FormTypeConstants } from '../../../../../shared/constants/form-types.constants';
import { ApplicantValidatorConstants } from 'app/modules/submission-management/validators/validator-error-constants/applicant-validators.constants';
import { EndorsementsData } from '../../../../../modules/submission-management/data/endorsements.data';
import { CoveragesValidators } from 'app/modules/submission-management/validators/coverages.validators';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ThirdPartyErrorDTO } from 'app/shared/models/data/dto/third-party/third-party-error.dto';
import { BCEGSService } from '../../../../../core/services/submission/third-party/ISO-Location/BCEGSService';
import { ComparativeRaterConstants } from '../../../../../shared/constants/rater.constant';
import { ComparativeRaterData } from '../../../../comparative-rater/comparative-rater.data';
import { ComparativeMappedPayload, ComparativeRequestPayload } from '../../../../../shared/models/rater-premium/comparativeRate.model';
import { LvProtectionClass } from '../../../../../shared/constants/property.options.constants';
import { LvNameSuffix } from '../../../../../shared/constants/applicant.options.constants';
import { EntityRiskDTO } from 'app/shared/models/data/dto/quick-quote/entity-risk.dto';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';
import { INavDetails } from 'app/_nav';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { ApplicantValidationService } from 'app/core/services/submission/validations/applicant-validation.service';
import { LvCreditOrderStatus } from 'app/shared/constants/quick-quote.options.constants';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import { CoverageBusinessRules } from '../../../business-rules/coverage.business-rules';
import { ApplicationInformationDTO } from 'app/shared/models/data/dto/third-party/application-information.dto';

@Component({
  selector: 'app-applicant-information',
  templateUrl: './applicant-information.component.html',
  styleUrls: ['./applicant-information.component.scss']
})
export class ApplicantInformationComponent extends BaseClass implements OnInit, OnChanges {
  public ErrorMessageConstant = ErrorMessageConstant;
  public ApplicantsLabel = QuickQuotesLabelConstans.applicantPageLabel;
  public InfoMessageConstant = InfoMessageConstant;
  public ApplicantValidatorConstants = ApplicantValidatorConstants;
  public QuickQuoteFormConstant = QuickQuoteFormConstant;
  public ComparativeRaterConstants = ComparativeRaterConstants;
  public PageSections = PageSections;
  public Pages = Pages;
  GenericLabel = GenericLabel;
  @Input() isOrder: boolean;
  @Input() userType: string;
  @Output() isOrderPrefill: EventEmitter<boolean> = new EventEmitter<boolean>();
  effectiveDateOption: IAngularMyDpOptions;
  isOpen: boolean = true;
  showBelow18Age: boolean = false;
  showBelow1800: boolean = false;
  showFutureDate: boolean = false;
  public FormUtils = FormUtils;
  effectivityDate: Date;
  futureDate: Date;
  formType: string;
  formTypeConstants = FormTypeConstants;
  isPrefillClicked: boolean = false;
  showOrderCreditInfo: boolean = false;
  isOrderCreditAllowed: boolean = false;
  protected isOrderCreditChecked: boolean = false;
  protected isNewQuoteFromComparative: boolean = false;
  hasValuationId: boolean = false;
  iseffectiveDatePastDate: boolean = false;

  constructor(
    public quickQuoteData: QuickQuoteData,
    public applicantData: ApplicantData,
    protected submissionData: SubmissionPageData,
    protected propertyData: PropertyData,
    protected coveragesData: CoveragesData,
    protected quoteService: QuoteService,
    protected applicantBusinessRules: ApplicantBusinessRules,
    protected thirdPartyDataService: ThirdPartyDataService,
    protected thirdPartyData: ThirdPartyData,
    public entityRiskData: EntityRiskData,
    public summaryData: SummaryData,
    protected detect: ChangeDetectorRef,
    public zipCodeData: ZipCodeData,
    protected raterPremiumService: RaterPremiumService,
    protected submissionNavValidationService: SubmissionNavValidationService,
    protected endorsementData: EndorsementsData,
    protected bCEGSService: BCEGSService,
    protected comparativeRaterData: ComparativeRaterData,
    protected propertyBusinessRules: PropertyBusinessRules,
    protected uwApprovalData: UwApprovalData,
    protected applicantValidationService: ApplicantValidationService,
    protected coveragesBusinessRules: CoverageBusinessRules) {
    super();
    this.thirdPartyData.creditOrderDetail$.subscribe(detail => {
      const data = this.entityRiskData?.EntityRisk;
      const isPolicy = localStorage.getItem(`${data?.risks[0]?.id}_${GenericConstants.isPolicy}`) === 'true';
      const creditOrderStatusFromBackup = isPolicy && data?.entityBackUp ? LvCreditOrderStatus.find(s => s.code === data?.entityBackUp?.creditScoreStatusCode).description : '';
      const creditOrderStatus = isPolicy && data?.entityBackUp ? creditOrderStatusFromBackup : detail.statusDescription;
      this.summaryData.SummaryForm.get('creditOrderStatus').setValue(creditOrderStatus);
    });
  }

  get QuickQuoteApplicantForm() {
    return this.applicantData.mainApplicant;
  }

  get PropertyLocationDetailsForm() {
    return this.propertyData.locationDetails;
  }

  get PropertyDetailsForm() {
    return this.propertyData.propertyDetails;
  }

  get isOrderCreditDisable(): boolean {
    return this.quickQuoteData.isOrderCreditValue;
  }

  get isDOBFromComparativeValid(): boolean {
    return this.isNewQuoteFromComparative && !this.quickQuoteData?.orderPrefill ?
      (this.showBelow1800 || this.showBelow18Age || this.showFutureDate) : false;
  }

  ngOnInit() {
    this.setEffectiveDate();
    this.onValueChangeRetiree();
    this.setStateLocalStorage();
    this.quoteService.formTypeObs$.pipe(takeUntil(this.stop$)).subscribe((formType) => this.formType = formType);
    this.quickQuoteData.orderPrefill = this.summaryData.SummaryForm.get('entityId').value ? true : this.quickQuoteData.orderPrefill;
    this.applicantBusinessRules.updateApplicantNameValidators(this.userType);
    if (this.quickQuoteData.orderPrefill && this.summaryData.SummaryForm.get('entityId').value) {
      this.QuickQuoteApplicantForm.get('isOrderYesNo').setValue(this.quickQuoteData.isOrderCreditValue);
    }
      this.summaryData.initializeOrderCredit();
      this.summaryData.authService.allowOrderCredit$.next();

    const compRaterDetails = JSON.parse(localStorage.getItem('comparativeRater'));
    if (Boolean(compRaterDetails?.isFromCompRater && !this.quickQuoteData.orderPrefill)){
      this.comparativeRaterData.isFromComparativeRater = Boolean(compRaterDetails?.isFromCompRater);
      this.comparativeRaterData.comparativeDetails = compRaterDetails?.request;
      this.comparativeRaterData.comparativeMappedPayload = compRaterDetails?.mapped;
      this.populateQuickQuoteFromComparative(compRaterDetails?.request, compRaterDetails?.mapped);
      this.comparativeRaterData.getValidComparativeDropdownValues(this.comparativeRaterData.comparativeDetails);
      this.isNewQuoteFromComparative = this.entityRiskData?.isNewQuote && this.comparativeRaterData.isFromComparativeRater;
      this.coveragesData.isActiveCentauriHOPolicyFromComparativeRater = (compRaterDetails?.request?.policy?.form?.toUpperCase() === this.formTypeConstants.DP3) && this.comparativeRaterData.isFromComparativeRater;
    }
  }

  ngOnChanges() {
    this.setEffectiveDate();
  }

  setStateLocalStorage() {
    if (!this.QuickQuoteApplicantForm.get('qqZipCode').value) {
      this.QuickQuoteApplicantForm.get('qqState').setValue((this.quoteService?.state ?? JSON.parse(sessionStorage.getItem(environment.QuoteFormTypeAndState))?.state));
    }
  }

  setEffectiveDate() {
    const futureDate = this.getFutureDate(91);
    const today = this.summaryData.authService.getCustomDate();
    if (this.userType === 'external') {
      const yesterday: Date = this.getYesterdayDate();
      this.quickQuoteData.effectiveDate.disableUntil = {
        year: yesterday.getFullYear(),
        month: yesterday.getMonth() + 1,
        day: yesterday.getDate()
      };
      this.quickQuoteData.effectiveDate.disableSince = this.define90days(futureDate);
      this.quickQuoteData.effectiveDate.disableDates = this.summaryData.authService.getLeapYearDateToDisable(today);
    } else {
      this.effectiveDateOption = {
        disableUntil: {
          year: 0,
          month: 0,
          day: 0
        },
        disableDates: this.summaryData.authService.getLeapYearDateToDisable(today),
        disableSince: this.define90days(futureDate),
        dateFormat: 'mm/dd/yyyy',
        dateRange: false
      };
    }
  }

  onValueChange(value, desc) {
    if (value) {
      NotifUtils.showConfirmMessage({ html: ApplicantLabelsConstants.displayFCRAWording, width: '60%' },
        () => {
          this.quickQuoteData.isOrderCreditValue = false;
        },
        () => {
          this.QuickQuoteApplicantForm.get('isOrderYesNo').setValue(false);
          this.quickQuoteData.Applicant.patchValue({
            isOrderYesNo: false
          });
        });
    } else {
      this.applicantData.fcraReportingActForm.get('fcraReportingAct').setValidators(Validators.requiredTrue);
      this.applicantData.fcraReportingActForm.get('fcraReportingAct').updateValueAndValidity();
      this.submissionNavValidationService.validateCurrentCategory(PathConstants.Applicant.Applicant.ApplicantPage);
    }
  }

  populateFieldsBasedThirdParty(result: AllResponseDTO) {
    const lessThan100 = ['01', '02'];
    const lessThan2500 = ['03', '04', '05'];
    const distanceToWaterCode = this.thirdPartyData.dataFromAPIResponse.distanceToWaterCode;
    const distanceToActualCoastCode = this.thirdPartyData.dataFromAPIResponse.distanceToActualCoastCode;
    const majorBodyOfWater = MajorBodyOfWater;
    const isValidForNewMinimumDeductibleStateWide = this.coveragesData.isValidForNewMinimumDeductibleStateWide();

    const hurricaneDeductibleDefaultValue = isValidForNewMinimumDeductibleStateWide ? '2' : '5';

    this.coveragesData.intCoveragesHurricaneDeductibleInfoMessage();

    if (this.formType === this.formTypeConstants.HO3 || this.formType === this.formTypeConstants.DP3) {
      if (distanceToWaterCode === distanceToActualCoastCode ||
        Number(distanceToActualCoastCode) < Number(distanceToWaterCode)) {
        if (lessThan100.includes(distanceToActualCoastCode)) {
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('E');
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
        } else if (lessThan2500.includes(distanceToActualCoastCode)) {
          //CNTMAIN-275 change minimum info message to 2%
          this.coveragesData.hurricaneDeductible2InfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurricaneDeductible5InfoMessage = !isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(hurricaneDeductibleDefaultValue);
        }
      } else if (Number(distanceToWaterCode) < Number(distanceToActualCoastCode)) {
        if (lessThan100.includes(distanceToWaterCode) && majorBodyOfWater.includes(result.nearestWaterName)) {
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('E');
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = true;
        } else if (lessThan2500.includes(distanceToWaterCode) && majorBodyOfWater.includes(result.nearestWaterName)) {
          //CNTMAIN-275 change minimum info message to 2%
          this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(hurricaneDeductibleDefaultValue);
        }
        if (lessThan100.includes(distanceToWaterCode) &&
          lessThan2500.includes(distanceToActualCoastCode) &&
          (Number(distanceToActualCoastCode) > Number(distanceToWaterCode))) {
          //CNTMAIN-275 change minimum info message to 2%
          this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(hurricaneDeductibleDefaultValue);
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = false;
        }
      } else if (lessThan100.includes(distanceToActualCoastCode) ||
        (lessThan100.includes(distanceToWaterCode) && majorBodyOfWater.includes(result.nearestWaterName))) {
          this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('E');
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
      }

      if (lessThan100.includes(distanceToWaterCode) &&
          lessThan100.includes(distanceToActualCoastCode) &&
          majorBodyOfWater.includes(result.nearestWaterName)) {
        this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue('E');
        //CNTMAIN-275 change minimum info message to 2%
        this.coveragesData.hurrDed2DueToWaterInfoMessage = isValidForNewMinimumDeductibleStateWide;
        this.coveragesData.hurrDed5DueToWaterInfoMessage = !isValidForNewMinimumDeductibleStateWide;
        this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = true;
        if (distanceToWaterCode === distanceToActualCoastCode) {
          this.coveragesData.hurrDedExcludedDueToWaterInfoMessage = false;
          this.coveragesData.hurricaneDeductibleExcludedInfoMessage = true;
        }
      }
    }

    if (Array.isArray(result?.county) && result?.county.length > 1) {
      this.propertyData.countyList = result.county;
      this.PropertyLocationDetailsForm.get('county').setValue('');
      this.PropertyLocationDetailsForm.get('county').enable();
    } else if (Array.isArray(result?.county) && result?.county.length === 1 && result?.county) {
      this.PropertyLocationDetailsForm.get('county').setValue(result.county[0]);
      this.propertyData.countyList = result.county;
      this.PropertyLocationDetailsForm.get('county').disable();
    } else {
      const zipCode = this.QuickQuoteApplicantForm.get('qqZipCode').value;
      const state = this.zipCodeData.stateCode;
      this.zipCodeData.setPropertyCounty(zipCode, state, this.PropertyLocationDetailsForm);
    }

    this.coveragesData.county = this.PropertyLocationDetailsForm.get('county')?.value;

    if (this.PropertyLocationDetailsForm.get('county')?.value?.toString().toLowerCase() === this.QuickQuoteFormConstant.Coverages.Monroe) {
      this.coveragesData.intCoveragesHurricaneDeductibleInfoMessage();
      this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid').setValue(this.QuickQuoteFormConstant.Coverages.Excluded);
      this.coveragesData.validateHurricaneDeductible();
    }

    if (result.distanceToWater === '' || result.distanceToWater === null) {
      this.PropertyLocationDetailsForm.get('distanceToWater').enable();
    } else {
      this.PropertyLocationDetailsForm.get('distanceToWater').setValue(result.distanceToWater);
    }

    if (result.distanceToActualCoast === '' || result.distanceToActualCoast === null) {
      this.PropertyLocationDetailsForm.get('distanceToActualCoast').enable();
    } else {
      this.PropertyLocationDetailsForm.get('distanceToActualCoast').setValue(result.distanceToActualCoast);
    }

    if (result.protectionClassLocationLowerNumber === '' || result.protectionClassLocationLowerNumber === null) {
      this.PropertyLocationDetailsForm.get('protectionClass').enable();
      this.PropertyLocationDetailsForm.get('protectionClass').setValue('');
    } else {
      this.PropertyLocationDetailsForm.get('protectionClass').setValue(result.protectionClassLocationLowerNumber);
      this.propertyData.protectionClassLowerNumber = result.protectionClassLocationLowerNumber;
    }

    if (result?.protectionClassLocationHigherNumber) {
      this.propertyData.protectionClassHigherNumber = result.protectionClassLocationHigherNumber;
    }

    if (this.comparativeRaterData.isFromComparativeRater && result?.iso360Value?.isNoHitAddress &&
        (!Boolean(result?.protectionClassLocationHigherNumber) && !Boolean(result?.protectionClassLocationLowerNumber))) {
      const raterPPC = LvProtectionClass.find(x => x.code === this.comparativeRaterData.comparativeDetails?.property?.protectionClass)?.code  ?? '';
      this.propertyData.protectionClassName = `${raterPPC}/${raterPPC}`;
    } else {
      this.propertyData.protectionClassName = `${result?.protectionClassLocationLowerNumber}/${result?.protectionClassLocationHigherNumber}`;
    }

    if (result.milesToFireStation === '' || result.milesToFireStation === null) {
      this.PropertyLocationDetailsForm.get('milesToFireStation').enable();
    } else {
      this.PropertyLocationDetailsForm.get('milesToFireStation').disable();
      if (result.fireStationDistanceBand >= 1 && result.fireStationDistanceBand <= 5) {
        this.PropertyLocationDetailsForm.get('milesToFireStation').setValue(LocationLabelConstants.MilesToFireStationFiveRoadMilesOrLess);
      } else {
        this.PropertyLocationDetailsForm.get('milesToFireStation').setValue(LocationLabelConstants.MilesToFireStationFiveRoadMilesOrMore);
      }
    }

    if (result.fireDistrictName === '' || result.fireDistrictName === null) {
      this.PropertyLocationDetailsForm.get('fireDistrictName').enable();
    } else {
      this.PropertyLocationDetailsForm.get('fireDistrictName').setValue(result.fireDistrictName);
    }

    if (result.oceanName === '' || result.oceanName === null) {
      this.PropertyLocationDetailsForm.get('oceanName').enable();
    } else {
      this.PropertyLocationDetailsForm.get('oceanName').setValue(result.oceanName);
    }


    if (result.windPoolEligible === 'Y' || result.windPoolEligible === 'y') {
      this.PropertyLocationDetailsForm.get('windPoolEligible').setValue(true);
    } else {
      this.PropertyLocationDetailsForm.get('windPoolEligible').setValue(false);
    }

    if (result.respondingFireDepartment === '' || result.respondingFireDepartment === null) {
      this.PropertyLocationDetailsForm.get('respondingFireDept').enable();
    } else {
      this.PropertyLocationDetailsForm.get('respondingFireDept').setValue(result.respondingFireDepartment);
    }

    if (result.nearestWaterName === '' || result.nearestWaterName === null) {
      this.PropertyLocationDetailsForm.get('nearestWaterName').enable();
    } else {
      this.PropertyLocationDetailsForm.get('nearestWaterName').setValue(result.nearestWaterName);
    }

    if (result.matchLevel === '' || result.matchLevel === null) {
      this.PropertyLocationDetailsForm.get('matchLevel').enable();
    } else {
      this.PropertyLocationDetailsForm.get('matchLevel').setValue(result.matchLevel);
    }

    this.bCEGSService.BCEGSMapperUsingAllResponse(result,this?.propertyData?.locationDetails);
    this.bCEGSService.SaveAResponseToStorage(result);
    this.propertyBusinessRules.triggerActiveNew125(false, true);
  }

  preFillPropertyAndCoverage(event) {
    this.thirdPartyData.isCurrentlyOrdering = true;

    if (this.showOrderCreditInfo) {
      this.showOrderCreditInfo = false;
    }
    this.applicantData.isPrefillClicked = true;
    this.zipCodeData.isPrefillClicked = true;
    this.quickQuoteData.orderPrefill = true;
    this.quickQuoteData.isOrderCreditValue = this.QuickQuoteApplicantForm.get('isOrderYesNo').value;
    const thirdPartyRequest: ApplicationInformationDTO = this.thirdPartyData.getPrefillRequestModel();
    Utils.blockUI();

    this.isOrderCreditChecked = false;
    if (this.QuickQuoteApplicantForm.get('isOrderYesNo').value) {
      this.isOrderCreditChecked = true;
      if (!this.getInternalUserType()) {
        thirdPartyRequest.isOrderCredit = this.summaryData.isOrderCreditAllowed;
      }
    }

    this.thirdPartyDataService.getThirdPartyData(thirdPartyRequest).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.thirdPartyData.isDataFromOrderPrefill = true;
      this.thirdPartyData.dataFromAPIResponse = result as AllResponseDTO;
      this.hasValuationId = result?.iso360Value !== null && result?.iso360Value?.valuationId !== null && result?.iso360Value?.valuationId !== '';
      this.thirdPartyData.mapThirdPartyResultToSubmission(result);
      this.thirdPartyData.isNoHitIso360ValueFirstCall = this.thirdPartyData.dataFromAPIResponse?.iso360Value?.isNoHitAddress;

      this.populateFieldsBasedThirdParty(result);
      this.submissionData.isOrder = true;
      this.savePreviousApplicantInfo();
      if (this.formType !== this.formTypeConstants.HO4) {
        this.updateYearRoofCustomValidators(Number(result.iso360Value.yearBuilt));
      }
      this.updateNextButtonPosition();

      this.processPrefillErrors(result);

      this.thirdPartyData.iso360YearBuilt = Number(result.iso360Value.yearBuilt);
      this.thirdPartyData.isCurrentlyOrdering = false;

      this.getTerritories(thirdPartyRequest);
    }, error => {
      Utils.unblockUI();
      this.coveragesData.hasSuccessOrderCredit = false;
      NotifUtils.showError(ErrorMessageConstant.contactAdminErrorMessage);
      this.submissionData.isOrder = false;
      this.submissionData.isSuccessfulOrder = false;
      this.updateNextButtonPosition();
      this.thirdPartyData.isCurrentlyOrdering = false;
      this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
    });
  }

  protected displayErrorDialog(result: AllResponseDTO) {
    const allResponse = this.thirdPartyData.dataFromAPIResponse;
    const hasPrefillErrors: boolean = result.errors ? true : false;
    let errorMsg = '';

    if (this.quickQuoteData.isOrderCreditValue &&
      allResponse.creditScoreInfo?.errorCode === ErrorMessageConstant.CreditService.CS02ErrorCode) {
      if (!hasPrefillErrors) {
        NotifUtils.showError(ErrorMessageConstant.CreditService.CS02ErrorCodeMessage);
      } else {
        errorMsg = ErrorMessageConstant.CreditService.CS02ErrorCodeMessage;
      }
      this.QuickQuoteApplicantForm.get('isOrderYesNo').setValue(false);
      this.quickQuoteData.isOrderCreditValue = false;
    } else if (this.quickQuoteData.isOrderCreditValue &&
      (allResponse.creditScoreInfo?.errorCode === ErrorMessageConstant.CreditService.CE01ErrorCode ||
        allResponse.creditScoreInfo?.errorCode === ErrorMessageConstant.CreditService.NcfApiErrorCode)) {
      if (!hasPrefillErrors) {
        NotifUtils.showError(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1);
      } else {
        errorMsg = ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1;
      }
      this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
      this.quickQuoteData.isOrderCreditValue = false;
    } else if (this.quickQuoteData.isOrderCreditValue &&
      allResponse.creditScoreInfo?.errorCode === ErrorMessageConstant.CreditService.GeneralErrorCode) {
      if (!hasPrefillErrors) {
        NotifUtils.showError(ErrorMessageConstant.CreditService.GeneralErrorMessage);
      } else {
        errorMsg = ErrorMessageConstant.CreditService.GeneralErrorMessage;
      }
      this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
      this.quickQuoteData.isOrderCreditValue = false;
    } else if (this.quickQuoteData.isOrderCreditValue &&
      allResponse.creditScoreInfo?.errorCode === ErrorMessageConstant.CreditService.NcfApiAccountCodeErrorCode) {
      if (!hasPrefillErrors) {
        NotifUtils.showError(ErrorMessageConstant.CreditService.AccountSetupBrQq41InvaliderrorMessage);
      } else {
        errorMsg = ErrorMessageConstant.CreditService.AccountSetupBrQq41InvaliderrorMessage;
      }
      this.applicantData.mainApplicant.get('isOrderYesNo').setValue(false);
      this.quickQuoteData.isOrderCreditValue = false;
    } else if (this.quickQuoteData.isOrderCreditValue &&
      (allResponse.creditScoreInfo === null || allResponse.creditScoreInfo === undefined)) {
      if (!hasPrefillErrors) {
        NotifUtils.showError(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1);
      } else {
        errorMsg = ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1;
      }
      this.quickQuoteData.isOrderCreditValue = false;
      this.thirdPartyData.setFcraValidatorOnError();
    }

    switch (allResponse.creditScoreInfo?.errorCode) {
      case ErrorMessageConstant.CreditService.CS02ErrorCode:
      case ErrorMessageConstant.CreditService.CE01ErrorCode:
      case ErrorMessageConstant.CreditService.NcfApiErrorCode:
      case ErrorMessageConstant.CreditService.GeneralErrorCode:
        // This must run after the validation of
        // this.quickQuoteData.isOrderCreditValue == true
        this.thirdPartyData.setFcraValidatorOnError();
        break;
      default:
        break;
    }

    const errorList = [];
    let disablePrefill = true;

    if (result?.errors || result?.errors?.length > 0) {
      _.map(result?.errors, (error: ThirdPartyErrorDTO) => {
        switch (error) {
          case ErrorMessageConstant.EC_IsoLocation:
          case ErrorMessageConstant.EC_IsoLocation_BCEGS:
          case ErrorMessageConstant.EC_IsoLocation_DistanceToCoast:
          case ErrorMessageConstant.EC_IsoLocation_PPC:
          case ErrorMessageConstant.EC_IsoLocation_PropertyTerritory:
          case ErrorMessageConstant.EC_IsoLocation_WindPool:
          case ErrorMessageConstant.EC_IsoLocation_GroupIITerritoryPialZone:
            errorList.push(ErrorMessageConstant.IsoLocationErrorMessage);
            disablePrefill = false;
            break;
          case ErrorMessageConstant.EC_Iso360Value:
            errorList.push(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ValueConnectionFailedMessage);
            disablePrefill = false;
            break;
          case ErrorMessageConstant.EC_AirHazard:
            errorList.push(ErrorMessageConstant.IsoAirHazardErrorMessage);
            disablePrefill = false;
            break;
          case ErrorMessageConstant.EC_Torrent:
            errorList.push(ErrorMessageConstant.TorrentFlood.torrentFailedMessage);
            disablePrefill = false;
            break;
          case ErrorMessageConstant.EC_LexisNexis:
            errorList.push(ErrorMessageConstant.CreditService.LexisNexisNcfFailed_V1);
            disablePrefill = false;
            break;
          default:
            errorList.push(ErrorMessageConstant.thirdPartyGenericErrorMessage);
            disablePrefill = false;
            break;
        }
      });
    }

    if (!this.hasValuationId) {
      errorList.push(ErrorMessageConstant.Iso360ValueErrorMessage.Iso360ValueConnectionFailedMessage);
      disablePrefill = false;
    }

    this.submissionData.isSuccessfulOrder = disablePrefill;

    const applicantForm = this.applicantData.mainApplicant;
    if (this.isOrderCreditChecked && !this.summaryData.isOrderCreditAllowed) {
      applicantForm.get('isOrderYesNo').setValue(false);
      errorList.push(ErrorMessageConstant.CreditService.AccountSetupBRQQ41ErrorMessage);
    }

    if (errorMsg?.length > 0) {
      errorList.push(errorMsg);
    }

    if (errorList?.length > 0) {
      errorList.push();
      NotifUtils.showThirdPartyAPIError(errorList);
    }
  }

  updateNextButtonPosition(): void {
    const cardBody = document.getElementById('card-body');
    cardBody.style.bottom = '0';
  }

  public calculateAge(event, isAutoPopulatedFromComparativeData: boolean = false): void {
    const dateOfBirth = isAutoPopulatedFromComparativeData ? event : event.jsDate;
    if (dateOfBirth) {
      const age = this.applicantBusinessRules.calculateInsuredAge(dateOfBirth);
      this.quickQuoteData.isRetired = this.applicantBusinessRules.isInAgeOfRetirement(age);
      this.applicantData.isRetired = this.applicantBusinessRules.isInAgeOfRetirement(age);
      this.applicantData.age = age;

      if (age < 55) {
        this.applicantData.mainApplicant.get('isRetiredYesNo').setValue(false);
      }

      if (age < 18) {
        this.showBelow18Age = true;
        NotifUtils.showError(ErrorMessageConstant.below18age, () => {
          this.QuickQuoteApplicantForm.get('dateOfBirth').setValue(null);
        });
      } else {
        this.showBelow18Age = false;
      }
      this.detectDateOfBirthChange(dateOfBirth);
      this.detect.detectChanges();
    }
  }

  protected detectDateOfBirthChange(dateOfBirth: any) {
    const isNewQuote = this.entityRiskData.getRiskId() ? false : true;
    if (isNewQuote) {
      this.showOrderCreditInfo = this.applicantBusinessRules.validateApplicantDateOfBirth(dateOfBirth);
      this.validateOrderCredit();
    }
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isApplicantFormOpen = this.isOpen;
  }

  onDateChanged(event?) {
    this.propertyData.purchaseDateOption.disableSince = event.date;
    const effectivityDate = event.jsDate;
    const dateOfBirth = this.QuickQuoteApplicantForm.get('dateOfBirth').value?.singleDate?.jsDate;
    const validAge = 18;

    if (dateOfBirth && effectivityDate) {
      const age = this.applicantBusinessRules.calculateInsuredAgeBasedOnEffectivityDate(dateOfBirth, effectivityDate);
      this.quickQuoteData.isRetired = this.applicantBusinessRules.isInAgeOfRetirement(age);
      if (age < validAge) {
        NotifUtils.showError(ErrorMessageConstant.below18age, () => {
          this.QuickQuoteApplicantForm.get('effectiveDate').setValue(null);
          this.showBelow18Age = true;
        });
      } else {
        this.showBelow18Age = false;
        this.QuickQuoteApplicantForm.get('effectiveDate').setValue(event);
      }
      this.detect.detectChanges();

      if(this.QuickQuoteApplicantForm.get('effectiveDate').valid){
        sessionStorage.setItem('selectedEffectiveDate', event.jsDate);
      }

      this.summaryData.authService.isDoneCommissionRateChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {
        this.zipCodeData.recreateMenuAfterCommissionRateChecking(this.getCompleteSubmissionDetailsMenuItems());
      });

      this.quickQuoteData.getCommissionRate(true, true);
    }

    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const effectiveDate = this.QuickQuoteApplicantForm.get('effectiveDate').value;
    this.coveragesData.validateHurricaneDeductible(yearBuilt, effectiveDate);

    if (effectivityDate) {
      Utils.blockUI();
      const isNewQuote = this.entityRiskData.getRiskDetailId() ? false : true;
      this.quickQuoteData.callRecheckMoratorium(isNewQuote, moment(new Date(effectivityDate)).format('YYYY-MM-DD'));
      if (!isNewQuote) {
        this.zipCodeData.isDoneMoratoriumChecking$.pipe(takeUntil(this.stop$)).subscribe(() => {
          if (!this.zipCodeData.isDisableControlsDueToMoratorium) {
            this.submissionNavValidationService.validateCategories();
            this.zipCodeData.recreateMenuAfterMoratoriumChecking(false, this.getCompleteSubmissionDetailsMenuItems());
          } else {
            this.zipCodeData.recreateMenuAfterMoratoriumChecking(true, null);
          }
          Utils.unblockUI();
        });
      } else {
        Utils.unblockUI();
      }

      this.propertyBusinessRules.triggerActiveNew125(false, false, true);
      this.coveragesBusinessRules.setupCoverageACValidatorByFormType();
    }
  }

  setZipCode(isFromComparativeRater?: boolean) {
    this.QuickQuoteApplicantForm.get('qqCity').enable();
    const zipCode = this.QuickQuoteApplicantForm.get('qqZipCode').value;
    const formControlNames = ['qqState', 'qqCity', 'qqZipCode'];
    this.zipCodeData.qqCityList = [];
    this.zipCodeData.isZipCodeFromComparativeRater = isFromComparativeRater ? true : false;

    if (zipCode !== '') {
      Utils.blockUI();
      const state = this.zipCodeData.stateCode;
      this.zipCodeData.getZipCodeWithState(zipCode, state, this.QuickQuoteApplicantForm, formControlNames, PageSections.QuickQuoteApplicant);
    } else {
      this.FormUtils.resetFields(this.QuickQuoteApplicantForm, formControlNames);
      this.zipCodeData.qqCityList = [];
      Utils.unblockUI();
    }

    this.QuickQuoteApplicantForm.get('qqCity').markAsTouched();
  }

  hasRetireeDiscount(isRetiredYesNo): boolean {
    return this.quickQuoteData.isRetired && isRetiredYesNo;
  }

  onValueChangeRetiree() {
    this.QuickQuoteApplicantForm.get('isRetiredYesNo').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
      this.coveragesData.discountsForm.get('seniorRetiree').setValue(this.hasRetireeDiscount(value));
      this.QuickQuoteApplicantForm.get('isRetired')?.setValue(this.quickQuoteData.isRetired);
    });
  }

  protected getYesterdayDate(): Date {
    const today: Date = this.summaryData.authService.getCustomDate();

    // set the correct date for past date limit for external users
    today.setDate(this.summaryData.authService.isBRUL398Triggered(today) ? today.getDate() : today.getDate() - 1);
    return today;
  }

  protected getFutureDate(numberOfDaysToAdd: number): Date {
    const today: Date = this.summaryData.authService.getCustomDate();
    today.setDate(today.getDate() + numberOfDaysToAdd);
    return today;
  }

  protected define90days(futureDate: Date): IMyDate {
    return {
      year: futureDate.getFullYear(),
      month: futureDate.getMonth() + 1,
      day: futureDate.getDate()
    };
  }

  onChange(event) {
    const below1800 = new Date('12/31/1799');
    const dateOfBirth = new Date(event.value);
    const dateToday = this.summaryData.authService.getCustomDate();

    if (!isNaN(Date.parse(event.value))) {
      this.showBelow1800 = (dateOfBirth <= below1800);
    } else {
      this.showBelow1800 = false;
    }

    if (dateOfBirth > dateToday) {
      this.showFutureDate = true;
    } else {
      this.showFutureDate = false;
    }

  }

  onInputFieldChanged(event?, valuePasted?: string) {
    this.effectivityDate = new Date(event?.value ? event?.value : valuePasted);
    this.futureDate = this.getFutureDate(90);
    this.iseffectiveDatePastDate = this.effectivityDate < this.summaryData.authService.getCustomDate();
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt').value);
    const effectiveDate = this.applicantData.mainApplicant.get('effectiveDate').value;

    if (effectiveDate && this.formType === this.formTypeConstants.DP3) {
      const isLimitedTheftCoverageAvailable = this.endorsementData.showLimitedTheftCoverageToggle();
      if (!isLimitedTheftCoverageAvailable) {
        this.endorsementData.endorsementsGroup.get('limitedTheftCoverage').setValue(isLimitedTheftCoverageAvailable);
      }
      this.endorsementData.isLimitedTheftCoverageAvailable = isLimitedTheftCoverageAvailable;
    }

    this.coveragesBusinessRules.setupCoverageACValidatorByFormType();
    this.thirdPartyData.checkBRUL135to140();
    this.coveragesData.validateHurricaneDeductible(yearBuilt, effectiveDate);
  }

  updateYearRoofCustomValidators(yearBuilt: number): void {
    this.PropertyDetailsForm.get('yearRoof').setValidators([Validators.required, Validators.min(1800),
    PropertyValidators.yearRoofShouldNotBeOlderThanYearBuiltValidator(yearBuilt),
    PropertyValidators.yearRoofInstalledCannotBeAFutureYearValidator(this.summaryData.authService.getCustomDate())]);
    this.PropertyDetailsForm.get('yearRoof').updateValueAndValidity();
    this.PropertyDetailsForm.get('yearBuilt').updateValueAndValidity();
  }

  onBlurApplicantName(formControl: string): void {
    this.applicantBusinessRules.showApplicantNameCorporationInvalidErrorMessage(formControl);
    this.detectApplicantInfoChanges(formControl);
  }

  onBlurApplicantMiddleInitial(formControl: string): void {
    this.applicantBusinessRules.showApplicantNameCorporationInvalidErrorMessage(formControl);
    this.detectApplicantInfoChanges(formControl);
  }

  protected detectApplicantInfoChanges(formControl: string) {
    const isNewQuote = this.entityRiskData.getRiskId() ? false : true;
    if (isNewQuote) {
      this.showOrderCreditInfo = this.applicantBusinessRules.validateApplicantInfo(formControl);
    this.validateOrderCredit();
    }
  }

  protected validateOrderCredit(): void {
    if (this.showOrderCreditInfo) {
      this.quickQuoteData.isOrderCreditValue = false;
    }
  }

  savePreviousApplicantInfo(): void {
    const hasNoRiskId = this.entityRiskData.getRiskId() ? false : true;
    if (this.quickQuoteData.isOrderCreditValue && hasNoRiskId) {
      // always save
      this.applicantData.savePreviousApplicantInfo();
    }
  }

  protected getInternalUserType(): boolean {
    const isInternal = localStorage.getItem('userType') === 'internal';
    return isInternal;
  }

  populateQuickQuoteFromComparative(requestData: ComparativeRequestPayload, mappedData: ComparativeMappedPayload): void {
    const applicantDetails = requestData?.applicant.find(x => x.applicantType === 1);
    const propertyDetails = requestData?.property;
    const coApplicantDetails = requestData?.applicant.find(x => x.applicantType === 2);

    this.QuickQuoteApplicantForm.get('firstName').setValue(applicantDetails?.firstName?.trim());
    this.QuickQuoteApplicantForm.get('lastName').setValue(applicantDetails?.lastName?.trim());
    this.QuickQuoteApplicantForm.get('dateOfBirth').setValue({ isRange: false, singleDate: { jsDate: new Date(applicantDetails?.dob) }});
    this.QuickQuoteApplicantForm.get('addressLine1').setValue(propertyDetails?.propertyAddress);
    this.QuickQuoteApplicantForm.get('addressLine2').setValue(propertyDetails?.propertyAddress2);
    this.QuickQuoteApplicantForm.get('qqCity').setValue(propertyDetails?.propertyCity);
    this.QuickQuoteApplicantForm.get('qqState').setValue(propertyDetails?.propertyState);
    this.QuickQuoteApplicantForm.get('qqZipCode').setValue(propertyDetails?.propertyZip);
    this.QuickQuoteApplicantForm.get('nameSuffix').setValue(
      LvNameSuffix.find(x => x.code.toLowerCase().replace('.', '') === applicantDetails?.suffix?.toLowerCase().replace('.', ''))?.code ?? '');

    const comparativeEffectiveDate = new Date(requestData?.policy?.effectiveDate);
    const currentDate = new Date();
    const effectiveDate = comparativeEffectiveDate >= currentDate ? comparativeEffectiveDate : currentDate;
    this.QuickQuoteApplicantForm.get('effectiveDate').setValue({
      singleDate: {
        date: {
          year: effectiveDate.getFullYear(),
          month: effectiveDate.getMonth() + 1,
          day: effectiveDate.getUTCDate()
        },
        jsDate: effectiveDate
      }
    });

    this.QuickQuoteApplicantForm.get('firstName').markAsTouched();
    this.QuickQuoteApplicantForm.get('lastName').markAsTouched();
    this.QuickQuoteApplicantForm.get('effectiveDate').markAsTouched();

    this.calculateAge(new Date(applicantDetails?.dob), true);
    if (this.quickQuoteData.isRetired) {
      this.QuickQuoteApplicantForm.get('isRetiredYesNo').setValue(mappedData?.isSeniorRetiree);
      this.coveragesData.discountsForm.get('seniorRetiree').setValue(this.hasRetireeDiscount(mappedData?.isSeniorRetiree));
    }

    this.zipCodeData.stateCode = propertyDetails?.propertyState;
    this.setZipCode(true);

    //Get city list to validate Applicant/CoApplicant Insured Mailing Address if country is USA
    this.comparativeRaterData.isCountryUSA = Boolean(applicantDetails?.country?.toUpperCase() === 'USA' || applicantDetails?.country?.toUpperCase() === 'US');
    if (Boolean(applicantDetails?.zip) && this.comparativeRaterData.isCountryUSA) {
      const formControlNames = ['state', 'mainApplicantCounty', 'city', 'zipCode'];
      this.zipCodeData.getGenericZipCode(applicantDetails?.zip, this.QuickQuoteApplicantForm, formControlNames, PageSections.ApplicantInformation);
    }

    this.comparativeRaterData.isCoAppCountryUSA = Boolean(coApplicantDetails?.country?.toUpperCase() === 'USA' || coApplicantDetails?.country?.toUpperCase() === 'US');
    if (Boolean(coApplicantDetails?.zip) && this.comparativeRaterData.isCoAppCountryUSA) {
      const formControlNames = ['coApplicantSameAsApplicantState', 'coApplicantCounty', 'coApplicantSameAsApplicantCity', 'coApplicantSameAsApplicantZipCode'];
      this.zipCodeData.getGenericZipCode(coApplicantDetails?.zip, this.applicantData.coApplicant, formControlNames, PageSections.CoApplicant);
    }
  }


  protected processPrefillErrors(response: AllResponseDTO): void {
      this.displayErrorDialog(response);
  }

  onChangeFirstName(event: any): void {
    if (this.applicantRetrievalData?.firstName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeMiddleName(event: any): void {
    if (this.applicantRetrievalData?.middleName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeLastName(event: any): void {
    if (this.applicantRetrievalData?.lastName !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  onChangeNameSuffix(event: any): void {
    if (this.applicantRetrievalData?.nameSuffix !== event) {
      this.applicantData.nameChanged = true;
    }
  }

  get applicantRetrievalData(): EntityRiskDTO {
    return this.applicantData.applicantRetrievalData;
  }

  onPasteEffectiveDate(event: ClipboardEvent): void {
    const pastedValue = event.clipboardData.getData('text');
    if (pastedValue.length > 7) {
      const finalValue = pastedValue.substring(0,2)+'/'+pastedValue.substring(2,4)+'/'+pastedValue.substring(4,8);
      this.onInputFieldChanged(null, finalValue);
    } else {
      this.iseffectiveDatePastDate = false;
    }

    this.coveragesBusinessRules.setupCoverageACValidatorByFormType();
  }

  getCompleteSubmissionDetailsMenuItems(hasUwrChanges: boolean = false): INavDetails {
    return {
      riskId: this.entityRiskData?.getRiskId(),
      riskDetailId: this.entityRiskData?.getRiskDetailId(),
      validation: this.submissionNavValidationService?.validationList,
      quoteStatus: this.submissionData?.quoteStatus?.value,
      hasAir: this.submissionData?.hasAir,
      hasDecline: this.submissionData?.hasDecline,
      uwrListEmpty: this.uwApprovalData?.uwrListEmpty,
      allUwrApproved: this.uwApprovalData?.isAllUWApprovalApproved,
      hasUwrChanges: hasUwrChanges,
      hasPostBindUwrItem: this.uwApprovalData?.hasPostBindUwrItem,
      uwrListAllPostBind: this.uwApprovalData?.uwrListAllPostBind,
      postBindUwrItemsApproved: this.uwApprovalData?.postBindUwrItemsApproved,
      nonPostBindUwrItemsChecked: this.uwApprovalData?.nonPostBindUwrItemsChecked,
      nonPostBindUwrItemsApproved: this.uwApprovalData?.nonPostBindUwrItemsApproved,
      uwApprovalListAddedAllPostBind: this.uwApprovalData?.uwApprovalListAddedAllPostBind,
      fcraAcknowledgement: this.applicantValidationService?.fcraAcknowledgementSection,
      hasRenewalOfferChanges: this.submissionData?.hasRenewalOfferChanges?.value,
      hasCommissionRate: this.entityRiskData?.getAgencyHasCommissionRate()
    };
  }

  get isRenewalQuote(): boolean {
    const isNewQuote = this.entityRiskData.getRiskId() ? false : true;
    const isRenewal = !isNewQuote && this.entityRiskData?.getIsRenewalQuote();
    return isRenewal;
  }

  getTerritories(thirdPartyRequest: ApplicationInformationDTO): void {
    const zipCode: string = this.thirdPartyData.dataFromAPIResponse?.iso360Value.zipCode ? this.thirdPartyData.dataFromAPIResponse?.iso360Value.zipCode :
      thirdPartyRequest.zipCode;

    this.raterPremiumService.getTerritories(zipCode).pipe(takeUntil(this.stop$)).subscribe(result => {
      this.PropertyLocationDetailsForm.get('territoryCode').setValue(result ?? '');

      this.quickQuoteData.getCommissionRate();

      if (this.endorsementData.isWindStormExteriorVisibleIncluded(result?.toString()?.toUpperCase()?.trim())) {
        this.endorsementData.isWindStormExteriorVisible.next(true);
      } else {
        this.endorsementData.isWindStormExteriorVisible.next(false);
      }

      this.endorsementData.checkHurricaneDeductibleid();
      this.propertyBusinessRules.triggerActiveNew125(false, false, false, true);
      Utils.unblockUI();
    });
  }
}
