import { Observable, Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PropertyData } from '../../../../modules/submission-management/data/property.data';
import { CoveragesData } from '../../../../modules/submission-management/data/coverages.data';
import { PersonalPropertyIndicator } from '../../../../shared/models/rater-premium/personal-property-indicator.model';
import { WindMitigation } from '../../../../shared/models/rater-premium/wind-mitigation.model';
import { RaterPremiumDetails } from '../../../../shared/models/rater-premium/rater-premium-details';
import { EndorsementsData } from '../../../../modules/submission-management/data/endorsements.data';
import { ProcessRaterCommand } from '../../../../shared/models/rater-premium/process-rater-command.model';
import { RaterPremiumService } from './rater-premium.service';
import { LocalStorageService } from '../../local-storage.service';
import { IProcessedRaterView } from '../../../../shared/models/rater-premium/processed-rater-view.model';
import { ClaimsData } from '../../../../modules/submission-management/data/claims.data';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../shared/base-class';
import { ErrorMessageConstant } from '../../../../shared/constants/error-message.constants';
import { ToastrService } from 'ngx-toastr';
import { EntityRiskDTO } from '../../../../shared/models/data/dto/quick-quote/entity-risk.dto';
import { PolicyBillingData } from '../../../../modules/policy-management/data/policy-billing.data';
import { CustomFees } from '../../../../shared/models/rater-premium/custom-fees.model';
import { TransactionFeeDTO } from '../../../../shared/models/data/dto/billing/transaction-fee.dto';
import { ProcessDP3Command } from '../../../../shared/models/rater-premium/process-dp3-command.model';
import { LvNumberOfUnitsBetweenFireWalls, LvNumberOfUnitsInsured, LvStructureType } from 'app/shared/constants/property.options.constants';
import { DPRater } from 'app/shared/constants/dp3-rater.constant';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';
import { select, Store } from '@ngrx/store';
import {
  updateRaterCalculateDP3IsLoadingFromDP3RaterRequestData,
  updateRaterCalculateDP3ListFromDP3RaterRequestData
} from 'app/store/rater-calculate-dp3/rater-calculate-dp3.actions';
import { FormTypeConstants } from 'app/shared/constants/form-types.constants';
import { RoofSurfacingPaymentScheduleBRULDatePipe } from 'app/shared/pipes/rsps-brul-date.pipe';
import { selectAppBrulDates } from 'app/store/app/app.selectors';
import { updateAppBrulDatesFromEndorsementComponent } from 'app/store/app/app.actions';
import Utils from 'app/shared/utilities/utils';
import { RaterExemptedLinkConstants } from '../../../../shared/constants/rater.constant';

@Injectable({
  providedIn: 'root',
})
export class DP3RaterRequestData extends BaseClass {
  personalPropIndicator: PersonalPropertyIndicator;
  raterRequest: ProcessDP3Command = {};
  raterPremiumDetails: Subject<RaterPremiumDetails> = new Subject<RaterPremiumDetails>();
  creditRiskDetails: Subject<any> = new Subject<any>();
  isCalcPremium: Subject<boolean> = new Subject<boolean>();
  isRaterCalculated: Subject<boolean> = new Subject<boolean>();
  proratedRaterResults: Subject<any> = new Subject<any>();
  fireProtection: Subject<number> = new Subject<number>();
  effectiveDate: any;
  endorsementEffectiveDate: Subject<Date> = new Subject<Date>();
  recalculatePremiumAndSave$: Subject<boolean> = new Subject<boolean>();
  recalculatePremiumSubmissionRewrite$: Subject<boolean> = new Subject<boolean>();

  isViewQuoteProposalClicked: boolean = false;
  numberOfUnitsFirewallsList: any[] = LvNumberOfUnitsBetweenFireWalls;
  field = { hurricane: 'Hurricane Deductible' };

  calculateDP3RaterSubscription: Subscription;

  isEndorsementRequest: boolean;
  endorsementRequest$: Subject<boolean> = new Subject<boolean>();

  protected selectAppBrulDates$: Observable<any>;
  protected selectAppBrulDates: any | null;

  constructor(
    protected storage: LocalStorageService,
    protected raterPremiumService: RaterPremiumService,
    protected claimsData: ClaimsData,
    protected toastr: ToastrService,
    protected authService: AuthService,
    public router: Router,
    protected store: Store,
    protected roofSurfacingPaymentScheduleBRULDate: RoofSurfacingPaymentScheduleBRULDatePipe
  ) {
    super();
    this.retrieveRaterRequest();
    this.selectAppBrulDates$ = this.store.pipe(select(selectAppBrulDates));
    this.subscribeToSelectAppBrulDates$();
  }

  protected retrieveRaterRequest() {
    const raterRequestStorage = this.storage.retrieve(DPRater.raterRequest);
    if (raterRequestStorage) {
      this.raterRequest = raterRequestStorage;
    }
  }

  quickQuoteRaterRequest(
    qqApplicant: FormGroup,
    propLocation: FormGroup,
    propDetails: FormGroup,
    summary: FormGroup,
    coveragesData: CoveragesData,
    isNew?: boolean
  ) {
    const localDate = qqApplicant.get('effectiveDate')?.value?.singleDate?.jsDate;
    this.effectiveDate = moment(localDate).format('YYYY-MM-DDT00:00:00.000');
    //commented => original code = new Date(localDate + 'UTC');
    this.populateQuickQuotePayload(
      this.effectiveDate, coveragesData, summary, qqApplicant, propLocation, propDetails, coveragesData?.entityRiskData?.EntityRisk
    );
    this.updateRaterRequestStorage();
    if (!isNew) {
      this.calculateRaterPremium();
    } else {
      this.isCalcPremium.next(false);
    }
  }

  billingTransactionFeesRequest(policyBillingData: PolicyBillingData) {
    this.populateBillingPayload(policyBillingData.transactionFees);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  populateBillingPayload(transactionFees: TransactionFeeDTO[]) {
    const cusFees: CustomFees[] = [];
    if (transactionFees) {
      transactionFees.forEach(e => {
        const customFees = {
          id: e.id,
          description: e.description,
          amount: e.amount,
          dateAdded: e.addDate,
          dateVoided: e.voidDate
        };
        cusFees.push(customFees);
      });
    }
    this.raterRequest.customFees = cusFees;
  }

  coverageRaterRequest(coverageData: CoveragesData, policyNumber: string) {
    this.populateCoveragesPayload(coverageData, policyNumber);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  applicantRaterRequest(coveragesData: CoveragesData) {
    this.raterRequest.isSeniorRetiree = Boolean(coveragesData.discountsForm.get('seniorRetiree').value);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  endorsementRaterRequest(endorsementData: EndorsementsData, policyNumber: string) {
    this.populateEndorsementPayload(endorsementData, policyNumber);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  claimsRequest(claims: ClaimsData, policyNumber: string) {
    this.populateClaimsPayload(this.getClaimsCount(claims.claimList));
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  propertyRaterRequest(propertyData: PropertyData, coverageData: CoveragesData, policyNumber: string, propertyBusinessRules: PropertyBusinessRules) {
    const covAndDeductibles = coverageData.coveragesAndDeductiblesForm;
    this.raterRequest.coverageA = +covAndDeductibles.get('coverageA').value ?? 0;
    this.raterRequest.isSeniorRetiree = Boolean(coverageData.discountsForm.get('seniorRetiree').value);

    this.populatePropertyRequestPayload(propertyData, policyNumber, propertyBusinessRules);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  bindAndIssueRaterRequest(bindAndIssue: any, response: any) {
    const reqKeyRiskDetailId = bindAndIssue?.riskDetailId;

    const raterRequestKey = `${DPRater.raterRequest}_${reqKeyRiskDetailId}`;
    const raterRequestParams = this.storage.retrieve(raterRequestKey);
    const processRaterCommand: ProcessRaterCommand = raterRequestParams;
    processRaterCommand.riskId = bindAndIssue?.riskId;
    processRaterCommand.riskDetailId = bindAndIssue?.riskDetailId;

    processRaterCommand.endorsementRequest = this.getEndorsementRequest(response?.policyNumber, true);
    this.raterRequest = processRaterCommand;

    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  issueEndorsementPolicy(response: any) {
    const raterRequestKey = `${DPRater.raterRequest}_${response?.riskDetailId}`;
    const raterRequestParams = this.storage.retrieve(raterRequestKey);
    const processRaterCommand: ProcessRaterCommand = raterRequestParams;
    processRaterCommand.riskId = response?.riskId;
    processRaterCommand.riskDetailId = response?.riskDetailId;
    processRaterCommand.endorsementRequest = this.getEndorsementRequest(response?.policyNumber, true);
    this.raterRequest = processRaterCommand;
    this.updateRaterRequestStorage();
    this.isEndorsementRequest = true;
    this.calculateRaterPremium();
  }

  populatedEndorsementIssuePayload(effectiveDate) {
    this.effectiveDate = moment(effectiveDate).format('YYYY-MM-DDT00:00:00.000'); // CNTSUP-519
    //commented for now -> original code new Date(effectiveDate + 'UTC');
    this.raterRequest.effectiveDate = this.effectiveDate;
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  populateRewritePayload(effectiveDate?, isProceedSaving?) {
    this.effectiveDate = moment(effectiveDate).format('YYYY-MM-DDT00:00:00.000');
    //commented for now -> original code-> new Date(effectiveDate + 'UTC');
    this.raterRequest.isProceedSaving = isProceedSaving;
    this.raterRequest.isCalculateRewrite = true;
    this.updateRaterRequestStorage();
    this.calculateRaterPremium(true);
  }

  protected populateEndorsementPolicyDetails(summary: FormGroup) {
    const policyNumber = summary.get('policyNumber').value ?? '';
    this.raterRequest.endorsementRequest = this.getEndorsementRequest(policyNumber, false);
  }

  populateRaterRequestPayload(
    data: EntityRiskDTO,
    qqApplicant: FormGroup,
    propLocation: FormGroup,
    propDetails: FormGroup,
    summary: FormGroup,
    coveragesData: CoveragesData,
    endorsementData: EndorsementsData,
    propertyData: PropertyData,
    propertyBusinessRules: PropertyBusinessRules,
    transactionFees?: TransactionFeeDTO[],
    isEndorsement: boolean = false
  ) {
    this.raterRequest.isProceedSaving = true;
    this.raterRequest.isCalculateRewrite = false;
    this.effectiveDate = isEndorsement ? data?.risks[0]?.riskDetails[0]?.effectiveDate : data.risks[0]?.firstIssueDate;
    const endorsementDate = isEndorsement ? data?.risks[0]?.riskDetails[0]?.effectiveDate : data.risks[0]?.firstIssueDate;
    this.endorsementEffectiveDate.next(endorsementDate);

    const policyNumber = data?.risks[0]?.policyNumber ?? '';
    const renewalCode = data?.risks[0]?.renewalCode;
    this.raterRequest.policyStatus = renewalCode !== null ? 'RN' : 'NB';
    this.raterRequest.isSiebel = data?.risks[0]?.isSiebel ?? false;

    this.raterRequest.isLockRaterVersion = data?.risks[0]?.isLockRaterVersion ?? false;
    this.raterRequest.raterVersionId = data?.risks[0]?.isLockRaterVersion ? Number(data?.risks[0]?.raterVersionId) : 1;

    this.populateEndorsementPolicyDetails(summary);
    this.populateQuickQuotePayload(
      this.effectiveDate,
      coveragesData,
      summary,
      qqApplicant,
      propLocation,
      propDetails,
      data,
    );
    this.populateCoveragesPayload(coveragesData, policyNumber);
    this.populateEndorsementPayload(endorsementData, policyNumber);
    this.populatePropertyRequestPayload(propertyData, policyNumber, propertyBusinessRules);
    this.populateBillingPayload(transactionFees);
    this.updateRaterRequestStorage();
    this.calculateRaterPremium();
  }

  populateQuickQuotePayload(
    effectiveDate: Date,
    coveragesData: CoveragesData,
    summary: FormGroup,
    qqApplicant: FormGroup,
    propLocation: FormGroup,
    propDetails: FormGroup,
    data?: EntityRiskDTO
  ) {
    const covAndDeductibles = coveragesData.coveragesAndDeductiblesForm;

    //  applicant information

    this.raterRequest.form = summary.get('formType').value;
    this.raterRequest.riskId = summary.get('riskId').value;
    this.raterRequest.riskDetailId = summary.get('riskDetailId').value;
    this.raterRequest.lastRiskDetailId = data?.risks[0]?.riskDetails[0]?.lastRiskDetailId
      ?? summary.get('riskDetailId').value;
    this.raterRequest.riskNumber = summary.get('quoteNumber').value;
    this.raterRequest.zipCode = +qqApplicant.get('qqZipCode').value;
    this.raterRequest.effectiveDate = effectiveDate;

    // property
    this.raterRequest.protectionClass = propLocation.get('protectionClass').value !== '' ? propLocation.get('protectionClass').value : '0';
    this.raterRequest.constructionType = propDetails.get('constructionType').value;
    this.raterRequest.yearOfConstruction = +propDetails.get('yearBuilt').value;
    this.raterRequest.roofYear = +propDetails.get('yearRoof').value;
    this.raterRequest.exteriorWallFinish = propDetails.get('exteriorWallFinish').value;
    this.raterRequest.isFlatTile = Boolean(propDetails.get('isFlatTile').value);

    // coverages
    this.raterRequest.coverageA = +covAndDeductibles.get('coverageA').value ?? 0;
    this.raterRequest.coverageC = Math.round(Number(covAndDeductibles.get('coverageC').value));
    this.raterRequest.hurDeductible = covAndDeductibles.get('hurricaneDeductibleid').value ?? '';
    this.raterRequest.nhrDeductible = +covAndDeductibles.get('allOtherPerilsId').value;

    this.raterRequest.claimCount = this.claimsData.claimList?.length !== 0 ?
      this.claimsData.claimList.filter(x => x.claimChargeableVsNonChargeable).length :
      data?.risks[0]?.riskDetails[0]?.riskClaims.filter(x => x.chargeableNonChargable).length;

    if (coveragesData.creditScore !== null && coveragesData.creditScore !== undefined) {
      this.raterRequest.creditScore = +coveragesData.creditScore;
    } else {
      coveragesData.setCreditScore();
      this.raterRequest.creditScore = +coveragesData.creditScore;
    }

    // policy
    const policyNumber = data?.risks[0]?.policyNumber ?? '';
    this.raterRequest.endorsementRequest = this.getEndorsementRequest(policyNumber, false);

    // rater version id
    this.raterRequest.isLockRaterVersion = data?.risks[0]?.isLockRaterVersion ?? false;
    this.raterRequest.raterVersionId = data?.risks[0]?.isLockRaterVersion ? Number(data?.risks[0]?.raterVersionId) : 1;
  }

  populateCoveragesPayload(coverageData: CoveragesData, policyNumber: string) {
    const covAndDeductibles = coverageData.coveragesAndDeductiblesForm;
    const covDiscounts = coverageData.discountsForm;
    const covFlood = coverageData.floodForm;
    const schedule = coverageData.schedulesForm;
    const entityRisk = coverageData?.entityRiskData?.EntityRisk;

    // coverages
    this.raterRequest.coverageA = +covAndDeductibles.get('coverageA').value;
    this.raterRequest.coverageC = Math.round(Number(covAndDeductibles.get('coverageC').value));
    this.raterRequest.covLLimit = +covAndDeductibles.get('isCoverageLEnabled').value ? +covAndDeductibles.get('coverageL').value : 0;
    this.raterRequest.covMLimit = +covAndDeductibles.get('isCoverageMEnabled').value ? +covAndDeductibles.get('coverageM').value : 0;
    this.raterRequest.hurDeductible = covAndDeductibles.get('hurricaneDeductibleid').value ?? '';
    this.raterRequest.nhrDeductible = +covAndDeductibles.get('allOtherPerilsId').value;
    this.raterRequest.coverageBPercent = +covAndDeductibles.get('coverageBLimitId').value;
    if (coverageData.creditScore !== null && coverageData.creditScore !== undefined) {
      this.raterRequest.creditScore = +coverageData.creditScore;
    } else {
      coverageData.setCreditScore();
      this.raterRequest.creditScore = +coverageData.creditScore;
    }

    //  discount
    this.raterRequest.securedCommunity = String(covDiscounts.get('securedCommunityBuildingId').value);
    this.raterRequest.isSeniorRetiree = Boolean(covDiscounts.get('seniorRetiree').value);
    this.raterRequest.fireProtection = +covDiscounts.get('fireId').value;
    this.fireProtection.next(this.raterRequest.fireProtection);
    this.raterRequest.sprinkler = String(covDiscounts.get('sprinklerId').value);
    this.raterRequest.burglarProtection = +covDiscounts.get('burglarId').value;
    this.raterRequest.isActiveCentauriHOPolicy = (covDiscounts.get('companionHOPolicy').value || coverageData?.isActiveCentauriHOPolicyFromComparativeRater) ? (covDiscounts.get('isCompanionHOPolicy').value === true && (covDiscounts.get('companion').value.toString() === 'true')) ? true :
      covDiscounts.get('isCompanionHOPolicy').value ?? false : false;
    this.raterRequest.isActiveNFIPHOPolicy = (covDiscounts.get('isCompanionHOPolicy').value === true && covDiscounts.get('companion').value.toString() === 'true') ? false :
      covDiscounts.get('companion').value.toString() === 'true' ? true
      : covDiscounts.get('companion').value.toString() === 'false' ? false
      : covDiscounts.get('companion').value;

    // policy
    this.raterRequest.endorsementRequest = this.getEndorsementRequest(policyNumber, false);

     // rater version id
     this.raterRequest.isLockRaterVersion = entityRisk?.risks[0]?.isLockRaterVersion ?? false;
     this.raterRequest.raterVersionId = entityRisk?.risks[0]?.isLockRaterVersion ? Number(entityRisk?.risks[0]?.raterVersionId) : 1;
  }

  protected populateEndorsementPayload(endorsementData: EndorsementsData, policyNumber: string) {
    const endorsement = endorsementData.endorsementsGroup;
    this.raterRequest.isWaterDamageExclusion = endorsement.get('waterDamage').value;
    this.raterRequest.isLimitedWaterDamage = endorsement.get('limitedWater').value;

    const isLimitedTheftCoverageAvailable = endorsementData.showLimitedTheftCoverageToggle();
    this.raterRequest.isLimitedTheftCoverage = isLimitedTheftCoverageAvailable ? Boolean(endorsement.get('limitedTheftCoverage').value) : false;

    this.raterRequest.isOrdinanceLaw = endorsement.get('ordinanceLawOrCov').value;
    this.raterRequest.ordinanceLaw = endorsement.get('ordinanceLaw').value;
    this.raterRequest.isLimitedFungi = endorsement.get('fungiWetOrDryRot').value;
    this.raterRequest.limitedFungiLimit = String(endorsement.get('fungiWetOrDryRotLimit').value);
    this.raterRequest.isLossAssessment = endorsement.get('lossAssessment').value;
    this.raterRequest.lossAssessmentLimit = String(endorsement.get('lossAssessmentLimit').value);
    this.raterRequest.isWaterBackup = endorsement.get('waterBackup').value;
    this.raterRequest.isVacancyEndorsement = endorsement.get('vacancyEndorsement').value; // where to map
    this.raterRequest.isPersPropReplacementCost = endorsement.get('personalProperty').value;
    this.raterRequest.screenedEncCarportCoverage = endorsement.get('screenedEnclosure').value;
    this.raterRequest.screenedEncLimit = this.getScreenEnclosureLimit(endorsement);
    this.raterRequest.isSinkholeLoss = endorsement.get('sinkholeLoss').value;
    this.raterRequest.isEquipBreakdown = endorsement.get('equipmentBreakdown').value;
    this.raterRequest.isServiceLine = endorsement.get('serviceLine').value;

    const isPermittedIncOcc = endorsement.get('permittedIncidentalOccupancies').value;
    const isPermittedIncOccLiab = endorsement.get('permittedIncidentalOccupanciesLiability').value;
    this.raterRequest.isPermittedIncOcc = (isPermittedIncOcc || isPermittedIncOccLiab);

    const isPremiumAdjustment = endorsement.get('premiumAdjustment').value;
    const isPremiumAdjustmentAmount = endorsement.get('premiumAdjustmentAmount').value;
    this.raterRequest.premiumAdj = isPremiumAdjustment && isPremiumAdjustmentAmount ?
      +endorsementData.currencyChecking.checkAmountForSaving(endorsement.get('premiumAdjustmentAmount').value) : 0;

    const isConsentToRate = endorsement.get('consentToRate').value;
    this.raterRequest.consentToRate = isConsentToRate ? +endorsement.get('consentToRateAmount').value : 0;

    const rspsFormTypes = [FormTypeConstants.HO3, FormTypeConstants.DP3];
    const isFromRenewal = endorsementData.entityRiskData.getRisk()?.isFromRenewal;
    const rspsDate = isFromRenewal ? this.selectAppBrulDates?.brulDate?.rspsDate_RB : this.selectAppBrulDates?.brulDate?.rspsDate_NB;
    const rspsLogic = this.roofSurfacingPaymentScheduleBRULDate.transform(endorsementData.quoteService.formType, rspsDate, endorsementData.applicantData.mainApplicant.get('effectiveDate')?.value, null, null, true);
    this.raterRequest.isRoofSurfacingPaymentSchedule = rspsLogic ? endorsement.get('roofSurfacingPaymentSchedule').value : false;

    // policy
    this.raterRequest.endorsementRequest = this.getEndorsementRequest(policyNumber, false);
  }

  protected populatePropertyRequestPayload(propertyData: PropertyData, policyNumber: string, propertyBusinessRules: PropertyBusinessRules) {
    const propLocation = propertyData.locationDetails;
    const propertyDetails = propertyData.propertyDetails;
    const windMitigation = propertyData.windMitigation;

    // location details
    this.raterRequest.bceg = +propLocation.get('bceg').value;
    this.raterRequest.monthsOccupied = +propLocation.get('numMonthsOccupied')?.value;
    this.raterRequest.structureType = propLocation.get('structureType').value;
    this.raterRequest.propertyUsage = propLocation.get('propertyUsage')?.value;
    const numberOfUnitsFirewalls = this.numberOfUnitsFirewallsList
      .find(n => n.code === propLocation.get('numberOfUnitsFirewalls').value);
    this.raterRequest.numUnitsBetFW = numberOfUnitsFirewalls?.raterCode ?? 0;
    this.raterRequest.protectionClass = propLocation.get('protectionClass').value !== '' ? propLocation.get('protectionClass').value : '0';
    this.raterRequest.isResidenceRented = propLocation.get('isResidenceRented').value;

    //  wind mitigation
    this.raterRequest.windMitigationForm = windMitigation.get('mitigationForm').value;
    this.raterRequest.windMitigation = this.getWindMitigation(windMitigation, propertyDetails);

    // property details
    this.raterRequest.exteriorWallFinish = propertyDetails.get('exteriorWallFinish').value;
    this.raterRequest.isFlatTile = Boolean(propertyDetails.get('isFlatTile').value);
    this.raterRequest.foundationType = propertyDetails.get('foundationType').value;
    this.raterRequest.constructionType = propertyDetails.get('constructionType').value;
    this.raterRequest.yearOfConstruction = +propertyDetails.get('yearBuilt').value;
    this.raterRequest.roofYear = +propertyDetails.get('yearRoof').value;
    const unitsInsured = LvNumberOfUnitsInsured.find(s => s.code === propLocation.get('numberOfUnitsInsured')?.value);
    this.raterRequest.numOfFamilies = unitsInsured?.raterCode ?? 0;
    this.raterRequest.roofMaterial = propertyData.propertyDetails.get('roofMaterial').value;

    // policy
    this.raterRequest.endorsementRequest = this.getEndorsementRequest(policyNumber, false);
  }

  getScreenEnclosureLimit(endorsement: FormGroup) {
    const isSEReplacementCost = endorsement.get('screenedEnclosure').value === 'RC';
    const isSEActualCashValue = endorsement.get('screenedEnclosure').value === 'ACV';

    let screenEnclosureLimit = 0;
    if (isSEReplacementCost || isSEActualCashValue) {
      screenEnclosureLimit = endorsement.get('screenedEnclosureLimit').value ?? 0;
    }
    return Number(screenEnclosureLimit);
  }

  getWindMitigation(windMitigation, propertyDetails): WindMitigation {
    const windMitigationData = {
      roofCover: windMitigation.get('predominantRoofCovering').value,
      roofDeck: windMitigation.get('roofDeckAttachment').value,
      roofWallConnection: windMitigation.get('roofToWallAttachment').value,
      openingProtection: windMitigation.get('openingProtection').value,
      terrain: windMitigation.get('terrain').value,
      roofShape: propertyDetails.get('roofShape').value,
      secondaryWaterResistance: Boolean(windMitigation.get('secondaryWaterResistance').value) ? 'Y' : 'N',
      windSpeed: +windMitigation.get('windDesignSpeed').value,
      windBornDebris: Boolean(windMitigation.get('windBorneDebrisRegion').value),
    };
    return windMitigationData;
  }

  getEndorsementRequest(policyNumber: string, isIssuePolicy: boolean) {
    const endorsementRequest = {
      policyNumber: this.checkIsPolicy() || isIssuePolicy ? policyNumber : '',
      isIssuePolicy: isIssuePolicy
    };
    return endorsementRequest;
  }

  updateRaterRequestStorage() {
    const raterReqKey = `${DPRater.raterRequest}_${this.raterRequest.riskDetailId}`;
    this.storage.store(raterReqKey, this.raterRequest);
  }

  calculateRaterPremium(isRewriteRequest: boolean = false) {
    if (!this.raterRequest.riskId) {
      return;
    }
    this.processRaterPremium(this.raterRequest.riskDetailId, isRewriteRequest);
  }

  processRaterPremium(riskDetailId: string, isRewriteRequest: boolean = false) {
    if (this.calculateDP3RaterSubscription) {
      this.calculateDP3RaterSubscription.unsubscribe();
    }

    const raterReqKey = `${DPRater.raterRequest}_${riskDetailId}`;
    const raterRequestParams = this.storage.retrieve(raterReqKey);
    const effectiveDate = Boolean(sessionStorage.getItem('selectedEffectiveDate')) ?
      moment(sessionStorage.getItem('selectedEffectiveDate')).format('YYYY-MM-DDT00:00:00.000') :
      this.effectiveDate ?? this.authService.getCustomDate();

    // raterRequestParams.effectiveDate = this.checkIsPolicy ? this.effectiveDate : effectiveDate;
    raterRequestParams.effectiveDate = this.checkIsPolicy() ?this.effectiveDate : moment(effectiveDate).format('YYYY-MM-DDT00:00:00.000');


    this.isRaterCalculated.next(false);
    this.store.dispatch(updateRaterCalculateDP3IsLoadingFromDP3RaterRequestData({ isLoading: true }));

    this.calculateDP3RaterSubscription = this.raterPremiumService.calculateDP3RaterPremium(raterRequestParams)
      .pipe(takeUntil(this.stop$))
      .subscribe(response => {
        this.store.dispatch(updateRaterCalculateDP3IsLoadingFromDP3RaterRequestData({ isLoading: false }));
        this.store.dispatch(updateRaterCalculateDP3ListFromDP3RaterRequestData({ list: response }));

        if (response !== null) {
          this.getRaterPremiumDetails(response);
          this.getProratedPremiumDetails(response, raterRequestParams);
          const raterResultKey = `${DPRater.raterResult}_${riskDetailId}`;
          this.storage.store(raterResultKey, response);

          this.getRaterPremiumDetails(response);
          this.getProratedPremiumDetails(response, raterRequestParams);

          this.isCalcPremium.next(false);
          this.isRaterCalculated.next(true);
          this.recalculatePremiumAndSave$.next(true);

          if (isRewriteRequest) {
            this.recalculatePremiumSubmissionRewrite$.next(true);
          }

          if (this.isEndorsementRequest) {
            this.endorsementRequest$.next(true);
            this.isEndorsementRequest = false;
          }
        }
      }, (err) => {
        this.isEndorsementRequest = false;
        console.log(err.error); //needed to verify the error from rater but will remove later
        this.toastr.error(ErrorMessageConstant.raterCannotBeReachedErrorMessage);
        this.isCalcPremium.next(false);
        this.isRaterCalculated.next(false);
      });
  }

  getProratedPremiumDetails(response: IProcessedRaterView[], raterRequestParams: any) {
    const policyNumber = raterRequestParams?.endorsementRequest?.policyNumber ?? '';
    if (policyNumber !== '') {
      const proratedPremiumDetails = response?.filter(s => {
        return s.isMonetary && s.isCalcPremium && s.isMatrixDisplay && s.stepAlias !== DPRater.baseRate &&
          (s.totalProratedPremium !== 0 || s.proratedPremiumDiff !== 0);
      });
      const proratedResult = {
        proratedPremiumDetails: proratedPremiumDetails
      };
      this.proratedRaterResults.next(proratedResult);
    }
  }


  public getRaterPremiumDetails(response: IProcessedRaterView[]) {
    const premium = response
      .filter(r => r.stepAlias === DPRater.totalPremium ||
        r.stepAlias === DPRater.mgaf ||
        r.stepAlias === DPRater.empat ||
        r.groupName === DPRater.figaAssessment);
    const raterPremiumDetails: RaterPremiumDetails = {
      estimatedPremium: this.getTotalPremiumFees(premium),
      fees: this.getOtherFees(premium),
      taxes: this.getAssessments(premium),
    };
    this.raterRequest.endorsementRequest.isIssuePolicy = false;
    this.raterPremiumDetails.next(raterPremiumDetails);
  }

  getOtherFees(premium: IProcessedRaterView[]): number {
    return +premium.find(s => s.stepAlias === DPRater.mgaf).perilValue +
      +premium.find(s => s.stepAlias === DPRater.empat).perilValue;
  }

  getAssessments(premium: IProcessedRaterView[]): number {
    let figaAssessments: number = 0;
    premium.forEach(a => {
      if (a.groupName === DPRater.figaAssessment) {
        figaAssessments += Number(a?.perilValue) ?? 0;
      }
    });

    return figaAssessments;
  }

  getTotalPremiumFees(premium: IProcessedRaterView[]): number {
    return +premium.find(s => s.stepAlias === DPRater.totalPremium).perilValue;
  }

  payloadCodeConverter(field: string, value: string) {
    switch (field) {
      case this.field.hurricane:
        return value === 'E' ? 0 : +value;
      default:
        break;
    }
  }

  getLeaseTerm(value: string) {
    switch (value) {
      case 'Short Term':
        return 'ST';
      case 'Long Term':
        return 'LT';
    }
  }

  getClaimsCount(currentClaimsData: any): number {
    if (!!!currentClaimsData) { return 0; }
    return currentClaimsData.filter(x => x.claimChargeableVsNonChargeable).length;
  }

  protected populateClaimsPayload(claimsCount: number) {
    this.raterRequest.claimCount = claimsCount;
  }

  checkIsPolicy(): boolean {
    return this?.router?.url?.includes('policies');
  }

  subscribeToSelectAppBrulDates$(): void {
    this.selectAppBrulDates$.pipe().subscribe(brulDates => {
      if (this.isExemptedLinkForServerDate()) { return; }

      if (!brulDates) {
        this.authService.getServerDate().subscribe(result => {
          this.selectAppBrulDates = result;
          this.store.dispatch(updateAppBrulDatesFromEndorsementComponent({ brulDates: result }));
        });
      } else {
        this.selectAppBrulDates = brulDates;
      }
    });
  }

  getCovA(covA: number): number {
    if (this.checkIsPolicy()) {
      return Number(covA);
    }

    return Utils.round(Number(covA));
  }

  getCovC(covC: number): number {
    if (this.checkIsPolicy()) {
      return Number(covC);
    }

    return Utils.round(Number(covC));
  }

  isExemptedLinkForServerDate(): boolean {
    return this.router.url.includes(RaterExemptedLinkConstants.redirectmakeapayment) ||
      this.router.url.includes(RaterExemptedLinkConstants.makeapayment) ||
      this.router.url.includes(RaterExemptedLinkConstants.compRater);
  }
}


