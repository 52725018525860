import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { ErrorMessageConstant } from '../../../../../shared/constants/error-message.constants';
import { SubmissionPageData } from '../../../../../modules/submission-management/data/submission-page.data';
import { PropertyData } from '../../../data/property.data';
import { LocationLabelConstants, PropertyDropdownName } from '../../../../../shared/constants/property.labels.constants';
import { AuthService } from '../../../../../core/services/auth.service';
import { Subject } from 'rxjs';
import { GenericLabel } from '../../../../../shared/constants/generic.labels.constants';
import { ApplicantData } from '../../../../../modules/submission-management/data/applicant.data';
import { AllResponseDTO } from '../../../../../shared/models/data/dto/third-party/all-response.dto';
import { ThirdPartyData } from '../../../../../modules/submission-management/data/third-party.data';
import { PropertyValidationService } from '../../../../../core/services/submission/validations/property-validation.service';
import { GeneralValidationService } from '../../../../../core/services/submission/validations/general-validation.service';
import { Validators } from '@angular/forms';
import FormUtils from '../../../../../shared/utilities/form.utils';
import { PolicySummaryData } from '../../../../../modules/policy-management/data/policy-summary.data';
import { EndorsementsData } from '../../../../../modules/submission-management/data/endorsements.data';
import { QuoteService } from '../../../../../core/services/quote.service';
import { FormTypeConstants } from '../../../../../shared/constants/form-types.constants';
import { EntityRiskData } from '../../../../../modules/submission-management/data/entity-risk.data';
import { CoveragesData, MINCOVCDP3DEF, MAXCOVCDP3DEF, MINCOVCDP3TENANT, MAXCOVCDP3TENANT } from 'app/modules/submission-management/data/coverages.data';
import { CoveragesValidators } from 'app/modules/submission-management/validators/coverages.validators';
import { takeUntil } from 'rxjs/operators';
import { BaseClass } from '../../../../../shared/base-class';
import { GenericConstants } from '../../../../../shared/constants/generic.constants';
import { MoratoriumService } from '../../../../../core/services/moratorium.services';
import NotifUtils from '../../../../../shared/utilities/notif-utils';
import * as moment from 'moment';
import { Pages } from 'app/shared/constants/pages-and-sections.constants';
import { MoratoriumType } from '../../../../../shared/constants/moratoriumType';
import { PropertyValidatorErrorConstants } from '../../../validators/validator-error-constants/property-validator-error.constants';
import { PropertyBusinessRules } from 'app/modules/submission-management/business-rules/property.business-rules';
import { RequestObjectMoratorium } from 'app/shared/models/submission/quick-quote/moratoriums.model';
import { updateAppIsConfirmationFromLocationDetailsComponent } from 'app/store/app/app.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss']
})
export class LocationDetailsComponent extends BaseClass implements OnInit, OnChanges, OnDestroy {
  public ErrorMessageConstant = ErrorMessageConstant;
  public LocationLabelConstants = LocationLabelConstants;
  public FormUtils = FormUtils;
  public DropdownName = PropertyDropdownName;
  GenericLabel = GenericLabel;
  isOpen: boolean = true;
  countyList: any[] = [];
  isTextboxShow: boolean = true;
  isInternal = new Subject<boolean>();
  isInternal$ = this.isInternal.asObservable();
  showInternal: boolean = true;
  formTypeConstants = FormTypeConstants;
  PropertyValidatorErrorConstants = PropertyValidatorErrorConstants;

  @Input() userType: string;

  constructor(
    public propertyData: PropertyData,
    public submissionData: SubmissionPageData,
    public authService: AuthService,
    protected applicantData: ApplicantData,
    protected thirdPartyData: ThirdPartyData,
    protected propertyValidation: PropertyValidationService,
    protected generalValidationService: GeneralValidationService,
    public policySummaryData: PolicySummaryData,
    protected endorsementsData: EndorsementsData,
    public quoteService: QuoteService,
    protected entityRiskData: EntityRiskData,
    protected coveragesData: CoveragesData,
    protected moratoriumService: MoratoriumService,
    protected propertyBusinessRules: PropertyBusinessRules,
    protected store: Store
  ) {
    super();
  }

  get riskId() {
    return this.entityRiskData.getRiskId();
  }

  ngOnInit() {
    this.propertyBusinessRules.triggerActiveNew112();
    this.propertyBusinessRules.triggerActiveNew115();
  }

  ngOnChanges() {
    this.showInternal = this.userType === 'external' ? false : true;
    this.propertyValidation.userTypeValidation(Pages.Property.sections.LocationDetails);
    this.propertyData.isExternal = !this.showInternal;
  }

  collapse() {
    this.isOpen = !this.isOpen;
    this.submissionData.isLocationOpen = this.isOpen;
  }

  get ApplicantsForm() {
    return this.applicantData.mainApplicant;
  }
  get PropertyLocationForm() {
    return this.propertyData.locationDetails;
  }

  setProtectionClass() {

    const protectionClassLowerNumber = this?.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationLowerNumber ?? this.propertyData.protectionClassLowerNumber;
    const protectionClassHigherNumber = this?.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationHigherNumber ?? this.propertyData.protectionClassHigherNumber;

    if (this.propertyData.locationDetails.get('feetToHydrant').value === LocationLabelConstants.FireHydrantLessThan1000Feet) {

      this.propertyData.locationDetails.get('protectionClass').setValue(protectionClassLowerNumber);

    } else if (this.propertyData.locationDetails.get('feetToHydrant').value === LocationLabelConstants.FireHydrantMoreThan1000Feet) {

      this.propertyData.locationDetails.get('protectionClass').setValue(protectionClassHigherNumber);
    }
  }

  checkInternalPolicyDropdownControls(): boolean {
    const status = !this.policySummaryData.isPolicy ? true :
      (this.policySummaryData.isEditPolicy && this.showInternal) ? false : true;
    return status;
  }

  protected isPremisesLiabilityMandatory(): boolean {

    const MINIMUMNUMBEROFMONTHS: number = 1;
    const MAXIMUMNUMBEROFMONTHS: number = 8;

    return Number(this.propertyData.locationDetails.get('numMonthsOccupied').value) >= MINIMUMNUMBEROFMONTHS &&
           Number(this.propertyData.locationDetails.get('numMonthsOccupied').value) <= MAXIMUMNUMBEROFMONTHS &&
           this?.coveragesData?.coveragesAndDeductiblesForm?.get('isCoverageLEnabled')?.value &&
           (this?.propertyData?.locationDetails?.get('propertyUsage')?.value?.toString()?.toUpperCase()?.trim() !== LocationLabelConstants.ownerOccupiedPrimary &&
           this?.propertyData?.locationDetails?.get('propertyUsage')?.value?.toString()?.toUpperCase()?.trim() !== LocationLabelConstants.ownerOccupiedSecondary);
  }

  onSelectChangePremisesLiabilityCheck(): void {

    const formType = this.endorsementsData.quoteService.formType;
    const formTypeConstants = this.endorsementsData.formTypeConstants;

    if (formType === formTypeConstants.DP3) {

      if (this.isPremisesLiabilityMandatory()) {
        this.endorsementsData.endorsementsGroup.get('premisesLiability').setValue(true);
        if (this.userType.toString()?.toLowerCase()?.trim() === GenericConstants?.userType?.external?.toString()?.toLowerCase()?.trim()) {
          this.endorsementsData.endorsementsGroup.get('premisesLiability').disable();
          this.endorsementsData.isPremisesLiabilityDisabled.next(true);
        }
      } else {
        this?.endorsementsData?.manualToggleValueForPremisesLiability$.pipe(takeUntil(this.stop$)).subscribe(value => {
          this.endorsementsData.endorsementsGroup.get('premisesLiability').setValue(value ?? this?.endorsementsData?.endorsementsGroup?.get('premisesLiability')?.value);
        });

          this.endorsementsData.endorsementsGroup.get('premisesLiability').enable();
          this.endorsementsData.isPremisesLiabilityDisabled.next(false);
      }

    }

  }

  onCountyChange(): void {
    const countyFromProperty = this?.propertyData?.locationDetails?.get('county')?.value?.toString()?.trim() ?? '';
    const effectiveDateFromEntity = moment(new Date(this?.applicantData?.mainApplicant.get('effectiveDate').value?.singleDate?.jsDate)).format('YYYY-MM-DD');
    const zipCodeFromEntity = this?.applicantData?.mainApplicant?.get('qqZipCode')?.value?.toString()?.trim() ?? '';
    const stateCodeFromEntity = this?.applicantData?.mainApplicant?.get('qqState')?.value?.toString()?.trim() ?? '';

    if (!countyFromProperty || !effectiveDateFromEntity || !zipCodeFromEntity || !stateCodeFromEntity) {
      return;
    }

    const moratoriumRequest: RequestObjectMoratorium = {
      county: countyFromProperty,
      effectiveDate: effectiveDateFromEntity,
      zipCode: zipCodeFromEntity,
      stateCode: stateCodeFromEntity,
      formType: this.quoteService.formType,
      agencyId: this.entityRiskData.agencyId
    };

    this.moratoriumService.getMoratoriums(moratoriumRequest).pipe(takeUntil(this.stop$)).subscribe(moratoriumResult => {
      const response = moratoriumResult.filter(a => a.type === MoratoriumType.closure || a.type === MoratoriumType.moratorium || a.type === MoratoriumType.coverageMoratorium);
      if (response?.length > 0) {
        this.entityRiskData.moratoriumResponse = response;
        let messageBuilder = '';

        response.forEach(x => {
          messageBuilder += ` <li> ${x.popUpMessage} </li> `;
        });

        if (this.authService.isExternalUser) {
          this.store.dispatch(updateAppIsConfirmationFromLocationDetailsComponent({ isConfirmation: true }));

          NotifUtils.showInformationWithConfirmationFromUser(messageBuilder, () => {
            this.store.dispatch(updateAppIsConfirmationFromLocationDetailsComponent({ isConfirmation: false }));
          });
        }
      } else {
        this.entityRiskData.moratoriumResponse = null;
      }
    },(error) => {
      this.store.dispatch(updateAppIsConfirmationFromLocationDetailsComponent({ isConfirmation: true }));

      NotifUtils.showInformationWithConfirmationFromUser(GenericConstants.moratoriumAPIError, () => {
        this.store.dispatch(updateAppIsConfirmationFromLocationDetailsComponent({ isConfirmation: false }));
      });
    });
  }

  onSelectChange(fieldName: string, event: any): void {
    const code = event.target.value;
    const formType = this.endorsementsData.quoteService.formType;
    const formTypeConstants = this.endorsementsData.formTypeConstants;
    switch (fieldName) {
      case this.DropdownName.PropertyUsage:
        if ((code === LocationLabelConstants.ownerOccupiedPrimary || code === LocationLabelConstants.ownerOccupiedSecondary) && formType === formTypeConstants.DP3) {
          this.onSelectChangePremisesLiabilityCheck();
        } else if (formType === formTypeConstants.DP3 && (code !== LocationLabelConstants.ownerOccupiedPrimary && code !== LocationLabelConstants.ownerOccupiedSecondary)) {
          this.onSelectChangePremisesLiabilityCheck();
        }

        if (formType === formTypeConstants.DP3) {
          const isLimitedTheftCoverageAvailable = this.endorsementsData.showLimitedTheftCoverageToggle();
          if (!isLimitedTheftCoverageAvailable) {
            this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').setValue(isLimitedTheftCoverageAvailable);
          }
          this.endorsementsData.isLimitedTheftCoverageAvailable = isLimitedTheftCoverageAvailable;
        } else {
          this.endorsementsData.isLimitedTheftCoverageAvailable = ((code === LocationLabelConstants.ownerOccupiedPrimary
            || code === LocationLabelConstants.ownerOccupiedSecondary
            || code === LocationLabelConstants.SeasonalDwelling) && formType === formTypeConstants.DP3);
        }

        if (code === LocationLabelConstants.SeasonalDwelling || code === LocationLabelConstants.ownerOccupiedSecondary) {
          if (formType !== formTypeConstants.DP3) {
            this.endorsementsData.endorsementsGroup.get('seasonalDwelling').setValue(true);
          }
        } else if (code === LocationLabelConstants.ownerOccupiedPrimary && formType === formTypeConstants.HO6) {
          this.endorsementsData.endorsementsGroup.get('seasonalDwelling').setValue(false);
        } else if (code === LocationLabelConstants.tenantOccupied && formType === formTypeConstants.HO6) {
          this.endorsementsData.endorsementsGroup.get('unitOwnersRentalToOthers').setValue(true);
          this.endorsementsData.endorsementsGroup.get('unitOwnersRentalToOthers').disable();
          this.endorsementsData.endorsementsGroup.get('leaseTerm').setValue('');
        } else {
          this.endorsementsData.endorsementsGroup.get('seasonalDwelling').setValue(false);
        }

        if (formType === formTypeConstants.HO6) {
          if (this.propertyData.currentPropertyUsage === LocationLabelConstants.tenantOccupied && code !== LocationLabelConstants.tenantOccupied) {
            this.endorsementsData.endorsementsGroup.get('unitOwnersRentalToOthers').setValue(false);
            this.endorsementsData.endorsementsGroup.get('unitOwnersRentalToOthers').enable();
            this.endorsementsData.endorsementsGroup.get('leaseTerm').setValue('');
          }
        }
        this.propertyData.setCoverageLValidator();

        if (formType === formTypeConstants.DP3) {
          this.updateCovCValidator(code);
          if (this.coveragesData.invalidCovC !== -1 && this.coveragesData.covCInfoMessage) {
            if (Number(this.coveragesData.invalidCovC) < 0) {
              this.coveragesData.covCInfoMessage = false;
            }
            if(Number(this.coveragesData.invalidCovC) === Number(this.coveragesData.validCovC)) {
              this.coveragesData.covCInfoMessage = false;
              this.coveragesData.covCInfoViewed = false;
              this.coveragesData.invalidCovC = null;
            }
          }
        }
        this.propertyBusinessRules.triggerActiveNew112(code, true);
        this.propertyBusinessRules.triggerActiveNew115(code, true);

        if (!this.policySummaryData.isPolicy &&
          !this.entityRiskData.getIsRenewalQuote() &&
          (formType === formTypeConstants.HO6 || formType === formTypeConstants.DP3) &&
          (code === LocationLabelConstants.shortTermRental || code === LocationLabelConstants.tenantOccupied)) {
            this.propertyBusinessRules.triggerBRUL351();
        }
        break;
      case this.DropdownName.StructureType:
        this.setFeetToHydrantValueAndValidity(code);
        this.propertyValidation.numberOfUnitsInsuredValidation();
        break;
      case this.DropdownName.NumberOfUnitsInsured:
        this.propertyValidation.numberOfUnitsInsuredValidation();
        break;
      default:
        break;
    }
  }

  setFeetToHydrantValueAndValidity(code: string): void {
    if (code !== 'E' && code !== 'I') {
      this.generalValidationService.clearValidatorFormControl(this.propertyData.locationDetails, 'numberOfUnitsFirewalls');
      return;
    }

    this.generalValidationService.resetValidatorFormControl(this.propertyData.locationDetails, 'numberOfUnitsFirewalls', [Validators.required]);
    this.propertyData.locationDetails.get('numberOfUnitsFirewalls').setValue('');
  }

  updateCovCValidator(propertyUsage: string): void {
    const covC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value;
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').clearValidators();
    if (covC > 0) {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValidators([
        Validators.required,
        Validators.min(propertyUsage === this.LocationLabelConstants.tenantOccupied ? MINCOVCDP3TENANT : MINCOVCDP3DEF),
        Validators.max(propertyUsage === this.LocationLabelConstants.tenantOccupied ? MAXCOVCDP3TENANT : MAXCOVCDP3DEF)
      ]);
    } else {
      this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').setValidators([Validators.required]);
    }
    this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').updateValueAndValidity();
  }

  checkIfMilesToFireStation(): string {
    return this.coveragesData?.isPolicyPageNotEditable()
    || this.thirdPartyData?.dataFromAPIResponse?.isMilesToFireStationFromISO ? 'disabled' : null;
  }

  onResidenceRentedChange(): void {
    const formType = this.endorsementsData.quoteService.formType;
    const formTypeConstants = this.endorsementsData.formTypeConstants;

    this.propertyBusinessRules.triggerActiveNew112(null, true);
    this.propertyBusinessRules.triggerActiveNew115(null, true);

    const isLimitedTheftCoverageAvailable = this.endorsementsData.showLimitedTheftCoverageToggle();
    if (formType === formTypeConstants.DP3 && !isLimitedTheftCoverageAvailable) {
      this.endorsementsData.endorsementsGroup.get('limitedTheftCoverage').setValue(isLimitedTheftCoverageAvailable);
    }
    this.endorsementsData.isLimitedTheftCoverageAvailable = isLimitedTheftCoverageAvailable;

    if (!this.policySummaryData.isPolicy && !this.entityRiskData.getIsRenewalQuote()) {
      this.propertyBusinessRules.triggerBRUL351();
    }
  }

  ngOnDestroy(): void {
  }
}
