import { Injectable } from '@angular/core';
import {
  LvUWApprovalCodeConstants,
  LvUWApprovals,
} from 'app/shared/constants/uw.referrals';
import { BaseClass } from 'app/shared/base-class';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { PropertyData } from 'app/modules/submission-management/data/property.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import { UwQuestionsData } from 'app/modules/submission-management/data/uw-questions.data';
import { InterestData } from 'app/modules/submission-management/data/interest.data';
import { ClaimsData } from 'app/modules/submission-management/data/claims.data';
import { ApplicantData } from 'app/modules/submission-management/data/applicant.data';
import { LossTypeModel } from 'app/shared/models/submission/claims/claims.model';
import { ITableTr } from 'app/shared/models/dynamic/table.interface';
import { FloodZipCodesWatchList } from 'app/shared/constants/watch-list.zipcode.list';
import { LvFoundationElevation } from 'app/shared/constants/property.options.constants';
import { LvFloodPolicyDuringPolicyPeriod, LvRaterDeductibleHUR } from 'app/shared/constants/quick-quote.options.constants';
import * as _ from 'lodash';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { RiskDetailsDTO } from 'app/shared/models/data/dto/quick-quote/risk-details.dto';
import { AuthService } from '../../auth.service';
import { Rater } from '../../../../shared/constants/rater.constant';
import { UWRHelpers } from './uwr-helpers.service';
import { GenericConstants } from 'app/shared/constants/generic.constants';
import * as moment from 'moment';
import { LocationLabelConstants } from '../../../../shared/constants/property.labels.constants';

@Injectable({
  providedIn: 'root',
})
export class BaseUWR extends BaseClass {
  county: any = {
    broward: 'Broward',
    palmBeach: 'Palm Beach',
    stLucie: 'St Lucie',
    miamiDade: 'Miami-Dade',
  };

  baseUWR9minYearBuilt: number = 2007;

  baseUWR9CountyList: string[] = [
    'miami-dade', 'broward', 'palm beach', 'osceola', 'orange'
  ];

  floodZipCodesWatchList = FloodZipCodesWatchList;
  lvUWApprovals = LvUWApprovals;
  lvUWApprovalCodeConstants = LvUWApprovalCodeConstants;
  currentDate = this.authService.getCustomDate();
  saveCoverages: boolean = true;
  genericConstants = GenericConstants;

  baseUWR1and10CountyList: string[] = [ 'palm beach', 'st lucie', 'broward', 'miami-dade', 'brevard', 'charlotte', 'collier', 'desoto',
    'glades', 'hardee', 'hendry', 'highlands', 'hillsborough', 'indian river', 'lee', 'manatee', 'martin', 'monroe', 'okeechobee',
    'orange', 'osceola', 'pinellas', 'polk', 'sarasota'];

  constructor(
    protected coveragesData: CoveragesData,
    protected propertyData: PropertyData,
    protected summaryData: SummaryData,
    protected endorsementsData: EndorsementsData,
    protected claimsData: ClaimsData,
    protected entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    protected thirdPartyData: ThirdPartyData,
    protected applicantData: ApplicantData,
    protected uwQuestionsData: UwQuestionsData,
    protected authService: AuthService,
    protected interestData: InterestData,
    protected uwApprovalData: UwApprovalData,
    protected uwrHelpers: UWRHelpers
  ) {
    super();
  }

  get parentRiskDetail(): RiskDetailsDTO {
    return this.uwrHelpers.isValidForRenewalUWR ? this.entityRiskData.renewalBackup : this.entityRiskData?.ParentBackUpRiskData;
  }

  baseUWR1(): boolean {
    // UWR-1 Condition
    //  Coverage A minimums on HO3 policies in the following counties:
    //   $350,000 - Palm Beach, St Lucie, Broward, Miami-Dade, Brevard, Charlotte, Collier, DeSoto, Glades, Hardee, Hendry, Highlands,
    //   Hillsborough, Indian River, Lee, Manatee, Martin, Monroe, Okeechobee, Orange, Osceola, Pinellas, Polk, or Sarasota
    //  Form-Type
    //    HO3, DP3

    // Involves:
    // 1. Dashboard > Start New Quote > Form Type
    // 2. Quick Quote > Application Information > Zip Code
    // 3. Coverages > Coverages > Coverages > Coverage A - Dwelling

    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    let status = false;

    if (covA && county) {
      status = this.uwrHelpers.isHO3orDP3 && covA < 350000 && this.baseUWR1and10CountyList.includes(county);
    }

    return status;
  }

  baseUWR2(): boolean {
    // Invovle/s:
    // Distance to coast return from ISO location API , ref: distance.constants.ts

    let status = false;
    const lessthan1000 = ['01', '02'];
    const locationApiData = this.thirdPartyData?.dataFromAPIResponse;
    status = this.uwrHelpers.isHO3orDP3orHO4orHO6 &&
      (lessthan1000.includes(locationApiData?.distanceToActualCoastCode) || lessthan1000.includes(locationApiData?.distanceToWaterCode));

    return status;
  }

  baseUWR3(): boolean {
    // Involves:
    // 1. Quick Quote > Application Information > Zip Code
    // 2. Coverages > Coverages > Deductibles > All Other Perils Deductible

    // Note: Coverages > Coverages > Deductibles > All Other Perils Deductible can only be edited if user type is internal

    const aop = +this.coveragesData.coveragesAndDeductiblesForm.get(
      'allOtherPerilsId'
    )?.value;
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    let status = false;

    if (aop && county) {
      status =
        this.uwrHelpers.isHO3orDP3orHO6 &&
        aop < 2500 &&
        (county === this.uwrHelpers.stringToLower(this.county.broward) ||
          county === this.uwrHelpers.stringToLower(this.county.palmBeach) ||
          county === this.uwrHelpers.stringToLower(this.county.miamiDade) ||
          county === this.uwrHelpers.stringToLower(this.county.stLucie));
    }

    return status;
  }

  baseUWR4(): boolean {
    // Involves:
    // 1. Quick Quote > Applicant Information > Zip Code
    // 2. Property > Property Details > Year Built
    // 3. Endorsements > Endorsements > Limited Water Damage Coverage

    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - Number(yearBuilt) : null;
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    const limitedWater = this.endorsementsData.endorsementsGroup.get(
      'limitedWater'
    )?.value;
    let status = false;

    if (yearBuiltAge && county) {
      status =
        this.uwrHelpers.isHO3orHO6 &&
        yearBuiltAge > 40 &&
        limitedWater === false &&
        (county === this.uwrHelpers.stringToLower(this.county.broward) ||
          county === this.uwrHelpers.stringToLower(this.county.palmBeach) ||
          county === this.uwrHelpers.stringToLower(this.county.miamiDade) ||
          county === this.uwrHelpers.stringToLower(this.county.stLucie));
    }

    return status;
  }

  baseUWR5(): boolean {
    // Invovle/s:
    // Distance to coast return from ISO location API , ref: distance.constants.ts
    // and Hurricane deductible less than 5%
    if (this.uwrHelpers.checkIsUWRTurnOffWithEffectiveDate(this.authService.turnOffUWR5EffectiveDate, this.uwrHelpers.riskEffectiveDate)) {
      return false;
    }

    let status = false;
    const distance1001to2500 = ['03', '04', '05'];
    const locationApiData = this.thirdPartyData?.dataFromAPIResponse;

    const hurrDeductible = LvRaterDeductibleHUR.find(x => x.code === this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value);

    let isLessThan5Percent: boolean = false;
    if (hurrDeductible == null || hurrDeductible.code === 'E') {
      isLessThan5Percent = false;
    } else if (hurrDeductible.isPercentage) {
      isLessThan5Percent = Number(hurrDeductible.code) < 5;
    } else {
      if (this.uwrHelpers.isHO3orDP3) {
        const covA: number = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));

        isLessThan5Percent = covA > 0 ?
          (Math.trunc(Number(hurrDeductible.code)) / covA) < 0.05 : false;
      } else {
        isLessThan5Percent = false;
      }
    }

    status = (distance1001to2500.includes(locationApiData?.distanceToActualCoastCode) || distance1001to2500.includes(locationApiData?.distanceToWaterCode)) &&
      this.uwrHelpers.isHO3orDP3 && isLessThan5Percent;

    return status;
  }

  baseUWR6(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 6; }).status;

    return status;
  }

  baseUWR7(): boolean {
    // HO3 and HO6
    // and rating tier (Credit/Prior Claim tier) is 22 and higher
    // and county is "Palm Beach", "Miami-Dade" or "Broward"

    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    const ratingTier = this.coveragesData.creditPriorClaimTier;
    let status = false;

    if (county && ratingTier) {
      status =
        this.uwrHelpers.isHO3orDP3orHO6 &&
        (county === this.uwrHelpers.stringToLower(this.county.palmBeach) ||
          county === this.uwrHelpers.stringToLower(this.county.miamiDade) ||
          county === this.uwrHelpers.stringToLower(this.county.broward)) && ratingTier >= 22;
    }

    return status;
  }

  baseUWR8(): boolean {
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    let status = false;

    if (yearBuilt && county) {
      status =
        this.uwrHelpers.isHO3orDP3orHO6 &&
        yearBuilt < 2002 &&
        (county === this.uwrHelpers.stringToLower(this.county.broward) ||
          county === this.uwrHelpers.stringToLower(this.county.miamiDade) ||
          county === this.uwrHelpers.stringToLower(this.county.palmBeach));
    }

    return status;
  }

  baseUWR9(): boolean {
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const waterDamage = this.endorsementsData.endorsementsGroup.get(
      'waterDamage'
    )?.value;
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    let status = false;

    if (yearBuilt && waterDamage === false) {
      status =
        this.uwrHelpers.isHO3orDP3orHO6 &&
        Number(yearBuilt) <= this.baseUWR9minYearBuilt &&
        waterDamage === false && this.baseUWR9CountyList.includes(county);
    }

    return status;
  }

  baseUWR10(): boolean {
    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    let status = false;

    if (covA && county) {
      status = this.uwrHelpers.isHO3orDP3 && covA < 250000 && !this.baseUWR1and10CountyList.includes(county);
    }

    return status;
  }

  baseUWR11(): boolean {
    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const costPerSqFt = Math.trunc(Number(this?.thirdPartyData?.costPerSqft || null));
    const cntrReplacementCost: number = Math.trunc(Number(this.thirdPartyData?.dataFromAPIResponse?.iso360Value?.replacementCost));
    const squareFootage = Math.trunc(Number(this.propertyData.propertyDetails.get('squareFootage')?.value));
    const minimum: Number = 85;
    const maximum: Number = 200;
    const maxCovA: Number = 1000000;

    const status = this.uwrHelpers.isHO3orDP3 &&
      (costPerSqFt < minimum ||
        (costPerSqFt > maximum && covA <= maxCovA) ||
        (cntrReplacementCost !== covA && ((Math.trunc(covA / squareFootage)) < minimum )));

    return status;
  }

  baseUWR12(): boolean {
    const DoDogsKept = this.uwrHelpers.isHO3orDP3orHO4orHO6 && this.uwQuestionsData.eligibilityGroup.get('DoDogsKept')
      ?.value;

    return DoDogsKept;
  }

  baseUWR13(): boolean {
    const effectiveDate = new Date(this.applicantData?.mainApplicant?.get('effectiveDate')?.value?.singleDate?.jsDate).getFullYear();
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - yearBuilt : null;
    const yearDwelling = 76;

    const status = this.uwrHelpers.isHO3orDP3 && (yearBuiltAge) && yearBuiltAge >= yearDwelling;

    return status;
  }

  baseUWR14(): boolean {
    // Involves:
    // 1. Coverages > Flood > Add Centauri Private Flood Coverage
    // 2. Coverages > Flood > Elevation Certificate
    // 3. Coverages > Flood > Base Flood Elevation
    // 4. Coverages > Flood > Lowest Floor Elevation

    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    const elevationCertificate = this.coveragesData.floodForm.get('elevationCertificateId')?.value === 'Y';
    const lowestFloorElevation = +this.coveragesData.floodForm.get('lowestFloodElevation')?.value;
    const baseFlood = +this.coveragesData.floodForm.get('baseFloodElevation')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orHO4orHO6 && floodCoverage && elevationCertificate && lowestFloorElevation && baseFlood) {
      status = floodCoverage && lowestFloorElevation < baseFlood;
    }

    return status;
  }

  baseUWR15(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 15;}).status;

    return status;
  }

  baseUWR16(): boolean {
    // UWR-16 Condition
    // If HO3 and structure usage drop down selection is "tenant occupied" or "short term rental"
    //  unless 'Structure Type' = 'Two Family (Duplex)'
    // Form-Type
    //  HO3
    let status = false;

    const conditions = ['TO', 'STR'];
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    const structureType = this.propertyData.locationDetails.get('structureType')?.value;

    status = this.uwrHelpers.isHO3 &&
      conditions.includes(propertyUsage) &&
      structureType !== '2';

    return status;
  }

  baseUWR17(): boolean {
    const floodZone = this.coveragesData.floodForm.get('floodZone')?.value.charAt(0);
    const applicantFloodPolicyId = this.coveragesData.floodForm.get('applicantFloodPolicyId')?.value;
    const applicantFloodPolicyNo = LvFloodPolicyDuringPolicyPeriod.find(a => a.description === 'No');
    const floorNumber = this.propertyData.propertyDetails.get('floorUnitNumber')?.value?.toString();
    let status = false;

    if (floodZone && applicantFloodPolicyId && floorNumber) {
      status =
        this.uwrHelpers.isHO6 &&
        (floodZone === 'A' || floodZone === 'V') &&
        applicantFloodPolicyId === applicantFloodPolicyNo.code &&
        (floorNumber === '0' || floorNumber === '1' || floorNumber === '2');
    }

    return status;
  }

  baseUWR18(): boolean {
    //  UWR-18 Condition
    //    If agent issues new business policy or cancel/rewrites to HO3 policy and Year built is greater than 40 years old,
    //      and optional Endorsement "Water Damage Exclusion" Is missing.
    //  Form-Type
    //    HO3

    // Involve > Property > YearBuilt
    //         > Endorsement > Water Damage Exclusion Endorsement.

    const currentDate = this.authService.getCustomDate();
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - yearBuilt : null;
    const waterDamage = this.endorsementsData.endorsementsGroup.get(
      'waterDamage'
    )?.value;

    const status = this.uwrHelpers.isHO3 && yearBuiltAge > 40 && !waterDamage;

    return status;
  }

  baseUWR19(): boolean {
    return false;
  }

  baseUWR20(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 20; }).status;

    return status;
  }

  baseUWR21(): boolean {
    let centralAirFalseInUwOrProperty = false;

    if(!this.propertyData.propertyDetails.get('isCentralAirConditioning')?.value || !this.uwQuestionsData.eligibilityGroup.get('HaveCentralHeat')?.value) {
      centralAirFalseInUwOrProperty= true;
    } else {
      centralAirFalseInUwOrProperty = false;
    }

    const status = this.uwrHelpers.isHO3orDP3orHO6 && centralAirFalseInUwOrProperty;

    return status;
  }

  baseUWR22(): boolean {
    // Involves:
    // 1. Dashboard > Start New Quote > Form Type
    // 2. Coverages > Coverages > Coverages > Coverage A - Dwelling
    // 3. Property > Property Details > Replacement Cost

    // Note: Property > Property Details > Replacement Cost field is API driven

    const coverageA: number = Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value);
    const replacementCost: number = Number(this.propertyData.propertyDetails.get('replacementCost')?.value);
    const percOfReplacementCost: number = coverageA / replacementCost;
    const minimumPerc: number = 1;
    const isValidPerc: boolean = percOfReplacementCost < minimumPerc;
    const status: boolean = this.uwrHelpers.isHO3orDP3 && isValidPerc;

    return status;
  }

  baseUWR23(): boolean {
    const floodZone = this.coveragesData.floodForm
      .get('floodZone')
      ?.value.charAt(0);
    const applicantFloodPolicyId = this.coveragesData.floodForm.get(
      'applicantFloodPolicyId'
    )?.value;
    const applicantFloodPolicyNo = LvFloodPolicyDuringPolicyPeriod.find(a => a.description === 'No');
    let status = false;

    if (floodZone && applicantFloodPolicyId) {
      status =
        this.uwrHelpers.isHO3orDP3orHO6 &&
        (floodZone === 'A' || floodZone === 'V') &&
        applicantFloodPolicyId === applicantFloodPolicyNo.code;
    }

    return status;
  }

  baseUWR24(): boolean {
    const addSchedules: boolean = this.coveragesData.schedulesForm.get('addSchedules')?.value;
    const itemsDamages: boolean = this.coveragesData.schedulesForm.get('itemsDamages')?.value;
    const status: boolean = this.uwrHelpers.isHO3orHO4orHO6 && addSchedules && itemsDamages;

    return status;
  }

  baseUWR25(): boolean {
    // Involves:
    // 1. Claims > Add Claims > Claim Type
    // 2. Endorsements > Star Package
    // 3. Endorsements > Star Advantage Package

    // Note: See the Lost Type Chargeable and Referral List document to see the claims with Act of God indicator
    // As per discussion in CENTAURI-1323, UWR-25 should be implemented but should not be triggered.

    // const starPackage = this.endorsementsData.endorsementsGroup.get('starPackage')?.value;
    // const starAdvantagePackage = this.endorsementsData.endorsementsGroup.get('starAdvantagePackage')?.value;
    // let status = false;
    // let claims: LossTypeModel[] = [];

    // this.claimsData.claimsTableRows.forEach((item: ITableTr) => {
    //   claims = this.claimsData.claimTypes.filter((claimType) => {
    //     const claimDate = item.tr[0].value.singleDate.date;
    //     const yearDiff = this.uwrHelpers.getYearDiffFromEffectiveDate(
    //       claimDate.day,
    //       claimDate.month,
    //       claimDate.year
    //     );

    //     return (
    //       item.tr[1].value === claimType.code &&
    //       claimType.actOfGod === false &&
    //       yearDiff >= 3 &&
    //       yearDiff >= 0
    //     );
    //   });

    //   status = this.uwrHelpers.isHO3 && claims.length !== 0 && (starPackage || starAdvantagePackage);
    // });

    return false;
  }

  baseUWR26(): boolean {
    // UWR-26 Condition
    // When NB Effective date is prior or equal to UWR26 Update condition.
    //    Months occupied = 0,1,2,3,4,5 and 6 and usage type is primary or seasonal
    // When NB Effective date is greater to UWR26 Update codition.
    //    Months occupied = 0,1,2,3,4,5 and 6 and all usage types except unoccupied or vacant
    // Form-Type
    //  HO3, DP3, HO6

    const baseUWR26UpdateEffectiveDate = new Date(localStorage.getItem('UWR26Update'));
    const quoteEffectiveDate = new Date(this.applicantData.mainApplicant.get('effectiveDate')?.value.singleDate.jsDate);
    let status = false;

    const numMonthsOccupied = this.propertyData.locationDetails.get('numMonthsOccupied')?.value;
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')?.value;
    const numMonthsOccupiedConditions = [1, 2, 3, 4, 5, 6];
    const propertyUsageConditions = ['OOP', 'SS'];
    const VACANT_UNOCCUPIED_CODE = ['UOCC', 'VCN'];

    if (numMonthsOccupied && propertyUsage) {
      if (!this.uwrHelpers.isPolicyOrValidForRenewal && baseUWR26UpdateEffectiveDate > quoteEffectiveDate) {
        status = this.uwrHelpers.isHO3orDP3orHO6 &&
          numMonthsOccupiedConditions.includes(Number(numMonthsOccupied)) &&
          propertyUsageConditions.includes(propertyUsage);
      } else {
        status = this.uwrHelpers.isHO3orDP3orHO6 &&
          numMonthsOccupiedConditions.includes(Number(numMonthsOccupied)) &&
          !VACANT_UNOCCUPIED_CODE.includes(propertyUsage);
      }
    }

    return status;
  }

  baseUWR27(): boolean {
    const conditions = ['VCN', 'UOCC'];
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO6 && conditions.includes(propertyUsage);

    return status;
  }

  baseUWR28(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 28; }).status;

    return status;
  }

  baseUWR29(): boolean {
    const IsProp5Acres = this.uwrHelpers.isHO3 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'IsProp5Acres'
      )?.value;
    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR30(): boolean {
    const electrical = this.propertyData.propertyDetails.get('electrical')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && electrical === 'EO1';

    return status;
  }

  baseUWR31(): boolean {
    //  UWR-31 Condition
    //    HO3 and Roof Material drop down selection = "composition - architechtural shingles" OR "composition - Impact resistant shingle"
    //      and 'year roof system was installed or last updated' is greater that 20 years old.
    //  Form-Type
    //    HO3, DP3

    const conditions = ['COMPA', 'COMPI'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const yearRoof = Number(this.propertyData.propertyDetails.get('yearRoof')?.value);
    let status = false;

    if (yearRoof) {
      const yearRoofAge: number = this.uwrHelpers.riskEffectiveDateYear - yearRoof;

      status = this.uwrHelpers.isHO3orDP3 && conditions.includes(roofMaterial) && yearRoofAge > 20;
    }

    return status;
  }

  baseUWR32(): boolean {
    const WasHomeBuiltAsbestos = this.uwrHelpers.isHO3orDP3orHO4orHO6 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'WasHomeBuiltAsbestos'
      )?.value;

      return WasHomeBuiltAsbestos;
  }

  baseUWR33(): boolean {
    const AreQReleaseBarredWin = this.uwQuestionsData.eligibilityGroup.get(
      'AreQReleaseBarredWin'
    )?.value;
    const bars = this.uwQuestionsData.eligibilityGroup.get('AreBarsWindow')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && bars && !AreQReleaseBarredWin;

    return status;
  }

  baseUWR34(): boolean {
    const DoesHomeHaveVio = this.uwrHelpers.isHO3 && this.uwQuestionsData.eligibilityGroup.get(
      'DoesHomeHaveVio'
    )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR35(): boolean {
    const IsFarmBusPremis = this.uwrHelpers.isHO3orDP3orHO4orHO6 && this.uwQuestionsData.eligibilityGroup.get(
      'IsFarmBusPremis'
    )?.value;

    return IsFarmBusPremis;
  }

  baseUWR36(): boolean {
    const IsDwellGCondition = this.uwrHelpers.isHO3orDP3orHO4orHO6 && this.uwQuestionsData.eligibilityGroup.get(
      'IsDwellGCondition'
    )?.value;

    return !IsDwellGCondition;
  }

  baseUWR37(): boolean {
    const conditions = ['COMPR', 'METC'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3 && conditions.includes(roofMaterial);

    return status;
  }

  baseUWR38(): boolean {
    const IsAnyDupilcate = this.uwrHelpers.isHO3 && this.uwQuestionsData.eligibilityGroup.get(
      'IsAnyDupilcate'
    )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR39(): boolean {
    const feetToHydrant = this.propertyData.locationDetails.get('feetToHydrant')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && feetToHydrant === 'FH1';

    return status;
  }

  baseUWR40(): boolean {
    const roofShape = this.propertyData.propertyDetails.get('roofShape')?.value;
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const pouredConcrete = 'PC';
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && (roofShape === 'F' && roofMaterial !== pouredConcrete);

    return status;
  }

  baseUWR41(): boolean {
    //  UWR-41 Condition
    //    If foundation type is 'elevated post/pier & beams' OR 'stilts with sweep away walls;
    //      OR 'deep pilings' and foundation elevation is ' 6 to 10 feet' OR 'greater than 10 feet' and year built is 50 years or older.
    //  Form-Type
    //    HO3, DP3

    const conditions = ['EP', 'SW', 'DP'];
    const foundationElevationCondition = ['FE2', 'FE1'];
    const foundationType = this.propertyData.propertyDetails.get('foundationType')?.value;
    const foundationElevation = this.propertyData.propertyDetails.get('foundationElevation')?.value;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - yearBuilt : null;
    let status = false;

    if (yearBuiltAge) {
      status = this.uwrHelpers.isHO3orDP3 &&
        conditions.includes(foundationType) &&
        foundationElevationCondition.includes(foundationElevation) &&
        yearBuiltAge >= 50;
    }

    return status;
  }

  baseUWR42(): boolean {
    const conditions = ['EP', 'SW'];

    const foundationType = this.propertyData.propertyDetails.get(
      'foundationType'
    )?.value;
    const foundationElevation = this.propertyData.propertyDetails.get(
      'foundationElevation'
    )?.value;
    const foundationElevationGreaterThan10FeetCode = LvFoundationElevation.find(a => a.code === 'FE2');
    const isDeepPilings = foundationType === 'DP';

    const status = this.uwrHelpers.isHO3orDP3 &&
      conditions.includes(foundationType) || (isDeepPilings && foundationElevation === foundationElevationGreaterThan10FeetCode.code);

    return status;
  }

  baseUWR43(): boolean {
    const electrical = this.propertyData.propertyDetails.get('electrical')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && electrical === 'EO3';

    return status;
  }

  baseUWR44(): boolean {
    const IsHomeHistorical = this.uwrHelpers.isHO3 && this.uwQuestionsData.eligibilityGroup.get(
      'IsHomeHistorical'
    )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR45(): boolean {
    const IsHomeLocOverWater = this.uwrHelpers.isHO3orDP3orHO6 && this.uwQuestionsData.eligibilityGroup.get(
      'IsHomeLocOverWater'
    )?.value;

    return IsHomeLocOverWater;
  }

  baseUWR46(): boolean {
    const IsHomeUsed = this.uwrHelpers.isHO3orDP3orHO4orHO6 && this.uwQuestionsData.eligibilityGroup.get('IsHomeUsed')
      ?.value;

      return IsHomeUsed;
  }

  baseUWR47(): boolean {
    const electrical = this.propertyData.propertyDetails.get('electrical')
      .value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && electrical === 'EO2';

    return status;
  }

  baseUWR48(): boolean {
    const yearElectrical = this.propertyData.propertyDetails.get('yearElectrical')?.value;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3orHO6 && yearBuilt && yearElectrical) {
      const yearElectricalAge = this.uwrHelpers.riskEffectiveDateYear - yearElectrical;
      const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;

      status = yearElectricalAge > 20 && yearBuiltAge > 40;
    }

    return status;
  }

  baseUWR49(): boolean {
    const yearHeating = this.propertyData.propertyDetails.get('yearHeating')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3orHO6 && yearHeating) {
      const yearHeatingAirAge = this.uwrHelpers.riskEffectiveDateYear - yearHeating;

      status = yearHeatingAirAge > 20;
    }

    return status;
  }

  baseUWR50(): boolean {
    const yearCentralAir = this.propertyData.propertyDetails.get('yearCentralAirConditioning')?.value;
    const isYearCentralChecked = this.propertyData.propertyDetails.get('isCentralAirConditioning').value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3orHO6 && (yearCentralAir && isYearCentralChecked)) {
      const yearCentralAirAge = this.uwrHelpers.riskEffectiveDateYear - yearCentralAir;

      status = yearCentralAirAge > 20;
    }

    return status;
  }

  baseUWR51(): boolean {
    //  UWR-51 Condition
    //    'Year plumbing system was installed or last updated' is greater than 20 years old and year built is older than 40 years
    //  Form-Type
    //    HO3, DP3, HO6

    const yearPlumbing = this.propertyData.propertyDetails.get('yearPlumbing')?.value;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3orHO6 && yearPlumbing && yearBuilt) {
      const yearPlumbingAge = this.uwrHelpers.riskEffectiveDateYear - yearPlumbing;
      const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;

      status = yearPlumbingAge > 20 && yearBuiltAge > 40;
    }

    return status;
  }

  baseUWR52(): boolean {
    const priorInsurance = this.interestData.priorInsuranceForm.get(
      'priorInsurance'
    )?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && priorInsurance === 'PI1';

    return status;
  }

  baseUWR53(): boolean {
    const IsHomeLocated = this.uwrHelpers.isHO3 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'IsHomeLocated'
      )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR54(): boolean {
    const conditions = ['METC', 'META'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3 && yearRoof && roofMaterial) {
      const roofIsOld = this.uwrHelpers.riskEffectiveDateYear - yearRoof;

      status = conditions.includes(roofMaterial) && roofIsOld > 30;
    }

    return status;
  }

  baseUWR55(): boolean {
    const milesToFireStation =
      this.propertyData.locationDetails.get('milesToFireStation')?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && milesToFireStation && milesToFireStation === 'MS1';

    return status;
  }

  baseUWR56(): boolean {
    const ArePorchesDecks = this.uwrHelpers.isHO3orDP3 && this.uwQuestionsData.eligibilityGroup.get(
      'ArePorchesDecks'
    )?.value;

    return ArePorchesDecks;
  }

  baseUWR57(): boolean {
    const IsPrefabricatedModHome = this.uwrHelpers.isHO3orDP3 && this.uwQuestionsData.eligibilityGroup.get(
      'IsPrefabricatedModHome'
    ).value;

    return IsPrefabricatedModHome;
  }

  baseUWR58(): boolean {
    const conditions = ['KH', 'PESH', 'PGSH', 'SH', 'WBS', 'OTH'];
    const primaryHeatType = this.propertyData.propertyDetails.get('primaryHeatType')?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && conditions.includes(primaryHeatType);

    return status;
  }

  baseUWR59(): boolean {
    const DoesHomePaved = this.uwrHelpers.isHO3 && this.uwQuestionsData.eligibilityGroup.get(
      'DoesHomePaved'
    )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;
    return status;
  }

  baseUWR60(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 60; }).status;

    return status;
  }

  baseUWR61(): boolean {
    const DoesRoofShingles = this.uwrHelpers.isHO3orDP3 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'DoesRoofShingles'
      )?.value;

      return DoesRoofShingles;
  }

  baseUWR62(): boolean {
    const DoesHomePolyIron = this.uwrHelpers.isHO3orDP3orHO6 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'DoesHomePolyIron'
      )?.value;

      return DoesHomePolyIron;
  }

  baseUWR63(): boolean {
    const IsPrefabricatedModHome = this.uwrHelpers.isHO3 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'IsPrefabricatedModHome'
      )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR64(): boolean {
    const HasAppliCrimeOfArson = this.uwrHelpers.isHO3orDP3orHO4orHO6 &&
      this.uwQuestionsData.generalQuestionsGroup.get(
        'HasAppliCrimeOfArson'
      )?.value;

    return HasAppliCrimeOfArson;
  }

  baseUWR65(): boolean {
    const IsPropLocKnownSink = this.uwrHelpers.isHO3orDP3orHO4orHO6 &&
      this.uwQuestionsData.eligibilityGroup.get(
        'IsPropLocKnownSink'
      )?.value;

      return IsPropLocKnownSink;
  }

  baseUWR66(): boolean {
    const isoLocationProtectionClass: string = this.thirdPartyData?.dataFromAPIResponse?.isoLocationPPC;
    const publicProtectionClassFromPropertyPage: string = this?.propertyData?.locationDetails?.get('protectionClass')?.value;
    const feetToHydrant: string = this.propertyData.locationDetails.get('feetToHydrant').value;
    const conditions: string[] = ['9','10', '10C', '10W'];
    let status = false;

    const protectionClassLowerNumber: string = this?.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationLowerNumber ?? this?.propertyData?.protectionClassLowerNumber;
    const protectionClassHigherNumber: string = this?.thirdPartyData?.dataFromAPIResponse?.protectionClassLocationHigherNumber ?? this?.propertyData?.protectionClassHigherNumber;
    const protectionClassFinal: string = protectionClassLowerNumber === protectionClassHigherNumber ? protectionClassLowerNumber : publicProtectionClassFromPropertyPage;
    const isSplitClass: boolean = isoLocationProtectionClass?.includes('/');
    const nonSplitClassCondition: boolean = (conditions?.includes(protectionClassFinal) ||
      (isoLocationProtectionClass?.includes('X') && !isSplitClass) ||
      conditions?.includes(publicProtectionClassFromPropertyPage));
    const splitClassCondition: boolean = isSplitClass && feetToHydrant === 'FH1';

    if (this.uwrHelpers.isHO3orDP3orHO4orHO6 && (isoLocationProtectionClass || publicProtectionClassFromPropertyPage)) {
      if(nonSplitClassCondition || splitClassCondition) {
        status = true;
      }
    }

    return status;
  }

  baseUWR67(): boolean {
    //  UWR-67 Condition
    //    HO3 and Roof Material drop down selection = 3-tab composition shingle and "year roof system was installed or last updated" is greater than 15 years
    //  Form-Type
    //    HO3, DP3

    const conditions = ['COMP3'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const roofIsOld = Number(this.uwrHelpers.riskEffectiveDateYear) - Number(this.propertyData.propertyDetails.get('yearRoof')?.value) > 15;
    const status = this.uwrHelpers.isHO3orDP3 && conditions.includes(roofMaterial) && roofIsOld;

    return status;
  }

  baseUWR68(): boolean {
    //  UWR-68 Condition
    //    HO3 and Roof material drop down selection = "slate" and 'Year roof system was installed or last updated" is greater than 40 years old.
    //  Form-Type
    //    HO3, DP3

    const conditions = ['SL'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const roofIsOld = (this.uwrHelpers.riskEffectiveDateYear - this.propertyData.propertyDetails.get('yearRoof')?.value) > 40;
    const status = this.uwrHelpers.isHO3orDP3 && conditions.includes(roofMaterial) && roofIsOld;

    return status;
  }

  baseUWR69(): boolean {
    //  UWR-69 Condition
    //    HO3 and Roof Material drop down selection contains "tile" and 'Year roof system was installed or last updated" is greater than 40 years.
    //  Form-Type
    //    HO3, DP3

    const conditions = ['TICL', 'TICO', 'TICE'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')?.value;
    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3 && roofMaterial && yearRoof) {
      const roofIsOld = this.uwrHelpers.riskEffectiveDateYear - yearRoof;
      status = conditions.includes(roofMaterial) && roofIsOld > 40;
    }

    return status;
  }

  baseUWR70(): boolean {
    const IsPropertyBusiness = this.uwrHelpers.isHO3orDP3orHO6 && this.uwQuestionsData.eligibilityGroup.get(
      'IsPropertyBusiness'
    )?.value;

    return IsPropertyBusiness;
  }

  baseUWR71(): boolean {
    const WasHomeConsUncovMethod = this.uwrHelpers.isHO3orDP3 && this.uwQuestionsData.eligibilityGroup.get(
      'IsUnconvDwelling'
    )?.value;

    return WasHomeConsUncovMethod;
  }

  baseUWR72(): boolean {
    const IsBldgUnConsRenov = this.uwrHelpers.isHO3orDP3 && this.uwQuestionsData.generalQuestionsGroup.get(
      'IsBldgUnConsRenov'
    )?.value;

    return IsBldgUnConsRenov;
  }

  baseUWR73(): boolean {
    const IsSwimHotTubPremis = this.uwQuestionsData.eligibilityGroup.get(
      'IsSwimHotTubPremis'
    )?.value;
    const IsPermanentLockFence = this.uwQuestionsData.eligibilityGroup.get(
      'IsPermanentLockFence'
    )?.value;
    const status = this.uwrHelpers.isHO3orDP3 && IsSwimHotTubPremis && !IsPermanentLockFence;

    return status;
  }

  baseUWR74(): boolean {
    const DoesDwelSepWindPol = this.uwQuestionsData.eligibilityGroup.get(
      'DoesDwelSepWindPol'
    )?.value;
    const hurricaneDeductible = this.coveragesData.coveragesAndDeductiblesForm.get(
      'hurricaneDeductibleid'
    )?.value;
    const status = this.uwrHelpers.isHO3 && hurricaneDeductible === 'E'
      && !DoesDwelSepWindPol;

    // Remove UWR as per requirement documents Version5
    const status1 = false;

    return status1;
  }

  baseUWR75(): boolean {
    const conditions = ['WSS'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3 && conditions.includes(roofMaterial);

    return status;
  }

  baseUWR76(): boolean {
    const HaveWoodShingles = this.uwrHelpers.isHO3 && this.uwQuestionsData.eligibilityGroup.get(
      'HaveWoodShingles'
    )?.value;

    // Remove UWR as per requirement documents Version5
    const status = false;

    return status;
  }

  baseUWR77(): boolean {
    const ordinanceLawOrCov = this.endorsementsData.endorsementsGroup.get('ordinanceLawOrCov')?.value;
    const starPackage = this.endorsementsData.endorsementsGroup.get('starPackage').value || false;
    const starAdvantagePackage = this.endorsementsData.endorsementsGroup.get('starAdvantagePackage')?.value || false;

    const status = this.uwrHelpers.isHO3orHO6 && !ordinanceLawOrCov && !starPackage && !starAdvantagePackage;

    return status;
  }

  baseUWR78(): boolean {
    const exteriorWallFinishCode: string = this.propertyData.iso360ValueInitialValueForm.get('exteriorWallFinish360DefaultCode').value;
    const conditions = ['LOG', 'CRP', 'BAB', 'WSS', 'SST'];
    const exteriorWall = this.propertyData.propertyDetails.get(
      'exteriorWallFinish'
    )?.value;

    const status = this.uwrHelpers.isHO3orDP3 &&
      exteriorWallFinishCode &&
      exteriorWall && exteriorWallFinishCode !== exteriorWall &&
      conditions.includes(exteriorWallFinishCode);

    return status;
  }

  baseUWR79(): boolean {
    // Involves:
    // Property > Property Details > Foundation Type

    const foundationType360Code: string = this.propertyData.iso360ValueInitialValueForm.get('foundationType360DefaultCode').value; // Value from 360
    const conditions = ['EP', 'SW', 'DP'];
    const foundationType = this.propertyData.propertyDetails.get(
      'foundationType'
    )?.value;
    const status = this.uwrHelpers.isHO3orDP3 &&
      foundationType360Code &&
      foundationType &&
      foundationType360Code !== foundationType &&
      conditions.includes(foundationType360Code);

    return status;
  }

  baseUWR80(): boolean {
    // Involves:
    // Property > Property Details > Roof Material

    const roofMaterial360Code: string = this.propertyData.iso360ValueInitialValueForm.get('roofMaterial360DefaultCode').value; // Value from 360
    const conditions = ['COMPR', 'BU', 'METC', 'MEMEPDM', 'WSS', 'SPF'];
    const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')
      ?.value;
    const status = this.uwrHelpers.isHO3orDP3 &&
      roofMaterial360Code &&
      roofMaterial &&
      roofMaterial360Code !== roofMaterial &&
      conditions.includes(roofMaterial360Code);

    return status;
  }

  baseUWR81(): boolean {
    // Involves:
    // Property > Property Details > Roof Shape

    const raterRoofShape360Code: string = this.propertyData.iso360ValueInitialValueForm.get('roofShape360DefaultCode').value; // Value from 360
    const conditions = ['F', 'M'];
    const roofShape = this.propertyData.propertyDetails.get('roofShape')?.value;
    const status = this.uwrHelpers.isHO3orDP3 &&
      raterRoofShape360Code &&
      roofShape &&
      raterRoofShape360Code !== roofShape &&
      conditions.includes(raterRoofShape360Code);

    return status;
  }

  baseUWR82(): boolean {
    // Involves:
    // 1. Coverages > Schedules > Add Schedules
    // 2. Coverages > Schedules > Schedule Individual Item (Note a field but a value)

    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 82; }).status;

    return status;
  }

  baseUWR83(): boolean {
    // Involves:
    // Property > Property Details > Square Footage

    const squareFootage = Math.trunc(Number(this.propertyData.propertyDetails.get('squareFootage')?.value));
    const squareFootage360: number = Math.trunc(Number(this.propertyData.squareFootage360Value)); // Value from 360
    const squareFootageDiff: number = Math.abs(squareFootage360 - squareFootage);
    const squareFootagePercent: number = Math.abs((squareFootageDiff / squareFootage) * 100);
    const validPercent = (squareFootagePercent >= 10 && squareFootagePercent > 0) ? true : false;

    let status = false;

    if (this.uwrHelpers.isHO3orDP3
      && (squareFootage || squareFootage === 0 )
      && (squareFootage360 || squareFootage360 === 0)
      && !!this.propertyData.squareFootage360Value) {
      if (squareFootage !== squareFootage360) {
        status = (squareFootage <= 2000 && squareFootageDiff >= 200) || (squareFootage > 2000 && validPercent);
      }
    }

    return status;
  }

  baseUWR84(): boolean {
    // Involves:
    // Property > Property Details > Year Built

    const iso360YearBuiltResponse = this.thirdPartyData.iso360YearBuilt; // Yearbuilt Value from ISO 360 API response
    let status = false;

    if (iso360YearBuiltResponse !== null) {
      const yearBuilt: number = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);

      if (this.uwrHelpers.isHO3orDP3 && yearBuilt && yearBuilt !== iso360YearBuiltResponse) {
        status = true;
      }
    }

    return status;
  }

  baseUWR85(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 85; }).status;

    return status;
  }

  baseUWR86(): boolean {
    //  UWR-86 Condition
    //    Prior Insurance CarrierCd equals Centauri
    //  Form-Type
    //    HO3, DP3

    let status: boolean = false;
    const priorInsuranceCarrier: string = this?.interestData?.priorInsuranceForm?.get('priorCarrier')?.value;
    const centauriInsuranceCode: string = 'PIC14';

    if (priorInsuranceCarrier === centauriInsuranceCode) {
      status = true;
    }

    status = this.uwrHelpers.isHO3orDP3 && status;

    return status;
   }

  baseUWR87(): boolean {
    //  UWR-87 Condition
    //    HO3 and Coverage A limit is greater than $1,000,000.
    //  Form-Type
    //    HO3

    const coveragesData = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const baseUWR87UpdateEffectiveDate = new Date(this.authService.uwr87UpdateEffectiveDate); //Remove UWR87 Effective Date
    const quoteEffectiveDate = new Date(this.uwrHelpers.riskEffectiveDate);

    const status = this.uwrHelpers.isHO3 && coveragesData && coveragesData > 1000000 && quoteEffectiveDate < baseUWR87UpdateEffectiveDate;

    return status;
  }

  baseUWR88(): boolean {
    const conditions = ['PB', 'EP', 'SW'];
    const deepPilling = 'DP';
    const foundationType = this.propertyData.propertyDetails.get(
      'foundationType'
    )?.value;
    const foundationElevation = this.propertyData.propertyDetails.get(
      'foundationElevation'
    )?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orDP3 && foundationType) {
      status = (conditions.includes(foundationType) || (foundationType === deepPilling && foundationElevation === 'FE1'));
    }

    return status;
  }

  baseUWR89(): boolean {
    const conditions = ['TO', 'STR'];
    const propertyUsage = this.propertyData.locationDetails.get('propertyUsage')
      ?.value;
    const structureType = this.propertyData.locationDetails.get('structureType')
      ?.value;

    const status = this.uwrHelpers.isHO3 &&
      propertyUsage &&
      conditions.includes(propertyUsage) &&
      structureType === '2';

    return status;
  }

  baseUWR90(): boolean {
    let limitedFungi = this.endorsementsData.endorsementsGroup.get(
      'limitedFungi'
    )?.value;
    let limitedFungiLimit = this.endorsementsData.endorsementsGroup.get(
      'limitedFungiLimit'
    )?.value;
    let status = false;

    if (this.uwrHelpers.isDP3){
      limitedFungi = this.endorsementsData.endorsementsGroup.get(
        'fungiWetOrDryRot'
      )?.value;
      limitedFungiLimit = this.endorsementsData.endorsementsGroup.get(
        'fungiWetOrDryRotLimit'
      )?.value;
    }

    if (this.uwrHelpers.isHO3orDP3orHO4orHO6 && limitedFungi && limitedFungiLimit) {
      status = limitedFungi === true && Number(limitedFungiLimit) === 50;
    }

    return status;
  }

  baseUWR91(): boolean {
    // Involves:
    // 1. Coverages > Flood > Add Centauri Private Flood Coverage
    // 2. Coverages > Flood > Elevation Certificate

    // Note: Coverages > Flood > Add Centauri Private Flood Coverage can only be enabled and edited if user type is internal

    const floodCoverageEndorsement = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    const elevationCertificate = this.coveragesData.floodForm.get('elevationCertificateId')?.value;
    let status = false;

    if (this.uwrHelpers.isHO3orHO6 && elevationCertificate) {
      status = floodCoverageEndorsement && elevationCertificate === 'Y';
    }

    return status;
  }

  baseUWR92(): boolean {
    const HasAppCovDeclined = this.uwQuestionsData.eligibilityGroup.get(
      'HasAppCovDeclined'
    )?.value;

    const status = this.uwrHelpers.isHO3 && HasAppCovDeclined === true;

    // Remove UWR as per requirement documents Version5
    const status1 = false;

    return status1;
  }

  baseUWR93(): void {
    // Involves:
    // 1. Coverages > Schedules > Add Schedules
    // 2. Coverages > Schedules > Schedule Individual Item (Note a field but a value)

    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')
      ?.value;

    if (this.uwrHelpers.isHO3orHO4orHO6 && addSchedules) {
      this.coveragesData.scheduleTableNames.forEach((scheduleName) => {
        let status = false;
        const tableStatus = this.coveragesData.schedulesForm.get(scheduleName).value;
        const schedule = this.coveragesData[scheduleName + 'TableRows'].filter(
          (item: ITableTr) => {
            return item.tr[1].value > 10000;
          }
        );

        status = schedule.length !== 0 && tableStatus;

        this.uwrHelpers.updateUwrList(
          status,
          this.uwrHelpers.getUwrCode(93),
          `Refer: ${ this.uwrHelpers.getSppName(
            scheduleName
          ) } limit > $10,000 requires prior UW approval`
        );
      });
    } else {
      this.coveragesData.scheduleTableNames.forEach((scheduleName) => {
        this.uwrHelpers.updateUwrList(
          false,
          this.uwrHelpers.getUwrCode(93),
          `Refer: ${ this.uwrHelpers.getSppName(
            scheduleName
          ) } limit > $10,000 requires prior UW approval`
        );
      });
    }
  }

  baseUWR94(): boolean {
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage A - Dwelling
    // 2. Coverages > Schedules > Add Schedules
    // 3. Coverages > Schedules > Schedule Total (Note a field but a value)

    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')
      ?.value;
    let total = 0;
    let status = false;

    if (this.uwrHelpers.isHO3 && addSchedules) {
      this.coveragesData.scheduleTableNames.forEach((item) => {
        const tableStatus = this.coveragesData.schedulesForm.get(item).value;
        if (tableStatus) {
          total += this.coveragesData[item + 'TotalLimit'];
        }
      });

      status = total >= 100000 || (covA / total <= 10 && covA / total > 0);
    }

    return status;
  }

  baseUWR95(): boolean {
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage A - Dwelling
    // 2. Coverages > Schedules > Add Schedules
    // 3. Coverages > Schedules > Schedule Total (Note a field but a value)

    const covC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageC')
      ?.value;
    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')
      ?.value;
    let total = 0;
    let status = false;
    if (this.uwrHelpers.isHO4orHO6 && addSchedules) {
      this.coveragesData.scheduleTableNames.forEach((item) => {
        const tableStatus = this.coveragesData.schedulesForm.get(item).value;
        if (tableStatus) {
          total += this.coveragesData[item + 'TotalLimit'];
        }
      });
      const covC20Percent = covC * 0.2; // 20% of covC
      status = total >= 50000 || total >= covC20Percent;
    }

    return status;
  }

  baseUWR96(): boolean {
    const sinkholeLoss = this.endorsementsData.endorsementsGroup.get(
      'sinkholeLoss'
    )?.value;

    const status = this.uwrHelpers.isHO3orDP3  && sinkholeLoss === true;

    return status;
  }

  baseUWR97(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 97; }).status;

    return status;
  }

  baseUWR98(): boolean {
    // const roofMaterial = this.propertyData.propertyDetails.get('roofMaterial')
    //   ?.value;
    // const status = roofMaterial === 'U';

    //Set status to false to remove Unknown RoofMaterial
    // Remove UWR as per requirement documents Version5
    return false;
  }

  baseUWR99(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 99; }).status;

    return status;
  }

  baseUWR100(): boolean {
    // Involves:
    // 1. Quick Quote > Applicant Information > Zip Code
    // 2. Coverages > Flood > Add Centauri Private Flood Coverage

    // Note: Coverages > Flood > Add Centauri Private Flood Coverage can only be enabled and edited if user type is internal

    const zipcode = Number(this.applicantData.mainApplicant.get('qqZipCode')?.value);
    const floodCoverageEndorsement = this.coveragesData.floodForm.get(
      'privateFloodCoverage'
    )?.value;
    let status = false;

    status = this.uwrHelpers.isHO3orHO4orHO6 && zipcode && floodCoverageEndorsement && this.floodZipCodesWatchList.includes(zipcode);

    return status;
  }

  baseUWR101(): boolean {
    // Involves:
    // 1. Coverages > Coverages > Coverages > Coverage A - Dwelling
    // 2. Property > Property Details > Replacement Cost

    const dwell = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const cntrReplacementCost: number = Math.trunc(Number(this.thirdPartyData?.dataFromAPIResponse?.iso360Value?.replacementCost));
    const covAPercentageOfReplacementCost = dwell / cntrReplacementCost;
    const status = this.uwrHelpers.isHO3orDP3 && (covAPercentageOfReplacementCost < 95 || covAPercentageOfReplacementCost > 125);

    // Temporarily comment turn off this UWR as per CNTSUP-457, set default status to false.
    return status;
    // this.uwrHelpers.updateUwrList(status, this.uwrHelpers.getUwrCode(101));
  }

  baseUWR102(): boolean {
    const floodCoverage = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    const floodAssistStatusCode = Number(this.entityRiskData.floodAssistStatusCode);
    const status = this.uwrHelpers.isHO3orHO4orHO6 && floodCoverage && floodAssistStatusCode === 9;

    return status;
  }

  baseUWR103(): boolean {
    const conditions = ['WSS', 'SST'];
    const exteriorWallFinish = this.propertyData.propertyDetails.get(
      'exteriorWallFinish'
    )?.value;

    const status = this.uwrHelpers.isHO3orDP3orHO6 &&
      exteriorWallFinish &&
      conditions.includes(exteriorWallFinish);

      return status;
  }

  baseUWR104(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 104; }).status;

    return status;
  }

  baseUWR105(): boolean {
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && (this.entityRiskData?.EntityRisk?.risks[0]?.riskDetails[0]?.riskProperties[0]?.isNoHitIso360ValueFirstCall === true);

    return status;
  }

  baseUWR106(): boolean {
    // Involves:
    // Policy Issue > Change Effective Date
    // Policy Effective Date
    const status = this.uwrHelpers.isHO3orHO4orHO6;
    return status;
  }

  baseUWR107(): boolean {
    // coverage c > 100,000 and HO4
    const baseUWR107CovC = 100000;
    const status = this.uwrHelpers.isHO4 && Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageC').value) > baseUWR107CovC;

    return status;
  }

  baseUWR108(): boolean {
    const status = false;

    return status;
  }

  baseUWR109(): boolean {
    // Dwell = Coverage A
    // Form Type = HO6
    // Condition = Coverage A > 300,000
    const dwell = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const baseUWR109CovA = 300000;
    const status = this.uwrHelpers.isHO6 && ((dwell > baseUWR109CovA));

    return status;
  }

  baseUWR110(): boolean {
    // Involves
    // UW-Questions > "Are there any excessive or unusual liability exposures on the property such as skateboard or bicycle ramps, empty swimming pools, etc?" is yes
    const areUnusualLiability = this.uwQuestionsData.eligibilityGroup.get('AreUnusualLiability')?.value ?? false;

    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && areUnusualLiability === true;

    return status;
  }

  baseUWR111(): boolean {
    // FOR DP3 Only
    // Involves
    // UW-Questions > "If the answer to the UW question "Is there a trampoline on the premises?" is yes
    const IsTrampPremis = this.uwQuestionsData.eligibilityGroup.get('IsTrampPremis')?.value ?? false;
    const status = this.uwrHelpers.isDP3 && IsTrampPremis;
    return status;
  }

  baseUWR112(): boolean {
    // FOR DP3 Only
    // Involves
    // UW-Questions > "Is there a swimming pool or hot tub on the premises?" is yes
    // UW-Questions > "Is there a diving board?" is yes
    const IsSwimHotTubPremis = this.uwQuestionsData.eligibilityGroup.get('IsSwimHotTubPremis')?.value ?? false;
    const IsDivBoard = this.uwQuestionsData.eligibilityGroup.get('IsDivBoard')?.value ?? false;
    const status = this.uwrHelpers.isDP3 && IsSwimHotTubPremis && IsDivBoard;

    return status;
  }

  baseUWR113(): boolean {
    // FOR DP3 Only
    // Involves
    // UW-Questions > "Is there a swimming pool or hot tub on the premises?" is yes
    // UW-Questions > "Is there a slide?" is yes
    const IsSwimHotTubPremis = this.uwQuestionsData.eligibilityGroup.get('IsSwimHotTubPremis')?.value ?? false;
    const IsSlide = this.uwQuestionsData.eligibilityGroup.get('IsSlide')?.value ?? false;
    const status = this.uwrHelpers.isDP3 && IsSwimHotTubPremis && IsSlide;

    return status;
  }

  baseUWR114(): boolean {
    // For DP3 Only
    // Involve
    // Property > County
    // Property > Yearbuilt
    // Endorsement > Limited Water Damage Coverage
    // Condition > Year built > 30 years old and county = Broward or Miami-Dade or Palm Beach or St Lucie with optional endorsement 'Limited Water Damage Coverage' attached .

    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - Number(yearBuilt) : null;

    const county = this.uwrHelpers.stringToLower(this.propertyData.locationDetails.get('county')?.value);
    const limitedWater = this?.endorsementsData?.endorsementsGroup?.get('limitedWater')?.value === false;

    const status = this.uwrHelpers.isDP3 && limitedWater && yearBuiltAge > 30 &&
                  (county === this.uwrHelpers.stringToLower(this.county.broward) ||
                    county === this.uwrHelpers.stringToLower(this.county.miamiDade) ||
                    county === this.uwrHelpers.stringToLower(this.county.palmBeach) ||
                    county === this.uwrHelpers.stringToLower(this.county.stLucie));

                    return status;
  }

  baseUWR115(): boolean {
    // FOR HO3 UWR only.
    // Involve > Property > YearBuilt
    //         > Endorsement > Water Damage Exclusion Endorsement.

    const currentDate = this.currentDate;
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - yearBuilt: null;
    const waterDamage = this.endorsementsData.endorsementsGroup.get('waterDamage')?.value;
    const status = this.uwrHelpers.isDP3 && yearBuiltAge > 30 && !waterDamage;

    return status;
  }

  baseUWR116(): boolean {
    //  UWR-116 Condition
    //    DP3 and Coverage A limit is greater than $750,000.
    //  Form-Type
    //    DP3

    // Involves
    // Quick quote > Coverage A
    const covA = Math.trunc(Number(this.coveragesData.coveragesAndDeductiblesForm.get('coverageA')?.value));
    const baseUWR116UpdateEffectiveDate = new Date(this.authService.uwr116UpdateEffectiveDate);
    const quoteEffectiveDate = new Date(this.uwrHelpers.riskEffectiveDate);

    const status = covA > 750000 &&
      (this.uwrHelpers.isDP3 || (this.uwrHelpers.isHO3 && quoteEffectiveDate >= baseUWR116UpdateEffectiveDate));

    return status;
  }

  baseUWR117(): boolean {
    // FOR Dp3 Only
    // Involve
    // Endorsement > Permitted Incidental Occupancies
    // Endorsement > Permitted Incidental Occupancies - Liability
    // Condition > If agent adds "Permitted Incidental Occupancies" and/or "Permitted Incidental Occupancies - Liability" optional endorsement(s)

    const isExternalUser = this.authService.userType.value === this.genericConstants.userType.external;
    const permittedIncidenal = this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupancies')?.value || false;
    const permittedIncidentalOccupanciesLiability = this.endorsementsData.endorsementsGroup.get('permittedIncidentalOccupanciesLiability')?.value || false;
    let status = false;

    status = permittedIncidenal || permittedIncidentalOccupanciesLiability;

    if (status) {
      if (this.endorsementsData.isPermittedIncidentalOccupanciesChanged) {
        if (!isExternalUser) {
          status = false;
        }
      } else {
        const uwr117Description = this.uwrHelpers.getUwrDescription(this.lvUWApprovalCodeConstants['uwr117']);
        const hasUWR117 = this.uwApprovalData.uwApprovalList.includes(uwr117Description);

        status = hasUWR117;
      }
    }

    status = status && this.uwrHelpers.isDP3;

    return status;
  }

  baseUWR118(): boolean {
    //  UWR-118 Condition
    //    Mitigation form = something other than 'no form'
    //  Form-Type
    //    HO3, DP3, HO6

    // Involves
    // Property > Wind Mitigation > Mitigation Form
    const hurricaneDeductible = this.coveragesData.coveragesAndDeductiblesForm.get('hurricaneDeductibleid')?.value;
    const mitigationForm = this.propertyData.windMitigation.get('mitigationForm').value;
    let status = false;

    if (hurricaneDeductible !== 'E') {
      status = this.uwrHelpers.isHO3orDP3orHO6 && mitigationForm !== 'NF';
    }

    return status;
  }

  baseUWR119(): boolean {
    //  UWR-119 Condition
    //    Year of construction is greater than 40.
    //  Form-Type
    //    HO3, DP3

    // Involves
    // Property > Property Details > Yearbuilt
    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);
    const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;

    const status = this.uwrHelpers.isHO3orDP3 && yearBuiltAge > 40;

    return status;
  }

  baseUWR120(): boolean {
    // Involves
    // Coverages > Discounts > Sprinkler
    const sprinklerCondition = ['FU', 'PA'];
    const sprinkler = this.coveragesData.discountsForm.get('sprinklerId')?.value;

    const status = this.uwrHelpers.isHO3orDP3orHO6 && sprinklerCondition.includes(sprinkler);

    return status;
  }

  baseUWR121(): boolean {
    // Involves
    // Coverages > Coverages > Hurricane Deductible

    const hurricaneDeductible = this.coveragesData.coveragesAndDeductiblesForm.get(
      'hurricaneDeductibleid'
    )?.value;
    const baseUWR121UpdateEffectiveDate = new Date(this.authService.uwr121UpdateEffectiveDate);
    const quoteEffectiveDate = new Date(this.uwrHelpers.riskEffectiveDate);

    const status = (this.uwrHelpers.isHO3orDP3 && hurricaneDeductible === 'E') ||
      (this.uwrHelpers.isHO4orHO6 &&
        hurricaneDeductible === 'E' &&
        quoteEffectiveDate >= baseUWR121UpdateEffectiveDate);

    return status;
  }

  baseUWR122(): boolean {
    //  UWR-122 Condition
    //    Coverage C - Personal Limit = excluded
    //  Form-Type
    //    HO3

    // Involves
    // Coverages > Coverages > Coverage C - Personal Property Limit
    const covC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageCLimitId')?.value;
    const coverageC = this.coveragesData.coveragesAndDeductiblesForm.get('coverageC')?.value;

    const statusHO3 = this.uwrHelpers.isHO3 && Math.trunc(Number(covC)) === 0;

    const status = statusHO3;

    return status;
  }

  baseUWR123(): boolean {
    return;
  }

  baseUWR124(): boolean {
    //  UWR-124 Condition
    //    If midterm increase to Coverage A is 20% or more of Coverage A.
    //  Form-Type
    //    HO3, DP3

    // For midterm and renewal endorsement only.
    const status = this.uwrHelpers.isHO3orDP3 && this.uwrHelpers.isPolicyOrValidForRenewal;

    return status;
  }

  baseUWR125(): boolean {
    //  UWR-125 Condtion
    //    If midterm decrease to Coverage A (any decrease)
    //  Form-Type
    //    HO3, DP3

    // For midterm and renewal endorsement only
    const status = this.uwrHelpers.isHO3orDP3 && this.uwrHelpers.isPolicyOrValidForRenewal;

    return status;
  }

  baseUWR126(): boolean {
    //  UWR-126 Condition
    //    If Consent to Rate is 'yes' on policy regardless of additional premium limit.
    //  Form-Type
    //    HO3, DP3

    // For midterm and renewal endorsement only
    const status = this.uwrHelpers.isHO3orDP3 && this.uwrHelpers.isPolicyOrValidForRenewal;

    return status;
  }

  baseUWR127(): boolean {
    //  UWR-127 Condition
    //    If any deductible (AOP, wind/hail, hurricane, non-hurricane wind) is lowered.
    //  Form-Type
    //    HO3, HO4, HO6, DP3

    // For midterm endorsement only
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && this.uwrHelpers.isPolicyOrValidForRenewal;

    return status;
  }

  baseUWR128(): boolean {
    //  UWR-128 Condition
    //    If wind was excluded voluntarily (not per UWR-2) and agent attempts to add wind back to policy.
    //  Form-Type
    //    HO3, HO4, HO6, DP3

    // For midtern endorsement only
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 128; }).status;

    return status;
  }

  baseUWR129(): boolean {
    //  UWR-129 Condition
    //    If agent processes change to named insured.
    //  Form-Type
    //    HO3, HO4, HO6, DP3

    // For midtern endorsement only
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 129; }).status;

    return status;
  }

  baseUWR130(): boolean {
    // UWR-130 Condition
    //    Year built more than 15 years old and optional endorsement 'Limited Water Damage Coverage' is not attached (where it is attached)
    // Form-Type
    //    HO3, DP3, HO6
    const yearBuilt = this.propertyData.propertyDetails.get('yearBuilt')?.value;
    const yearBuiltAge = yearBuilt ? this.uwrHelpers.riskEffectiveDateYear - Number(yearBuilt) : null;
    const limitedWater = this.endorsementsData.endorsementsGroup.get('limitedWater')?.value;
    const waterDamage = this.endorsementsData.endorsementsGroup.get('waterDamage')?.value;

    const status = this.uwrHelpers.isHO3orDP3orHO6 && yearBuiltAge > 15 && !limitedWater && !waterDamage;

    return status;
  }

  baseUWR131(): boolean {
    const status = this.uwrHelpers.asyncUWRs.find(uwr => { return uwr.id === 131; }).status;

    return status;
  }

  checkUWR131FromUWRApprovalTable(): void {
    // Check if 131 is on the Approval Table and is already Approved
    // if approved, remove and insert a new one with 'Pending' status
    // else, do nothing
    const desc = this.uwrHelpers.getUwrDescription(this.lvUWApprovalCodeConstants['uwr131']);
    const indexToRemove = this.uwApprovalData.uwApprovalList.indexOf(desc);
    let isUWR131OnUWRApprovalTable = false;

    this.uwApprovalData.uwApprovalTableRows.forEach((item: ITableTr, index) => {
      if (item.tr[0].value === desc) {
        if (item.tr[1].value === 'Approve' ||
            item.tr[1].value === 'AIR' ||
            item.tr[1].value === 'Decline') {
          isUWR131OnUWRApprovalTable = true;
        }
      }
    });

    if (isUWR131OnUWRApprovalTable) {
      this.uwApprovalData.uwApprovalList.splice(indexToRemove, 1);
      this.uwrHelpers.removeFromTableRows(desc);
    }
  }

  baseUWR132(): boolean {
    // UWR-131 condition
    //    If usage is short-term rental OR any other usage besides unoccupied or vacant, AND the residence is rented.
    //    Residence is considered rented if any of the following are answered yes:
    //      1. Is the residence rented ? question on Property tab or
    //      2. Is the home ever rented ? on UW Question tab or
    //      3. Are there any rooms, units, or other structures rented or held for rental at this residence ? on UW Question tab.
    //  Form-Type
    //    HO3, DP3, HO6
    const VACANT_UNOCCUPIED_CODE = ['UOCC', 'VCN'];
    const shortTermRentalCode = 'STR';
    const propertyUsageCode = this.propertyData.locationDetails.get('propertyUsage').value;
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented')?.value ?? false;
    const IsEverRented = this.uwQuestionsData.eligibilityGroup.get('IsEverRented')?.value ?? false;
    const isForRent = this.uwQuestionsData.eligibilityGroup.get('IsForRent')?.value ?? false;

    const status = this.uwrHelpers.isHO3orDP3orHO6 &&
                  propertyUsageCode !== null &&
                  (propertyUsageCode === shortTermRentalCode ||
                  (!VACANT_UNOCCUPIED_CODE.includes(propertyUsageCode) && (isResidenceRented || IsEverRented || isForRent)));

    return status;
  }

  baseUWR133(): boolean {
    // UWR-132 condition
    //    If usage is short-term rental OR any other usage besides unoccupied or vacant, AND the residence is rented.
    //    Residence is considered rented if any of the following are answered yes:
    //      1. Is the residence rented ? question on Property tab or
    //      2. Is the home ever rented ? on UW Question tab or
    //      3. Are there any rooms, units, or other structures rented or held for rental at this residence ? on UW Question tab.
    //  Form-Type
    //    HO3, DP3, HO6
    const VACANT_UNOCCUPIED_CODE = ['UOCC', 'VCN'];
    const shortTermRentalCode = 'STR';
    const propertyUsageCode = this.propertyData.locationDetails.get('propertyUsage').value;
    const isResidenceRented = this.propertyData.locationDetails.get('isResidenceRented')?.value ?? false;
    const IsEverRented = this.uwQuestionsData.eligibilityGroup.get('IsEverRented')?.value ?? false;
    const isForRent = this.uwQuestionsData.eligibilityGroup.get('IsForRent')?.value ?? false;

    const status = this.uwrHelpers.isHO3orDP3orHO6 &&
                    propertyUsageCode !== null &&
                    (propertyUsageCode === shortTermRentalCode ||
                    (!VACANT_UNOCCUPIED_CODE.includes(propertyUsageCode) && (isResidenceRented || IsEverRented || isForRent)));

    return status;
  }

  // Comment this code as per ticket CNTSUP-997 UWR 139 has been updated based on latest UWR List.
  // baseUWR139(): boolean {
  //   // UWR-139 condition
  //   // 1. Year built 15 or more years old AND
  //   // 2. Endorsement 'Roof Surfacing Payment Schedule' is not attached (where it is attached, no requirement to refer).
  //   // Form-Type: HO3, DP3

  //   const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);
  //   const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;
  //   const roofSurfacingPaymentSchedule = this.endorsementsData.endorsementsGroup.get('roofSurfacingPaymentSchedule')?.value;
  //   const countDateDiffBy = 'days';
  //   const isDateValidForRSPS = moment(this.uwrHelpers.riskEffectiveDate).diff(this.uwrHelpers.rspsStartDate, countDateDiffBy) >= 0;

  //   const status = this.uwrHelpers.isHO3orDP3 && isDateValidForRSPS && yearBuiltAge >= 15 && !roofSurfacingPaymentSchedule;

  //   return status;
  // }

  baseUWR134(): boolean {
    return false;
  }

  baseUWR135(): boolean {
    return false;
  }

  baseUWR136(): boolean {
    return false;
  }

  baseUWR137(): boolean {
    return false;
  }

  baseUWR138(): boolean {
    return false;
  }

  baseUWR139(): boolean {
    // UWR-139 condition
    //    1. Roof age ten(10) or more years old refers prior to binding
    // Form-Type:
    //    HO3, DP3

    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;
    const yearRoofAge = this.uwrHelpers.riskEffectiveDateYear - yearRoof;

    const status = this.uwrHelpers.isHO3orDP3 && yearRoofAge >= 10;

    return status;
  }

  baseUWR140(): boolean {
    // UWR-140 condition
    // 1. Roof Surfacing Payment Schedule is on the policy AND
    // 2. Agent enters policy change to remove
    // Form-Type: DP3

    let status = false;
    const roofSurfacingPaymentSchedule = this.endorsementsData.endorsementsGroup.get('roofSurfacingPaymentSchedule')?.value;
    const isExternalUser = this.authService.userType.value === this.genericConstants.userType.external;
    const countDateDiffBy = 'days';
    const isDateValidForRSPS = moment(this.uwrHelpers.riskEffectiveDate).diff(this.uwrHelpers.rspsStartDate, countDateDiffBy) >= 0;

    status = !roofSurfacingPaymentSchedule;

    if (status) {
      if (this.endorsementsData.roofSurfacingPaymentScheduleChanged) {
        if (!isExternalUser) {
          status = false;
        }
      } else {
        const uwr140Description = this.uwrHelpers.getUwrDescription(this.lvUWApprovalCodeConstants['uwr140']);
        const hasUWR140 = this.uwApprovalData.uwApprovalList.includes(uwr140Description);

        status = hasUWR140;
      }
    }

    status = this.uwrHelpers.isPolicyOrValidForRenewal && isDateValidForRSPS && this.uwrHelpers.isDP3 && status;

    return status;
  }

  baseUWR141(): boolean {
    return false;
  }

  baseUWR142(): boolean {
    return false;
  }

  baseUWR143(): boolean {
    return false;
  }

  baseUWR144(): boolean {
    return false;
  }

  baseUWR145(): boolean {
    return false;
  }

  baseUWR146(): boolean {
    return false;
  }

  baseUWR147(): boolean {
    return false;
  }

  baseUWR148(): boolean {
    return false;
  }

  baseUWR149(): boolean {
    return false;
  }

  baseUWR150(): boolean {
    return false;
  }

  baseUWR151(): boolean {
    // UWR-151 condition
    //    1. Year built twenty (20) or more years old refers prior to binding
    // Form-Type:
    //    HO3, DP3, HO6

    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);
    const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;

    const status = this.uwrHelpers.isHO3orDP3orHO6 && yearBuiltAge >= 20;

    return status;
  }

  baseUWR152(): boolean {
    // UWR-152 condition
    //    1. Roof age less than 10 years old refers prior to binding
    // Form-Type:
    //    HO3, DP3, HO6

    // CNTMAIN-275 - CENTAURI-12748
    if (this.uwrHelpers.checkIsUWRTurnOffWithEffectiveDate(this.authService.turnOffUWR152EffectiveDate, this.uwrHelpers.riskEffectiveDate)) {
      return false;
    }

    const yearRoof = this.propertyData.propertyDetails.get('yearRoof')?.value;
    const yearRoofAge = this.uwrHelpers.riskEffectiveDateYear - yearRoof;

    const status = this.uwrHelpers.isHO3orDP3 && yearRoofAge < 10;

    return status;
  }

  baseUWR153(): boolean {
    return false;
  }

  baseUWR154(): boolean {
    // UWR-154 condition
    // 1. Year built 15 or more years old AND
    // 2. Endorsement ‘Roof Surfacing Payment Schedule’ is not attached (where it is attached, no requirement to refer)
    // Form-Type: DP3

    const yearBuilt = Number(this.propertyData.propertyDetails.get('yearBuilt')?.value);
    const yearBuiltAge = this.uwrHelpers.riskEffectiveDateYear - yearBuilt;
    const roofSurfacingPaymentSchedule = this.endorsementsData.endorsementsGroup.get('roofSurfacingPaymentSchedule')?.value;
    const countDateDiffBy = 'days';
    const isDateValidForRSPS = moment(this.uwrHelpers.riskEffectiveDate).diff(this.uwrHelpers.rspsStartDate, countDateDiffBy) >= 0;

    const status = this.uwrHelpers.isDP3 && yearBuiltAge >= 15 && !roofSurfacingPaymentSchedule && isDateValidForRSPS;

    return status;
  }

  baseUWR155(): boolean {
    return false;
  }

  baseUWR156(): boolean {
    return false;
  }

  baseUWR157(): boolean {
    return false;
  }

  baseUWR158(): boolean {
    return false;
  }

  baseUWR159(): boolean {
    return false;
  }

  baseUWR160(): boolean {
    return false;
  }

  baseUWR161(): boolean {
    return false;
  }

  baseUWR162(): boolean {
    return false;
  }

  baseUWR163(): boolean {
    return false;
  }

  baseUWR164(): boolean {
    return false;
  }

  baseUWR165(): boolean {
    return false;
  }

  baseUWR166(): boolean {
    return false;
  }

  baseUWR167(): boolean {
    return false;
  }

  baseUWR168(): boolean {
    return false;
  }

  baseUWR169(): boolean {
    return false;
  }

  baseUWR170(): boolean {
    return false;
  }

  baseUWR171(): boolean {
    return false;
  }

  baseUWR172(): boolean {
    return false;
  }

  baseUWR173(): boolean {
    return false;
  }

  baseUWR174(): boolean {
    return false;
  }

  baseUWR175(): boolean {
    return false;
  }

  baseUWR176(): boolean {
    return false;
  }

  baseUWR177(): boolean {
    return false;
  }

  baseUWR178(): boolean {
    return false;
  }

  baseUWR179(): boolean {
    return false;
  }

  baseUWR180(): boolean {
    return false;
  }

  baseUWR181(): boolean {
    return false;
  }

  baseUWR182(): boolean {
    return false;
  }

  baseUWR183(): boolean {
    return false;
  }

  baseUWR184(): boolean {
    return false;
  }

  baseUWR185(): boolean {
    return false;
  }

  baseUWR186(): boolean {
    return false;
  }

  baseUWR187(): boolean {
    const isProtectionClassFromGenzip = this.thirdPartyData?.dataFromAPIResponse?.isoLocationPPCReturnSource?.toUpperCase() === LocationLabelConstants.genzip;
    const status: boolean = this.uwrHelpers.isHO3orDP3orHO4orHO6 && isProtectionClassFromGenzip;

    return status;
  }

  baseUWR188(): boolean {
    const isWindPoolFromGenzip = this.thirdPartyData?.dataFromAPIResponse?.windPoolEligibleReturnSource?.toUpperCase() === LocationLabelConstants.genzip;
    const status: boolean = this.uwrHelpers.isHO3orDP3orHO4orHO6 && isWindPoolFromGenzip;

    return status;
  }

  baseUWR189(): boolean {
    const isDistanceToCoastFromGenzip = this.thirdPartyData?.dataFromAPIResponse?.distanceToCoastReturnSource?.toUpperCase() === LocationLabelConstants.genzip;
    const status: boolean = this.uwrHelpers.isHO3orDP3orHO4orHO6 && isDistanceToCoastFromGenzip;

    return status;
  }
}
