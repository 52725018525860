import { Injectable } from '@angular/core';
import {
  LvUWApprovalCodeConstants,
  LvUWApprovals,
} from 'app/shared/constants/uw.referrals';
import { BaseClass } from 'app/shared/base-class';
import { CoveragesData } from 'app/modules/submission-management/data/coverages.data';
import { SummaryData } from 'app/modules/submission-management/data/summary.data';
import { EndorsementsData } from 'app/modules/submission-management/data/endorsements.data';
import { UwApprovalData } from 'app/modules/submission-management/data/uw-approval.data';
import * as moment from 'moment';
import { FloodZipCodesWatchList } from 'app/shared/constants/watch-list.zipcode.list';
import * as _ from 'lodash';
import { ThirdPartyData } from 'app/modules/submission-management/data/third-party.data';
import { EntityRiskData } from 'app/modules/submission-management/data/entity-risk.data';
import { PolicySummaryData } from 'app/modules/policy-management/data/policy-summary.data';
import { distinctUntilChanged, takeUntil, switchMap, filter, take, delay } from 'rxjs/operators';
import { PolicyIssueData } from 'app/modules/policy-management/data/policy-issue.data';
import { RiskDetailsDTO } from 'app/shared/models/data/dto/quick-quote/risk-details.dto';
import { AuthService } from '../../auth.service';
import { SubmissionPageData } from 'app/modules/submission-management/data/submission-page.data';
import { RiskStatusConstants } from 'app/shared/constants/risk-status';
import { GenericConstants } from '../../../../shared/constants/generic.constants';
import { RenewalStatusCode } from 'app/shared/models/data/dto/submission-list/entity-submission-list.dto';
import { MoratoriumType, MoratoriumType2, MoratoriumTypeCode } from 'app/shared/constants/moratoriumType';
import { PolicyUWR } from './policy-uwr.service';
import { RenewalUWR } from './renewal-uwr.service';
import { BaseUWR } from './base-uwr.service';
import { UWRHelpers } from './uwr-helpers.service';
import { AsyncBaseUWR } from './async-base-uwr.service';
import { AsyncPolicyUWR } from './async-policy-uwr.service';
import { AsyncRenewalUWR } from './async-renewal-uwr.service';
import { Subject } from 'rxjs';
interface formType {
  ho3: string;
  ho4: string;
  ho6: string;
  dp3: string;
}
@Injectable({
  providedIn: 'root',
})
export class UWR extends BaseClass {
  // TODO: Fetch LvUWApprovals value on API and store to this variable
  LvUWApprovals = LvUWApprovals;

  LvUWApprovalCodeConstants = LvUWApprovalCodeConstants;
  county: any = {
    broward: 'Broward',
    palmBeach: 'Palm Beach',
    stLucie: 'St Lucie',
    miamiDade: 'Miami-Dade',
  };
  formType: formType = {
    ho3: 'HO3',
    ho4: 'HO4',
    ho6: 'HO6',
    dp3: 'DP3'
  };
  uwr9CountyList: string[] = [
    'miami-dade', 'broward', 'palm beach', 'osceola', 'orange'
  ];
  uwr9minYearBuilt = 2007;
  floodZipCodesWatchList = FloodZipCodesWatchList;
  hasUWR106: boolean = false;
  saveCoverages: boolean = true;
  excludedUrwList: number[] = [13, 106, 123, 134, 135, 136, 137];
  isRenewalSubmission: boolean = false;
  riskStatusConstants = RiskStatusConstants;
  midtermEndoUWRList: number[] = [2, 3, 4, 5, 9, 14, 16, 17,22,
    23, 25, 26, 27, 30, 37, 39, 40, 42, 43, 47, 58,
    75, 77, 82, 85, 88, 87, 89, 90, 93, 94, 95, 96, 100, 102,
    103, 106, 107, 109, 114, 115, 116, 117, 118, 120, 121, 122,
    124, 125, 127, 128, 129, 131, 132, 133, 140];
  specialMidtermEndoUWRList: number[] = [86, 93, 106, 123, 126];
  uwrPopulated$ = new Subject();
  clearUnexpecteUWRs$ = new Subject();

  constructor(
    protected coveragesData: CoveragesData,
    protected summaryData: SummaryData,
    protected endorsementsData: EndorsementsData,
    protected uwApprovalData: UwApprovalData,
    protected thirdPartyData: ThirdPartyData,
    protected entityRiskData: EntityRiskData,
    public policySummaryData: PolicySummaryData,
    protected policyIssueData: PolicyIssueData,
    protected authService: AuthService,
    protected submissionPageData: SubmissionPageData,
    protected baseUWR: BaseUWR,
    protected policyUWR: PolicyUWR,
    protected renewalUWR: RenewalUWR,
    public uwrHelpers: UWRHelpers,
    protected asyncBaseUWR: AsyncBaseUWR,
    protected asyncPolicyUWR: AsyncPolicyUWR,
    protected asyncRenewalUWR: AsyncRenewalUWR
  ) {
    super();
    this.uwr106Subscriber();

    this.subscribeToUWRPopulated$();
    this.subscribeToGetAsyncUWRs$();
    this.subscribeToClearUnexpecteUWRs$();
  }

  getUwrReferrals(saveCoverages: boolean = true): void {
    this.saveCoverages = saveCoverages;

    if (!this.uwrHelpers.isPolicy) {
      if (this.policySummaryData.renewalStatusCode === null) {
        this.initUwrs();
      }

      this.uwrHelpers.checkUwrChangesList();
    }
  }

  initUwrs(): void {
    let counter = 1;
    const specialBaseUWRList: number[] = [93];

    this.isRenewalSubmission = false;
    this.uwApprovalData.uwApprovalListAddedOnNav = [];
    this.uwApprovalData.uwApprovalListRemovedOnNav = [];

    for (counter; counter <= this.uwrHelpers.uwrLastCount; counter++) {
      if (
        !this.excludedUrwList.includes(counter) &&
        counter !== 101 &&
        !this.uwrHelpers.isPolicyOrValidForRenewal &&
        !this.uwrHelpers.isRenewalPendingOrRevisedRenewalPending
      ) {
        if (!specialBaseUWRList.includes(counter)) {
          this.getBaseUWR(counter);
        } else {
          this.baseUWR[`baseUWR${ counter }`]();
        }
      } else {
        this.uwrHelpers.updateUwrList(false, this.uwrHelpers.getUwrCode(counter));
      }
    }

    this.getFireRefferal();
    this.checkMoratoriumReferrals();
  }

  initRenewalUWRs(): void {
    const uwrList: number[] = [...this.midtermEndoUWRList];
    const specialUWRList: number[] = [...this.specialMidtermEndoUWRList];
    let counter = 1;

    this.isRenewalSubmission = true;
    this.uwApprovalData.uwApprovalListAddedOnNav = [];
    this.uwApprovalData.uwApprovalListRemovedOnNav = [];

    for (counter; counter <= this.uwrHelpers.uwrLastCount; counter++) {
      if (uwrList.includes(counter) && this.uwrHelpers.isValidForRenewalUWR) {
        if (!specialUWRList.includes(counter)) {
          this.getRenewalUWR(counter);
        } else {
          this[`renewalUWR${ counter }`]();
        }
      } else {
        this.uwrHelpers.updateUwrList(false, this.uwrHelpers.getUwrCode(counter));
      }
    }

    this.getMoratoriumReferral();
  }

  getPolicyUwrReferrals(): void {
    const uwrList: number[] = [...this.midtermEndoUWRList];
    const specialUWRList: number[] = [...this.specialMidtermEndoUWRList];

    if (this.parentRiskDetail && this.summaryData.isFormTypeLoaded.value) {
      let counter = 0;

      _.forEach(uwrList, (data, index) => {
        counter = uwrList[index];

        if (!specialUWRList.includes(counter)) {
          this.getPolicyUWR(counter);
        } else {
          this[`policyUWR${ counter }`]();
        }
      });

      this.getPolicyFireReferral();
      this.getMoratoriumReferral();
      this.removeUnnecessaryUwrs();
    }
  }

  removeUnnecessaryUwrs(): void {
    let counter = 1;

    for (counter; counter <= this.uwrHelpers.uwrLastCount; counter++) {
      if (
        !this.midtermEndoUWRList.includes(counter) &&
        !this.specialMidtermEndoUWRList.includes(counter)
      ) {
        this.uwrHelpers.policyRemoveFromTableRow(`uwr${ counter }`);
      }
    }
  }

  getBaseUWR(uwrNumber: number): void {
    this.uwrHelpers.updateUwrList(this.baseUWR[`baseUWR${ uwrNumber }`](), this.uwrHelpers.getUwrCode(uwrNumber));
  }

  getPolicyUWR(uwrNumber: number): void {
    if (this.policyUWR[`policyUWR${ uwrNumber }`]()) {
      this.getBaseUWR(uwrNumber);
    } else {
      this.uwrHelpers.policyRemoveFromTableRow(`uwr${ uwrNumber }`);
    }
  }

  getRenewalUWR(uwrNumber: number): void {
    if (this.renewalUWR[`renewalUWR${ uwrNumber }`]()) {
      this.getBaseUWR(uwrNumber);
    } else {
      this.uwrHelpers.renewalRemoveFromTableRow(`uwr${ uwrNumber }`);
    }
  }

  get parentRiskDetail(): RiskDetailsDTO {
    return this.uwrHelpers.isValidForRenewalUWR ? this.entityRiskData.renewalBackup : this.entityRiskData?.ParentBackUpRiskData;
  }

  getFireRefferal(): void {
    // Involves
    // Coverages > Discounts > Fire
    const fireProtection = this.coveragesData.discountsForm.get('fireId')?.value;
    const status = this.uwrHelpers.isHO3orDP3orHO4orHO6 && fireProtection === '1';

    this.uwrHelpers.updateUwrList(status, 'fireReferralCode');
  }

  policyUWR86(): void {
    // TEMPORARILY Comment this code, as per business UWR86: turn this off until the copy feature is turned back on

    // const parentPriorInsuranceCarrier = this?.ParentRiskDetail?.riskInterests[0]?.priorCarrierCode;
    // const priorInsuranceCarrier = this?.interestData?.priorInsuranceForm?.get('priorCarrier')?.value;
    // const status = parentPriorInsuranceCarrier !== priorInsuranceCarrier;

    // if (status) {
    //   this.uwr86();
    // } else {
    //   this.uwrHelpers.policyRemoveFromTableRow('uwr86');
    // }
  }

  policyUWR93(): void {
    // Involves Updates in:
    // 1. Coverages > Schedules > Add Schedules
    // 2. Coverages > Schedules > Schedule Individual Item (Note a field but a value)

    const addSchedules = this.coveragesData.schedulesForm.get('addSchedules')?.value;
    this.coveragesData.scheduleTableNames.forEach((scheduleName) => {
      let status = false;
      const uwrDescription = `Refer: ${ this.uwrHelpers.getSppName(scheduleName) } limit > $10,000 requires prior UW approval`;

      if(addSchedules) {
        const parentScheduleRowData = this.parentRiskDetail?.riskSchedules;
        const childScheduleStatus = this.coveragesData.schedulesForm.get(scheduleName).value;
        const childScheduleRowData = parentScheduleRowData?.filter(a => a.tableId === scheduleName);
        const isChildTableUpdate = this.coveragesData.policyCompareParentScheduleTableRowData(scheduleName, childScheduleRowData, true);
        status = childScheduleStatus && isChildTableUpdate && this.uwrHelpers.isHO3orHO4orHO6;
      }

      this.uwrHelpers.updateUwrList(
        status,
        this.uwrHelpers.getUwrCode(93),
        uwrDescription
      );
    });
  }

  policyUWR106(): void {
    // Involves:
    // Policy Issue > Change Effective Date
    // Policy Effective Date
    // this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
    //   this.checkUWR106();
    // });
  }

  policyUWR123(): void {
    // TODO: Identify UWR 123
  }

  policyUWR126(): void {
    // TODO: This is midterm renewal policy Endorsement
    const parentConsentToRate = this.parentRiskDetail?.riskEndorsements[0]?.isConsentToRate ?? false;
    const parentConsetToRateLimitAmount = parentConsentToRate ? this.parentRiskDetail?.riskEndorsements[0]?.consentToRateAmount : 0;

    const currentConsentToRate = this.endorsementsData.endorsementsGroup.get('consentToRate')?.value ?? false;
    const currentConsentToRateLimitAmount = currentConsentToRate ? this.endorsementsData.endorsementsGroup.get('consentToRateAmount') : 0;
  }

  getPolicyFireReferral(): void {
    // Involves
    // Coverages > Discounts > Fire
    const parentFireProtection = this.parentRiskDetail?.riskDiscounts[0].fireCode;
    const fireProtection = this.coveragesData.discountsForm.get('fireId')?.value;
    const status = parentFireProtection !== fireProtection;
    if (status) {
      this.getFireRefferal();
    } else {
      this.uwrHelpers.policyRemoveFromTableRow('fireReferralCode');
    }
  }

  checkMoratoriumReferrals(): void {
    if (this.uwrHelpers.isValidForRenewalUWR && this.uwrHelpers.isPolicy) {
      this.getMoratoriumReferral();
      return;
    }
    const mor6Description = 'MOR-6';
    const hasMor6 = this.entityRiskData.moratoriumResponse?.find(moratorium => moratorium.description === mor6Description) ?? false;
    const status = !this.uwrHelpers.isPolicy && this.entityRiskData.moratoriumResponse?.length === 1;
    if (status) {
      this.getMor6();
      this.uwrHelpers.updateUwrList(false, 'moratoriumCode');
      if (!hasMor6) {
        this.getMoratoriumReferral();
      }
    } else {
      this.getMoratoriumReferral();
      this.getMor6();
    }
  }

  getMoratoriumReferral(): void {
    const status = this.entityRiskData.moratoriumResponse && this.entityRiskData.moratoriumResponse.length > 0;
    const isNullRenewalStatusCode = this.policySummaryData.renewalStatusCode == null;
    const isRenewalOffered = this.policySummaryData.renewalStatusCode === 'RWO' as RenewalStatusCode ||
      this.policySummaryData.renewalStatusCode === 'RRWO' as RenewalStatusCode; // Renewal Offer or Revised Renewal Offer

    if (this.uwrHelpers.isValidForRenewalUWR) {
      return;
    }

    if (status && (isNullRenewalStatusCode || isRenewalOffered)) {
      _.forEach(this.entityRiskData.moratoriumResponse, moratorium => {
        const isClosure = moratorium.type.toLocaleLowerCase() === MoratoriumType.closure.toLocaleLowerCase();
        if (moratorium.type.toLowerCase() === MoratoriumType.coverageMoratorium.toLowerCase() &&
            moratorium.type2.toLowerCase() === MoratoriumType2.floodCoverage.toLocaleLowerCase()) {
          this.initCoverageFloodMoratoriumUWR(status, moratorium.description);
        } else {
          if (this.uwrHelpers.isPolicy && this.uwrHelpers.isEditPolicy && !isClosure) {
            if (moratorium.description === 'MOR-6') {
              this.getMor6();
            } else {
              this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.moratoriumCode, moratorium.popUpMessage);
            }
          } else if (!this.uwrHelpers.isPolicy) {
            if (isClosure) {
              this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.closureUWCode, moratorium.popUpMessage);
              this.uwrHelpers.updateUwrList(false, MoratoriumTypeCode.moratoriumCode);
            } else {
              this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.moratoriumCode, moratorium.popUpMessage, true);
              this.uwrHelpers.updateUwrList(false, MoratoriumTypeCode.closureCode);
              this.uwrHelpers.updateUwrList(false, MoratoriumTypeCode.closureUWCode);
            }
          }
          this.removeInactiveMoratoriumUWR();
        }
      });
    } else {
      this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.moratoriumCode);
      this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.closureCode);
      this.uwrHelpers.updateUwrList(status, MoratoriumTypeCode.closureUWCode);
      this.removeInactiveMoratoriumUWR();
    }
  }

  getMorCode(morNo: number): string {
    return `mor${morNo.toString()}`;
  }

  getMor6(): void {
    const mor6Code = this.getMorCode(6);
    const mor6Description = 'MOR-6';
    const status = this.entityRiskData.moratoriumResponse?.find(moratorium => moratorium.description === mor6Description);

    if (status) {
      this.uwrHelpers.updateUwrList(true, mor6Code);
    } else {
      this.uwrHelpers.updateUwrList(false, mor6Code);
    }
  }

  checkUWR106(): void {
    let status = false;
    const effectiveDate = new Date(this.parentRiskDetail?.effectiveDate);
    const expirationDate = new Date(effectiveDate);
    expirationDate.setFullYear(effectiveDate.getFullYear() + 1);

    const policyChangeEffectiveDate = this.policyIssueData.issuePolicyForm.get('changeEffectiveDate')?.value;

    if (policyChangeEffectiveDate) {
      const daysBeforePolicyExpirationDate = 60;
      const changeEffectiveDate = new Date(policyChangeEffectiveDate?.singleDate?.jsDate);
      const cntrExpirationDate = new Date(expirationDate);
      const prior60DaysExpirationDate = new Date(cntrExpirationDate.setDate(cntrExpirationDate.getDate() - daysBeforePolicyExpirationDate));

      const policyExpirationDate = moment(this.entityRiskData.EntityRisk.risks[0].firstIssueDate).add(1, 'y').format('MM/DD/YYYY');
      const currentDate = moment(this.authService.getCustomDate()).format('MM/DD/YYYY');
      const daysBeforeExpirationDate = moment(policyExpirationDate).diff(currentDate, 'days');

      if (effectiveDate && changeEffectiveDate) {
        status = ((changeEffectiveDate >= prior60DaysExpirationDate) && (changeEffectiveDate <= expirationDate)) ||
          (this.submissionPageData.policyStatus.value !== this.riskStatusConstants.draft && daysBeforeExpirationDate <= daysBeforePolicyExpirationDate);
      }

      this.updateUWR106(this.policyIssueData.policyChangesLength > 0 && status);
    } else {
      this.updateUWR106(false);
    }
  }

  updateUWR106(status: boolean): void {
    if (status) {
      this.uwrHelpers.updateUwrList(status, this.uwrHelpers.getUwrCode(106));
      this.hasUWR106 = true;
    } else {
      this.uwrHelpers.policyRemoveFromTableRow('uwr106');
      this.hasUWR106 = false;
    }
  }

  uwr106Subscriber(): void {
    if (this.policySummaryData.isPolicy) {
      this.entityRiskData.isDonePopulatingForms$.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
        this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
          this.checkUWR106();
        });
      });
    }
  }

  initCoverageFloodMoratoriumUWR(status: boolean, moratoriumDescription: string): void {
    const isPrivateFlood = this.coveragesData.floodForm.get('privateFloodCoverage')?.value;
    let isCoverageFloodUWR = status && isPrivateFlood && moratoriumDescription === 'FloodCoverage';

    if (this.uwrHelpers.isPolicy && isPrivateFlood) {
      const parentRiskFloodCovA = this.parentRiskDetail?.riskFloods[0].floodCovA;
      const parentRiskFloodCovC = this.parentRiskDetail?.riskFloods[0].floodCovC;
      const parentRiskFloodDeductible = this.parentRiskDetail?.riskFloods[0].floodDeductibleCode;
      const parentParticipationStatus = this.parentRiskDetail?.riskFloods[0].participationStatusCode;
      const parentFirmDate = new Date(this.parentRiskDetail?.riskFloods[0]?.firmDate).getFullYear();
      const parentElevationCertificate = this.parentRiskDetail?.riskFloods[0].elevationCertificateCode;

      const currentRiskFloodCovA = Math.trunc(Number(this.coveragesData.floodForm.get('floodCoverageALimit')?.value) ?? 0);
      const currentRiskFloodCovC = Math.trunc(Number(this.coveragesData.floodForm.get('floodCoverageCLimit')?.value) ?? 0);
      const currentRiskFloodDeductible = this.coveragesData.floodForm.get('floodDeductibleId')?.value;
      const currentParticipationStatus = this.coveragesData.floodForm.get('participationStatusId')?.value;
      const currentFirmDate = this.coveragesData.floodForm.get('firmDate')?.value;
      const currentElevationCertificate = this.coveragesData.floodForm.get('elevationCertificateId')?.value;

      isCoverageFloodUWR =
        parentRiskFloodCovA !== currentRiskFloodCovA ||
        parentRiskFloodCovC !== currentRiskFloodCovC ||
        parentRiskFloodDeductible !== currentRiskFloodDeductible ||
        parentParticipationStatus !== currentParticipationStatus ||
        parentFirmDate !== currentFirmDate ||
        parentElevationCertificate !== currentElevationCertificate;
    }
    this.uwrHelpers.updateUwrList(isCoverageFloodUWR, MoratoriumTypeCode.coverageFloodMoratoriumCode, null, true);
  }

  removeInactiveMoratoriumUWR(): void {
    if(this.entityRiskData.isDoneFetchMoratorium) {
      const moratoriumResponse = this.entityRiskData.moratoriumResponse ?? [];
      // Remove Flood Coverage Moratorium
      if (moratoriumResponse.filter(a => a.type.toLowerCase() === MoratoriumType.coverageMoratorium.toLowerCase() &&
          a.type2.toLocaleLowerCase() === MoratoriumType2.floodCoverage.toLocaleLowerCase()).length <= 1) {
            this.uwrHelpers.updateUwrList(false, MoratoriumTypeCode.coverageFloodMoratoriumCode, null, true);
      }
    }
  }

  renewalUWR86(): void {
    // TEMPORARILY Comment this code, as per business UWR86: turn this off until the copy feature is turned back on

    // const parentPriorInsuranceCarrier = this?.ParentRiskDetail?.riskInterests[0]?.priorCarrierCode;
    // const priorInsuranceCarrier = this?.interestData?.priorInsuranceForm?.get('priorCarrier')?.value;
    // const status = parentPriorInsuranceCarrier !== priorInsuranceCarrier;

    // if (status) {
    //   this.uwr86();
    // } else {
    //   this.uwrHelpers.policyRemoveFromTableRow('uwr86');
    // }
  }

  renewalUWR93(): void {
    // Involves Updates in:
    // 1. Coverages > Schedules > Add Schedules
    // 2. Coverages > Schedules > Schedule Individual Item (Note a field but a value)

    this.coveragesData.scheduleTableNames.forEach((scheduleName) => {
      const parentScheduleRowData = this.parentRiskDetail?.riskSchedules;
      const childScheduleStatus = this.coveragesData.schedulesForm.get(scheduleName).value;
      const childScheduleRowData = parentScheduleRowData?.filter(a => a.tableId === scheduleName);
      const isChildTableUpdate = this.coveragesData.policyCompareParentScheduleTableRowData(scheduleName, childScheduleRowData, true);
      const status = childScheduleStatus && isChildTableUpdate && this.uwrHelpers.isHO3orHO4orHO6;
      const uwrDescription = `Refer: ${ this.uwrHelpers.getSppName(scheduleName) } limit > $10,000 requires prior UW approval`;
      // if status is uwr will be added with the description in uwrDescription else, it will be remove in uwr list
      this.uwrHelpers.updateUwrList(
        status,
        this.uwrHelpers.getUwrCode(93),
        uwrDescription
      );
    });
  }

  renewalUWR106(): void {
    // Involves:
    // Policy Issue > Change Effective Date
    // Policy Effective Date
    // this.policyIssueData.issuePolicyForm.get('changeEffectiveDate').valueChanges.pipe(takeUntil(this.stop$)).subscribe(value => {
    //   this.checkUWR106();
    // });
  }

  renewalUWR123(): void {
    // TODO: Identify UWR 123
  }

  renewalUWR126(): void {
    // TODO: This is midterm renewal policy Endorsement
    const parentConsentToRate = this.parentRiskDetail?.riskEndorsements[0]?.isConsentToRate ?? false;
    const parentConsetToRateLimitAmount = parentConsentToRate ? this.parentRiskDetail?.riskEndorsements[0]?.consentToRateAmount : 0;

    const currentConsentToRate = this.endorsementsData.endorsementsGroup.get('consentToRate')?.value ?? false;
    const currentConsentToRateLimitAmount = currentConsentToRate ? this.endorsementsData.endorsementsGroup.get('consentToRateAmount') : 0;
  }

  subscribeToUWRPopulated$(): void {
    this.uwrPopulated$.pipe(takeUntil(this.stop$)).subscribe(() => {
      if (!this.uwrHelpers.isPolicyOrValidForRenewal) {
        this.asyncBaseUWR.initAsyncBaseUWRs();
      }

      if (this.uwrHelpers.isPolicy) {
        this.asyncPolicyUWR.initAsyncPolicyUWRs();
      }

      if (this.uwrHelpers.isValidForRenewalUWR) {
        this.asyncRenewalUWR.initAsyncRenewalUWRs();
      }

      this.clearUnexpecteUWRs$.next();
    });
  }

  subscribeToGetAsyncUWRs$(): void {
    this.uwrHelpers.getAsyncUWRs$
    .pipe(
      switchMap(() => {
        return this.uwApprovalData.uwApprovalListComplete$.pipe(filter((value) => { return value === true; }));
      }),
      takeUntil(this.stop$),
      delay(1000))
    .subscribe(() => {
      if (!this.uwrHelpers.isPolicyOrValidForRenewal) {
        this.getUwrReferrals();
      }

      if (this.uwrHelpers.isPolicy) {
        this.getPolicyUwrReferrals();
      }

      this.uwApprovalData.initUWRData();
      this.uwApprovalData.checkPendingItems();
    });
  }

  subscribeToClearUnexpecteUWRs$(): void {
    // Defensive code for unexpected UWRs triggered upon retrival for Renewal Pending and Revised Renewal Pending
    this.clearUnexpecteUWRs$.pipe(take(1)).subscribe(() => {
      if (this.uwrHelpers.isRenewalPendingOrRevisedRenewalPending) {
        this.uwApprovalData.initUWRData();
        this.uwApprovalData.checkPendingItems();
      }
    });
  }
}
